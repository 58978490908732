import { LOCALE_LABELS } from "./labels";

type Country = { name: string; dialCode: string; iso: string; sub?: Record<string, string> };

export const Countries: Record<string, Country> = {
    AF: { name: LOCALE_LABELS.COUNTRIES_AF, dialCode: "93", iso: "af" },
    AL: {
        name: LOCALE_LABELS.COUNTRIES_AL,
        dialCode: "355",
        iso: "al",
    },
    DZ: {
        name: LOCALE_LABELS.COUNTRIES_DZ,
        dialCode: "213",
        iso: "dz",
    },
    AS: {
        name: LOCALE_LABELS.COUNTRIES_AS,
        dialCode: "1684",
        iso: "as",
    },
    AD: {
        name: LOCALE_LABELS.COUNTRIES_AD,
        dialCode: "376",
        iso: "ad",
    },
    AO: {
        name: LOCALE_LABELS.COUNTRIES_AO,
        dialCode: "244",
        iso: "ao",
    },
    AI: {
        name: LOCALE_LABELS.COUNTRIES_AI,
        dialCode: "1264",
        iso: "ai",
    },
    AG: {
        name: LOCALE_LABELS.COUNTRIES_AG,
        dialCode: "1268",
        iso: "ag",
    },
    AR: { name: LOCALE_LABELS.COUNTRIES_AR, dialCode: "54", iso: "ar" },
    AM: {
        name: LOCALE_LABELS.COUNTRIES_AM,
        dialCode: "374",
        iso: "am",
    },
    AW: {
        name: LOCALE_LABELS.COUNTRIES_AW,
        dialCode: "297",
        iso: "aw",
    },
    AU: { name: LOCALE_LABELS.COUNTRIES_AU, dialCode: "61", iso: "au" },
    AT: { name: LOCALE_LABELS.COUNTRIES_AT, dialCode: "43", iso: "at" },
    AZ: {
        name: LOCALE_LABELS.COUNTRIES_AZ,
        dialCode: "994",
        iso: "az",
    },
    BS: {
        name: LOCALE_LABELS.COUNTRIES_BS,
        dialCode: "1242",
        iso: "bs",
    },
    BH: {
        name: LOCALE_LABELS.COUNTRIES_BH,
        dialCode: "973",
        iso: "bh",
    },
    BD: {
        name: LOCALE_LABELS.COUNTRIES_BD,
        dialCode: "880",
        iso: "bd",
    },
    BB: {
        name: LOCALE_LABELS.COUNTRIES_BB,
        dialCode: "1246",
        iso: "bb",
    },
    BY: {
        name: LOCALE_LABELS.COUNTRIES_BY,
        dialCode: "375",
        iso: "by",
    },
    BE: { name: LOCALE_LABELS.COUNTRIES_BE, dialCode: "32", iso: "be" },
    BZ: {
        name: LOCALE_LABELS.COUNTRIES_BZ,
        dialCode: "501",
        iso: "bz",
    },
    BJ: {
        name: LOCALE_LABELS.COUNTRIES_BJ,
        dialCode: "229",
        iso: "bj",
    },
    BM: {
        name: LOCALE_LABELS.COUNTRIES_BM,
        dialCode: "1441",
        iso: "bm",
    },
    BT: {
        name: LOCALE_LABELS.COUNTRIES_BT,
        dialCode: "975",
        iso: "bt",
    },
    BO: {
        name: LOCALE_LABELS.COUNTRIES_BO,
        dialCode: "591",
        iso: "bo",
    },
    BA: {
        name: LOCALE_LABELS.COUNTRIES_BA,
        dialCode: "387",
        iso: "ba",
    },
    BW: {
        name: LOCALE_LABELS.COUNTRIES_BW,
        dialCode: "267",
        iso: "bw",
    },
    BR: { name: LOCALE_LABELS.COUNTRIES_BR, dialCode: "55", iso: "br" },
    IO: {
        name: LOCALE_LABELS.COUNTRIES_IO,
        dialCode: "246",
        iso: "io",
    },
    VG: {
        name: LOCALE_LABELS.COUNTRIES_VG,
        dialCode: "1284",
        iso: "vg",
    },
    BN: {
        name: LOCALE_LABELS.COUNTRIES_BN,
        dialCode: "673",
        iso: "bn",
    },
    BG: {
        name: LOCALE_LABELS.COUNTRIES_BG,
        dialCode: "359",
        iso: "bg",
    },
    BF: {
        name: LOCALE_LABELS.COUNTRIES_BF,
        dialCode: "226",
        iso: "bf",
    },
    BI: {
        name: LOCALE_LABELS.COUNTRIES_BI,
        dialCode: "257",
        iso: "bi",
    },
    KH: {
        name: LOCALE_LABELS.COUNTRIES_KH,
        dialCode: "855",
        iso: "kh",
    },
    CM: {
        name: LOCALE_LABELS.COUNTRIES_CM,
        dialCode: "237",
        iso: "cm",
    },
    CA: { name: LOCALE_LABELS.COUNTRIES_CA, dialCode: "1", iso: "ca" },
    CV: {
        name: LOCALE_LABELS.COUNTRIES_CV,
        dialCode: "238",
        iso: "cv",
    },
    BQ: {
        name: LOCALE_LABELS.COUNTRIES_BQ,
        dialCode: "599",
        iso: "bq",
    },
    KY: {
        name: LOCALE_LABELS.COUNTRIES_KY,
        dialCode: "1345",
        iso: "ky",
    },
    CF: {
        name: LOCALE_LABELS.COUNTRIES_CF,
        dialCode: "236",
        iso: "cf",
    },
    TD: {
        name: LOCALE_LABELS.COUNTRIES_TD,
        dialCode: "235",
        iso: "td",
    },
    CL: { name: LOCALE_LABELS.COUNTRIES_CL, dialCode: "56", iso: "cl" },
    CN: { name: LOCALE_LABELS.COUNTRIES_CN, dialCode: "86", iso: "cn" },
    CX: { name: LOCALE_LABELS.COUNTRIES_CX, dialCode: "61", iso: "cx" },
    CC: { name: LOCALE_LABELS.COUNTRIES_CC, dialCode: "61", iso: "cc" },
    CO: { name: LOCALE_LABELS.COUNTRIES_CO, dialCode: "57", iso: "co" },
    KM: {
        name: LOCALE_LABELS.COUNTRIES_KM,
        dialCode: "269",
        iso: "km",
    },
    CD: {
        name: LOCALE_LABELS.COUNTRIES_CD,
        dialCode: "243",
        iso: "cd",
    },
    CG: {
        name: LOCALE_LABELS.COUNTRIES_CG,
        dialCode: "242",
        iso: "cg",
    },
    CK: {
        name: LOCALE_LABELS.COUNTRIES_CK,
        dialCode: "682",
        iso: "ck",
    },
    CR: {
        name: LOCALE_LABELS.COUNTRIES_CR,
        dialCode: "506",
        iso: "cr",
    },
    CI: {
        name: LOCALE_LABELS.COUNTRIES_CI,
        dialCode: "225",
        iso: "ci",
    },
    HR: {
        name: LOCALE_LABELS.COUNTRIES_HR,
        dialCode: "385",
        iso: "hr",
    },
    CU: { name: LOCALE_LABELS.COUNTRIES_CU, dialCode: "53", iso: "cu" },
    CW: {
        name: LOCALE_LABELS.COUNTRIES_CW,
        dialCode: "599",
        iso: "cw",
    },
    CY: {
        name: LOCALE_LABELS.COUNTRIES_CY,
        dialCode: "357",
        iso: "cy",
    },
    CZ: {
        name: LOCALE_LABELS.COUNTRIES_CZ,
        dialCode: "420",
        iso: "cz",
    },
    DK: { name: LOCALE_LABELS.COUNTRIES_DK, dialCode: "45", iso: "dk" },
    DJ: {
        name: LOCALE_LABELS.COUNTRIES_DJ,
        dialCode: "253",
        iso: "dj",
    },
    DM: {
        name: LOCALE_LABELS.COUNTRIES_DM,
        dialCode: "1767",
        iso: "dm",
    },
    DO: { name: LOCALE_LABELS.COUNTRIES_DO, dialCode: "1", iso: "do" },
    EC: {
        name: LOCALE_LABELS.COUNTRIES_EC,
        dialCode: "593",
        iso: "ec",
    },
    EG: { name: LOCALE_LABELS.COUNTRIES_EG, dialCode: "20", iso: "eg" },
    SV: {
        name: LOCALE_LABELS.COUNTRIES_SV,
        dialCode: "503",
        iso: "sv",
    },
    GQ: {
        name: LOCALE_LABELS.COUNTRIES_GQ,
        dialCode: "240",
        iso: "gq",
    },
    ER: {
        name: LOCALE_LABELS.COUNTRIES_ER,
        dialCode: "291",
        iso: "er",
    },
    EE: {
        name: LOCALE_LABELS.COUNTRIES_EE,
        dialCode: "372",
        iso: "ee",
    },
    ET: {
        name: LOCALE_LABELS.COUNTRIES_ET,
        dialCode: "251",
        iso: "et",
    },
    FK: {
        name: LOCALE_LABELS.COUNTRIES_FK,
        dialCode: "500",
        iso: "fk",
    },
    FO: {
        name: LOCALE_LABELS.COUNTRIES_FO,
        dialCode: "298",
        iso: "fo",
    },
    FJ: {
        name: LOCALE_LABELS.COUNTRIES_FJ,
        dialCode: "679",
        iso: "fj",
    },
    FI: {
        name: LOCALE_LABELS.COUNTRIES_FI,
        dialCode: "358",
        iso: "fi",
    },
    FR: { name: LOCALE_LABELS.COUNTRIES_FR, dialCode: "33", iso: "fr" },
    GF: {
        name: LOCALE_LABELS.COUNTRIES_GF,
        dialCode: "594",
        iso: "gf",
    },
    PF: {
        name: LOCALE_LABELS.COUNTRIES_PF,
        dialCode: "689",
        iso: "pf",
    },
    GA: {
        name: LOCALE_LABELS.COUNTRIES_GA,
        dialCode: "241",
        iso: "ga",
    },
    GM: {
        name: LOCALE_LABELS.COUNTRIES_GM,
        dialCode: "220",
        iso: "gm",
    },
    GE: {
        name: LOCALE_LABELS.COUNTRIES_GE,
        dialCode: "995",
        iso: "ge",
    },
    DE: { name: LOCALE_LABELS.COUNTRIES_DE, dialCode: "49", iso: "de" },
    GH: {
        name: LOCALE_LABELS.COUNTRIES_GH,
        dialCode: "233",
        iso: "gh",
    },
    GI: {
        name: LOCALE_LABELS.COUNTRIES_GI,
        dialCode: "350",
        iso: "gi",
    },
    GR: { name: LOCALE_LABELS.COUNTRIES_GR, dialCode: "30", iso: "gr" },
    GL: {
        name: LOCALE_LABELS.COUNTRIES_GL,
        dialCode: "299",
        iso: "gl",
    },
    GD: {
        name: LOCALE_LABELS.COUNTRIES_GD,
        dialCode: "1473",
        iso: "gd",
    },
    GP: {
        name: LOCALE_LABELS.COUNTRIES_GP,
        dialCode: "590",
        iso: "gp",
    },
    GU: {
        name: LOCALE_LABELS.COUNTRIES_GU,
        dialCode: "1671",
        iso: "gu",
    },
    GT: {
        name: LOCALE_LABELS.COUNTRIES_GT,
        dialCode: "502",
        iso: "gt",
    },
    GG: { name: LOCALE_LABELS.COUNTRIES_GG, dialCode: "44", iso: "gg" },
    GN: {
        name: LOCALE_LABELS.COUNTRIES_GN,
        dialCode: "224",
        iso: "gn",
    },
    GW: {
        name: LOCALE_LABELS.COUNTRIES_GW,
        dialCode: "245",
        iso: "gw",
    },
    GY: {
        name: LOCALE_LABELS.COUNTRIES_GY,
        dialCode: "592",
        iso: "gy",
    },
    HT: {
        name: LOCALE_LABELS.COUNTRIES_HT,
        dialCode: "509",
        iso: "ht",
    },
    HN: {
        name: LOCALE_LABELS.COUNTRIES_HN,
        dialCode: "504",
        iso: "hn",
    },
    HK: {
        name: LOCALE_LABELS.COUNTRIES_HK,
        dialCode: "852",
        iso: "hk",
    },
    HU: { name: LOCALE_LABELS.COUNTRIES_HU, dialCode: "36", iso: "hu" },
    IS: {
        name: LOCALE_LABELS.COUNTRIES_IS,
        dialCode: "354",
        iso: "is",
    },
    IN: { name: LOCALE_LABELS.COUNTRIES_IN, dialCode: "91", iso: "in" },
    ID: { name: LOCALE_LABELS.COUNTRIES_ID, dialCode: "62", iso: "id" },
    IR: { name: LOCALE_LABELS.COUNTRIES_IR, dialCode: "98", iso: "ir" },
    IQ: {
        name: LOCALE_LABELS.COUNTRIES_IQ,
        dialCode: "964",
        iso: "iq",
    },
    IE: {
        name: LOCALE_LABELS.COUNTRIES_IE,
        dialCode: "353",
        iso: "ie",
    },
    IM: { name: LOCALE_LABELS.COUNTRIES_IM, dialCode: "44", iso: "im" },
    IL: {
        name: LOCALE_LABELS.COUNTRIES_IL,
        dialCode: "972",
        iso: "il",
    },
    IT: { name: LOCALE_LABELS.COUNTRIES_IT, dialCode: "39", iso: "it" },
    JM: {
        name: LOCALE_LABELS.COUNTRIES_JM,
        dialCode: "1876",
        iso: "jm",
    },
    JP: { name: LOCALE_LABELS.COUNTRIES_JP, dialCode: "81", iso: "jp" },
    JE: { name: LOCALE_LABELS.COUNTRIES_JE, dialCode: "44", iso: "je" },
    JO: {
        name: LOCALE_LABELS.COUNTRIES_JO,
        dialCode: "962",
        iso: "jo",
    },
    KZ: { name: LOCALE_LABELS.COUNTRIES_KZ, dialCode: "7", iso: "kz" },
    KE: {
        name: LOCALE_LABELS.COUNTRIES_KE,
        dialCode: "254",
        iso: "ke",
    },
    KI: {
        name: LOCALE_LABELS.COUNTRIES_KI,
        dialCode: "686",
        iso: "ki",
    },
    XK: {
        name: LOCALE_LABELS.COUNTRIES_XK,
        dialCode: "383",
        iso: "xk",
    },
    KW: {
        name: LOCALE_LABELS.COUNTRIES_KW,
        dialCode: "965",
        iso: "kw",
    },
    KG: {
        name: LOCALE_LABELS.COUNTRIES_KG,
        dialCode: "996",
        iso: "kg",
    },
    LA: {
        name: LOCALE_LABELS.COUNTRIES_LA,
        dialCode: "856",
        iso: "la",
    },
    LV: {
        name: LOCALE_LABELS.COUNTRIES_LV,
        dialCode: "371",
        iso: "lv",
    },
    LB: {
        name: LOCALE_LABELS.COUNTRIES_LB,
        dialCode: "961",
        iso: "lb",
    },
    LS: {
        name: LOCALE_LABELS.COUNTRIES_LS,
        dialCode: "266",
        iso: "ls",
    },
    LR: {
        name: LOCALE_LABELS.COUNTRIES_LR,
        dialCode: "231",
        iso: "lr",
    },
    LY: {
        name: LOCALE_LABELS.COUNTRIES_LY,
        dialCode: "218",
        iso: "ly",
    },
    LI: {
        name: LOCALE_LABELS.COUNTRIES_LI,
        dialCode: "423",
        iso: "li",
    },
    LT: {
        name: LOCALE_LABELS.COUNTRIES_LT,
        dialCode: "370",
        iso: "lt",
    },
    LU: {
        name: LOCALE_LABELS.COUNTRIES_LU,
        dialCode: "352",
        iso: "lu",
    },
    MO: {
        name: LOCALE_LABELS.COUNTRIES_MO,
        dialCode: "853",
        iso: "mo",
    },
    MK: {
        name: LOCALE_LABELS.COUNTRIES_MK,
        dialCode: "389",
        iso: "mk",
    },
    MG: {
        name: LOCALE_LABELS.COUNTRIES_MG,
        dialCode: "261",
        iso: "mg",
    },
    MW: {
        name: LOCALE_LABELS.COUNTRIES_MW,
        dialCode: "265",
        iso: "mw",
    },
    MY: { name: LOCALE_LABELS.COUNTRIES_MY, dialCode: "60", iso: "my" },
    MV: {
        name: LOCALE_LABELS.COUNTRIES_MV,
        dialCode: "960",
        iso: "mv",
    },
    ML: {
        name: LOCALE_LABELS.COUNTRIES_ML,
        dialCode: "223",
        iso: "ml",
    },
    MT: {
        name: LOCALE_LABELS.COUNTRIES_MT,
        dialCode: "356",
        iso: "mt",
    },
    MH: {
        name: LOCALE_LABELS.COUNTRIES_MH,
        dialCode: "692",
        iso: "mh",
    },
    MQ: {
        name: LOCALE_LABELS.COUNTRIES_MQ,
        dialCode: "596",
        iso: "mq",
    },
    MR: {
        name: LOCALE_LABELS.COUNTRIES_MR,
        dialCode: "222",
        iso: "mr",
    },
    MU: {
        name: LOCALE_LABELS.COUNTRIES_MU,
        dialCode: "230",
        iso: "mu",
    },
    YT: {
        name: LOCALE_LABELS.COUNTRIES_YT,
        dialCode: "262",
        iso: "yt",
    },
    MX: { name: LOCALE_LABELS.COUNTRIES_MX, dialCode: "52", iso: "mx" },
    FM: {
        name: LOCALE_LABELS.COUNTRIES_FM,
        dialCode: "691",
        iso: "fm",
    },
    MD: {
        name: LOCALE_LABELS.COUNTRIES_MD,
        dialCode: "373",
        iso: "md",
    },
    MC: {
        name: LOCALE_LABELS.COUNTRIES_MC,
        dialCode: "377",
        iso: "mc",
    },
    MN: {
        name: LOCALE_LABELS.COUNTRIES_MN,
        dialCode: "976",
        iso: "mn",
    },
    ME: {
        name: LOCALE_LABELS.COUNTRIES_ME,
        dialCode: "382",
        iso: "me",
    },
    MS: {
        name: LOCALE_LABELS.COUNTRIES_MS,
        dialCode: "1664",
        iso: "ms",
    },
    MA: {
        name: LOCALE_LABELS.COUNTRIES_MA,
        dialCode: "212",
        iso: "ma",
    },
    MZ: {
        name: LOCALE_LABELS.COUNTRIES_MZ,
        dialCode: "258",
        iso: "mz",
    },
    MM: { name: LOCALE_LABELS.COUNTRIES_MM, dialCode: "95", iso: "mm" },
    NA: {
        name: LOCALE_LABELS.COUNTRIES_NA,
        dialCode: "264",
        iso: "na",
    },
    NR: {
        name: LOCALE_LABELS.COUNTRIES_NR,
        dialCode: "674",
        iso: "nr",
    },
    NP: {
        name: LOCALE_LABELS.COUNTRIES_NP,
        dialCode: "977",
        iso: "np",
    },
    NL: { name: LOCALE_LABELS.COUNTRIES_NL, dialCode: "31", iso: "nl" },
    NC: {
        name: LOCALE_LABELS.COUNTRIES_NC,
        dialCode: "687",
        iso: "nc",
    },
    NZ: { name: LOCALE_LABELS.COUNTRIES_NZ, dialCode: "64", iso: "nz" },
    NI: {
        name: LOCALE_LABELS.COUNTRIES_NI,
        dialCode: "505",
        iso: "ni",
    },
    NE: {
        name: LOCALE_LABELS.COUNTRIES_NE,
        dialCode: "227",
        iso: "ne",
    },
    NG: {
        name: LOCALE_LABELS.COUNTRIES_NG,
        dialCode: "234",
        iso: "ng",
    },
    NU: {
        name: LOCALE_LABELS.COUNTRIES_NU,
        dialCode: "683",
        iso: "nu",
    },
    NF: {
        name: LOCALE_LABELS.COUNTRIES_NF,
        dialCode: "672",
        iso: "nf",
    },
    KP: {
        name: LOCALE_LABELS.COUNTRIES_KP,
        dialCode: "850",
        iso: "kp",
    },
    MP: {
        name: LOCALE_LABELS.COUNTRIES_MP,
        dialCode: "1670",
        iso: "mp",
    },
    NO: { name: LOCALE_LABELS.COUNTRIES_NO, dialCode: "47", iso: "no" },
    OM: {
        name: LOCALE_LABELS.COUNTRIES_OM,
        dialCode: "968",
        iso: "om",
    },
    PK: { name: LOCALE_LABELS.COUNTRIES_PK, dialCode: "92", iso: "pk" },
    PW: {
        name: LOCALE_LABELS.COUNTRIES_PW,
        dialCode: "680",
        iso: "pw",
    },
    PS: {
        name: LOCALE_LABELS.COUNTRIES_PS,
        dialCode: "970",
        iso: "ps",
    },
    PA: {
        name: LOCALE_LABELS.COUNTRIES_PA,
        dialCode: "507",
        iso: "pa",
    },
    PG: {
        name: LOCALE_LABELS.COUNTRIES_PG,
        dialCode: "675",
        iso: "pg",
    },
    PY: {
        name: LOCALE_LABELS.COUNTRIES_PY,
        dialCode: "595",
        iso: "py",
    },
    PE: { name: LOCALE_LABELS.COUNTRIES_PE, dialCode: "51", iso: "pe" },
    PH: { name: LOCALE_LABELS.COUNTRIES_PH, dialCode: "63", iso: "ph" },
    PL: { name: LOCALE_LABELS.COUNTRIES_PL, dialCode: "48", iso: "pl" },
    PT: {
        name: LOCALE_LABELS.COUNTRIES_PT,
        dialCode: "351",
        iso: "pt",
    },
    PR: { name: LOCALE_LABELS.COUNTRIES_PR, dialCode: "1", iso: "pr" },
    QA: {
        name: LOCALE_LABELS.COUNTRIES_QA,
        dialCode: "974",
        iso: "qa",
    },
    RE: {
        name: LOCALE_LABELS.COUNTRIES_RE,
        dialCode: "262",
        iso: "re",
    },
    RO: { name: LOCALE_LABELS.COUNTRIES_RO, dialCode: "40", iso: "ro" },
    RU: { name: LOCALE_LABELS.COUNTRIES_RU, dialCode: "7", iso: "ru" },
    RW: {
        name: LOCALE_LABELS.COUNTRIES_RW,
        dialCode: "250",
        iso: "rw",
    },
    BL: {
        name: LOCALE_LABELS.COUNTRIES_BL,
        dialCode: "590",
        iso: "bl",
    },
    SH: {
        name: LOCALE_LABELS.COUNTRIES_SH,
        dialCode: "290",
        iso: "sh",
    },
    KN: {
        name: LOCALE_LABELS.COUNTRIES_KN,
        dialCode: "1869",
        iso: "kn",
    },
    LC: {
        name: LOCALE_LABELS.COUNTRIES_LC,
        dialCode: "1758",
        iso: "lc",
    },
    MF: {
        name: LOCALE_LABELS.COUNTRIES_MF,
        dialCode: "590",
        iso: "mf",
    },
    PM: {
        name: LOCALE_LABELS.COUNTRIES_PM,
        dialCode: "508",
        iso: "pm",
    },
    VC: {
        name: LOCALE_LABELS.COUNTRIES_VC,
        dialCode: "1784",
        iso: "vc",
    },
    WS: {
        name: LOCALE_LABELS.COUNTRIES_WS,
        dialCode: "685",
        iso: "ws",
    },
    SM: {
        name: LOCALE_LABELS.COUNTRIES_SM,
        dialCode: "378",
        iso: "sm",
    },
    ST: {
        name: LOCALE_LABELS.COUNTRIES_ST,
        dialCode: "239",
        iso: "st",
    },
    SA: {
        name: LOCALE_LABELS.COUNTRIES_SA,
        dialCode: "966",
        iso: "sa",
    },
    SN: {
        name: LOCALE_LABELS.COUNTRIES_SN,
        dialCode: "221",
        iso: "sn",
    },
    RS: {
        name: LOCALE_LABELS.COUNTRIES_RS,
        dialCode: "381",
        iso: "rs",
    },
    SC: {
        name: LOCALE_LABELS.COUNTRIES_SC,
        dialCode: "248",
        iso: "sc",
    },
    SL: {
        name: LOCALE_LABELS.COUNTRIES_SL,
        dialCode: "232",
        iso: "sl",
    },
    SG: { name: LOCALE_LABELS.COUNTRIES_SG, dialCode: "65", iso: "sg" },
    SX: {
        name: LOCALE_LABELS.COUNTRIES_SX,
        dialCode: "1721",
        iso: "sx",
    },
    SK: {
        name: LOCALE_LABELS.COUNTRIES_SK,
        dialCode: "421",
        iso: "sk",
    },
    SI: {
        name: LOCALE_LABELS.COUNTRIES_SI,
        dialCode: "386",
        iso: "si",
    },
    SB: {
        name: LOCALE_LABELS.COUNTRIES_SB,
        dialCode: "677",
        iso: "sb",
    },
    SO: {
        name: LOCALE_LABELS.COUNTRIES_SO,
        dialCode: "252",
        iso: "so",
    },
    ZA: { name: LOCALE_LABELS.COUNTRIES_ZA, dialCode: "27", iso: "za" },
    KR: { name: LOCALE_LABELS.COUNTRIES_KR, dialCode: "82", iso: "kr" },
    SS: {
        name: LOCALE_LABELS.COUNTRIES_SS,
        dialCode: "211",
        iso: "ss",
    },
    ES: { name: LOCALE_LABELS.COUNTRIES_ES, dialCode: "34", iso: "es" },
    LK: { name: LOCALE_LABELS.COUNTRIES_LK, dialCode: "94", iso: "lk" },
    SD: {
        name: LOCALE_LABELS.COUNTRIES_SD,
        dialCode: "249",
        iso: "sd",
    },
    SR: {
        name: LOCALE_LABELS.COUNTRIES_SR,
        dialCode: "597",
        iso: "sr",
    },
    SJ: { name: LOCALE_LABELS.COUNTRIES_SJ, dialCode: "47", iso: "sj" },
    SZ: {
        name: LOCALE_LABELS.COUNTRIES_SZ,
        dialCode: "268",
        iso: "sz",
    },
    SE: { name: LOCALE_LABELS.COUNTRIES_SE, dialCode: "46", iso: "se" },
    CH: { name: LOCALE_LABELS.COUNTRIES_CH, dialCode: "41", iso: "ch" },
    SY: {
        name: LOCALE_LABELS.COUNTRIES_SY,
        dialCode: "963",
        iso: "sy",
    },
    TW: {
        name: LOCALE_LABELS.COUNTRIES_TW,
        dialCode: "886",
        iso: "tw",
    },
    TJ: {
        name: LOCALE_LABELS.COUNTRIES_TJ,
        dialCode: "992",
        iso: "tj",
    },
    TZ: {
        name: LOCALE_LABELS.COUNTRIES_TZ,
        dialCode: "255",
        iso: "tz",
    },
    TH: { name: LOCALE_LABELS.COUNTRIES_TH, dialCode: "66", iso: "th" },
    TL: {
        name: LOCALE_LABELS.COUNTRIES_TL,
        dialCode: "670",
        iso: "tl",
    },
    TG: {
        name: LOCALE_LABELS.COUNTRIES_TG,
        dialCode: "228",
        iso: "tg",
    },
    TK: {
        name: LOCALE_LABELS.COUNTRIES_TK,
        dialCode: "690",
        iso: "tk",
    },
    TO: {
        name: LOCALE_LABELS.COUNTRIES_TO,
        dialCode: "676",
        iso: "to",
    },
    TT: {
        name: LOCALE_LABELS.COUNTRIES_TT,
        dialCode: "1868",
        iso: "tt",
    },
    TN: {
        name: LOCALE_LABELS.COUNTRIES_TN,
        dialCode: "216",
        iso: "tn",
    },
    TR: { name: LOCALE_LABELS.COUNTRIES_TR, dialCode: "90", iso: "tr" },
    TM: {
        name: LOCALE_LABELS.COUNTRIES_TM,
        dialCode: "993",
        iso: "tm",
    },
    TC: {
        name: LOCALE_LABELS.COUNTRIES_TC,
        dialCode: "1649",
        iso: "tc",
    },
    TV: {
        name: LOCALE_LABELS.COUNTRIES_TV,
        dialCode: "688",
        iso: "tv",
    },
    VI: {
        name: LOCALE_LABELS.COUNTRIES_VI,
        dialCode: "1340",
        iso: "vi",
    },
    UG: {
        name: LOCALE_LABELS.COUNTRIES_UG,
        dialCode: "256",
        iso: "ug",
    },
    UA: {
        name: LOCALE_LABELS.COUNTRIES_UA,
        dialCode: "380",
        iso: "ua",
    },
    AE: {
        name: LOCALE_LABELS.COUNTRIES_AE,
        dialCode: "971",
        iso: "ae",
    },
    GB: { name: LOCALE_LABELS.COUNTRIES_GB, dialCode: "44", iso: "gb" },
    US: { name: LOCALE_LABELS.COUNTRIES_US, dialCode: "1", iso: "us" },
    UY: {
        name: LOCALE_LABELS.COUNTRIES_UY,
        dialCode: "598",
        iso: "uy",
    },
    UZ: {
        name: LOCALE_LABELS.COUNTRIES_UZ,
        dialCode: "998",
        iso: "uz",
    },
    VU: {
        name: LOCALE_LABELS.COUNTRIES_VU,
        dialCode: "678",
        iso: "vu",
    },
    VA: { name: LOCALE_LABELS.COUNTRIES_VA, dialCode: "39", iso: "va" },
    VE: { name: LOCALE_LABELS.COUNTRIES_VE, dialCode: "58", iso: "ve" },
    VN: { name: LOCALE_LABELS.COUNTRIES_VN, dialCode: "84", iso: "vn" },
    WF: {
        name: LOCALE_LABELS.COUNTRIES_WF,
        dialCode: "681",
        iso: "wf",
    },
    EH: {
        name: LOCALE_LABELS.COUNTRIES_EH,
        dialCode: "212",
        iso: "eh",
    },
    YE: {
        name: LOCALE_LABELS.COUNTRIES_YE,
        dialCode: "967",
        iso: "ye",
    },
    ZM: {
        name: LOCALE_LABELS.COUNTRIES_ZM,
        dialCode: "260",
        iso: "zm",
    },
    ZW: {
        name: LOCALE_LABELS.COUNTRIES_ZW,
        dialCode: "263",
        iso: "zw",
    },
    AX: {
        name: LOCALE_LABELS.COUNTRIES_AX,
        dialCode: "358",
        iso: "ax",
    },
};

export const Timezones = {
    "Asia/Aden": "Asia/Aden (UTC+03:00)",
    "America/Cuiaba": "America/Cuiaba (UTC-04:00)",
    "Etc/GMT+9": "Etc/GMT+9 (UTC-09:00)",
    "Etc/GMT+8": "Etc/GMT+8 (UTC-08:00)",
    "Africa/Nairobi": "Africa/Nairobi (UTC+03:00)",
    "America/Marigot": "America/Marigot (UTC-04:00)",
    "Asia/Aqtau": "Asia/Aqtau (UTC+05:00)",
    "Pacific/Kwajalein": "Pacific/Kwajalein (UTC+12:00)",
    "America/El_Salvador": "America/El_Salvador (UTC-06:00)",
    "Asia/Pontianak": "Asia/Pontianak (UTC+07:00)",
    "Africa/Cairo": "Africa/Cairo (UTC+02:00)",
    "Pacific/Pago_Pago": "Pacific/Pago_Pago (UTC-11:00)",
    "Africa/Mbabane": "Africa/Mbabane (UTC+02:00)",
    "Asia/Kuching": "Asia/Kuching (UTC+08:00)",
    "Pacific/Honolulu": "Pacific/Honolulu (UTC-10:00)",
    "Pacific/Rarotonga": "Pacific/Rarotonga (UTC-10:00)",
    "America/Guatemala": "America/Guatemala (UTC-06:00)",
    "Australia/Hobart": "Australia/Hobart (UTC+10:00)",
    "Europe/London": "Europe/London (UTC+01:00)",
    "America/Belize": "America/Belize (UTC-06:00)",
    "America/Panama": "America/Panama (UTC-05:00)",
    "Asia/Chungking": "Asia/Chungking (UTC+08:00)",
    "America/Managua": "America/Managua (UTC-06:00)",
    "America/Indiana/Petersburg": "America/Indiana/Petersburg (UTC-04:00)",
    "Asia/Yerevan": "Asia/Yerevan (UTC+04:00)",
    "Europe/Brussels": "Europe/Brussels (UTC+02:00)",
    "GMT": "GMT (UTC+00:00)",
    "Europe/Warsaw": "Europe/Warsaw (UTC+02:00)",
    "America/Chicago": "America/Chicago (UTC-05:00)",
    "Asia/Kashgar": "Asia/Kashgar (UTC+06:00)",
    "Chile/Continental": "Chile/Continental (UTC-03:00)",
    "Pacific/Yap": "Pacific/Yap (UTC+10:00)",
    "CET": "CET (UTC+02:00)",
    "Etc/GMT-1": "Etc/GMT-1 (UTC+01:00)",
    "Etc/GMT-0": "Etc/GMT-0 (UTC+00:00)",
    "Europe/Jersey": "Europe/Jersey (UTC+01:00)",
    "America/Tegucigalpa": "America/Tegucigalpa (UTC-06:00)",
    "Etc/GMT-5": "Etc/GMT-5 (UTC+05:00)",
    "Europe/Istanbul": "Europe/Istanbul (UTC+03:00)",
    "America/Eirunepe": "America/Eirunepe (UTC-05:00)",
    "Etc/GMT-4": "Etc/GMT-4 (UTC+04:00)",
    "America/Miquelon": "America/Miquelon (UTC-02:00)",
    "Etc/GMT-3": "Etc/GMT-3 (UTC+03:00)",
    "Europe/Luxembourg": "Europe/Luxembourg (UTC+02:00)",
    "Etc/GMT-2": "Etc/GMT-2 (UTC+02:00)",
    "Etc/GMT-9": "Etc/GMT-9 (UTC+09:00)",
    "America/Argentina/Catamarca": "America/Argentina/Catamarca (UTC-03:00)",
    "Etc/GMT-8": "Etc/GMT-8 (UTC+08:00)",
    "Etc/GMT-7": "Etc/GMT-7 (UTC+07:00)",
    "Etc/GMT-6": "Etc/GMT-6 (UTC+06:00)",
    "Europe/Zaporozhye": "Europe/Zaporozhye (UTC+03:00)",
    "Canada/Yukon": "Canada/Yukon (UTC-07:00)",
    "Canada/Atlantic": "Canada/Atlantic (UTC-03:00)",
    "Atlantic/St_Helena": "Atlantic/St_Helena (UTC+00:00)",
    "Australia/Tasmania": "Australia/Tasmania (UTC+10:00)",
    "Libya": "Libya (UTC+02:00)",
    "Europe/Guernsey": "Europe/Guernsey (UTC+01:00)",
    "America/Grand_Turk": "America/Grand_Turk (UTC-04:00)",
    "US/Pacific-New": "US/Pacific-New (UTC-07:00)",
    "Asia/Samarkand": "Asia/Samarkand (UTC+05:00)",
    "America/Argentina/Cordoba": "America/Argentina/Cordoba (UTC-03:00)",
    "Asia/Phnom_Penh": "Asia/Phnom_Penh (UTC+07:00)",
    "Africa/Kigali": "Africa/Kigali (UTC+02:00)",
    "Asia/Almaty": "Asia/Almaty (UTC+06:00)",
    "US/Alaska": "US/Alaska (UTC-08:00)",
    "Asia/Dubai": "Asia/Dubai (UTC+04:00)",
    "Europe/Isle_of_Man": "Europe/Isle_of_Man (UTC+01:00)",
    "America/Araguaina": "America/Araguaina (UTC-03:00)",
    "Cuba": "Cuba (UTC-04:00)",
    "Asia/Novosibirsk": "Asia/Novosibirsk (UTC+06:00)",
    "America/Argentina/Salta": "America/Argentina/Salta (UTC-03:00)",
    "Etc/GMT+3": "Etc/GMT+3 (UTC-03:00)",
    "Africa/Tunis": "Africa/Tunis (UTC+01:00)",
    "Etc/GMT+2": "Etc/GMT+2 (UTC-02:00)",
    "Etc/GMT+1": "Etc/GMT+1 (UTC-01:00)",
    "Pacific/Fakaofo": "Pacific/Fakaofo (UTC+13:00)",
    "Africa/Tripoli": "Africa/Tripoli (UTC+02:00)",
    "Etc/GMT+0": "Etc/GMT+0 (UTC+00:00)",
    "Israel": "Israel (UTC+03:00)",
    "Africa/Banjul": "Africa/Banjul (UTC+00:00)",
    "Etc/GMT+7": "Etc/GMT+7 (UTC-07:00)",
    "Indian/Comoro": "Indian/Comoro (UTC+03:00)",
    "Etc/GMT+6": "Etc/GMT+6 (UTC-06:00)",
    "Etc/GMT+5": "Etc/GMT+5 (UTC-05:00)",
    "Etc/GMT+4": "Etc/GMT+4 (UTC-04:00)",
    "Pacific/Port_Moresby": "Pacific/Port_Moresby (UTC+10:00)",
    "US/Arizona": "US/Arizona (UTC-07:00)",
    "Antarctica/Syowa": "Antarctica/Syowa (UTC+03:00)",
    "Indian/Reunion": "Indian/Reunion (UTC+04:00)",
    "Pacific/Palau": "Pacific/Palau (UTC+09:00)",
    "Europe/Kaliningrad": "Europe/Kaliningrad (UTC+02:00)",
    "America/Montevideo": "America/Montevideo (UTC-03:00)",
    "Africa/Windhoek": "Africa/Windhoek (UTC+01:00)",
    "Asia/Karachi": "Asia/Karachi (UTC+05:00)",
    "Africa/Mogadishu": "Africa/Mogadishu (UTC+03:00)",
    "Australia/Perth": "Australia/Perth (UTC+08:00)",
    "Brazil/East": "Brazil/East (UTC-03:00)",
    "Etc/GMT": "Etc/GMT (UTC+00:00)",
    "Asia/Chita": "Asia/Chita (UTC+08:00)",
    "Pacific/Easter": "Pacific/Easter (UTC-05:00)",
    "Antarctica/Davis": "Antarctica/Davis (UTC+07:00)",
    "Antarctica/McMurdo": "Antarctica/McMurdo (UTC+12:00)",
    "Asia/Macao": "Asia/Macao (UTC+08:00)",
    "America/Manaus": "America/Manaus (UTC-04:00)",
    "Africa/Freetown": "Africa/Freetown (UTC+00:00)",
    "Europe/Bucharest": "Europe/Bucharest (UTC+03:00)",
    "America/Argentina/Mendoza": "America/Argentina/Mendoza (UTC-03:00)",
    "Asia/Macau": "Asia/Macau (UTC+08:00)",
    "Europe/Malta": "Europe/Malta (UTC+02:00)",
    "Mexico/BajaSur": "Mexico/BajaSur (UTC-06:00)",
    "Pacific/Tahiti": "Pacific/Tahiti (UTC-10:00)",
    "Africa/Asmera": "Africa/Asmera (UTC+03:00)",
    "Europe/Busingen": "Europe/Busingen (UTC+02:00)",
    "America/Argentina/Rio_Gallegos": "America/Argentina/Rio_Gallegos (UTC-03:00)",
    "Africa/Malabo": "Africa/Malabo (UTC+01:00)",
    "Europe/Skopje": "Europe/Skopje (UTC+02:00)",
    "America/Catamarca": "America/Catamarca (UTC-03:00)",
    "America/Godthab": "America/Godthab (UTC-02:00)",
    "Europe/Sarajevo": "Europe/Sarajevo (UTC+02:00)",
    "Australia/ACT": "Australia/ACT (UTC+10:00)",
    "GB-Eire": "GB-Eire (UTC+01:00)",
    "Africa/Lagos": "Africa/Lagos (UTC+01:00)",
    "America/Cordoba": "America/Cordoba (UTC-03:00)",
    "Europe/Rome": "Europe/Rome (UTC+02:00)",
    "Asia/Dacca": "Asia/Dacca (UTC+06:00)",
    "Indian/Mauritius": "Indian/Mauritius (UTC+04:00)",
    "Pacific/Samoa": "Pacific/Samoa (UTC-11:00)",
    "America/Regina": "America/Regina (UTC-06:00)",
    "America/Fort_Wayne": "America/Fort_Wayne (UTC-04:00)",
    "America/Dawson_Creek": "America/Dawson_Creek (UTC-07:00)",
    "Africa/Algiers": "Africa/Algiers (UTC+01:00)",
    "Europe/Mariehamn": "Europe/Mariehamn (UTC+03:00)",
    "America/St_Johns": "America/St_Johns (UTC-02:30)",
    "America/St_Thomas": "America/St_Thomas (UTC-04:00)",
    "Europe/Zurich": "Europe/Zurich (UTC+02:00)",
    "America/Anguilla": "America/Anguilla (UTC-04:00)",
    "Asia/Dili": "Asia/Dili (UTC+09:00)",
    "America/Denver": "America/Denver (UTC-06:00)",
    "Africa/Bamako": "Africa/Bamako (UTC+00:00)",
    "GB": "GB (UTC+01:00)",
    "Mexico/General": "Mexico/General (UTC-05:00)",
    "Pacific/Wallis": "Pacific/Wallis (UTC+12:00)",
    "Europe/Gibraltar": "Europe/Gibraltar (UTC+02:00)",
    "Africa/Conakry": "Africa/Conakry (UTC+00:00)",
    "Africa/Lubumbashi": "Africa/Lubumbashi (UTC+02:00)",
    "Asia/Istanbul": "Asia/Istanbul (UTC+03:00)",
    "America/Havana": "America/Havana (UTC-04:00)",
    "NZ-CHAT": "NZ-CHAT (UTC+12:45)",
    "Asia/Choibalsan": "Asia/Choibalsan (UTC+09:00)",
    "America/Porto_Acre": "America/Porto_Acre (UTC-05:00)",
    "Asia/Omsk": "Asia/Omsk (UTC+06:00)",
    "Europe/Vaduz": "Europe/Vaduz (UTC+02:00)",
    "US/Michigan": "US/Michigan (UTC-04:00)",
    "Asia/Dhaka": "Asia/Dhaka (UTC+06:00)",
    "America/Barbados": "America/Barbados (UTC-04:00)",
    "Europe/Tiraspol": "Europe/Tiraspol (UTC+03:00)",
    "Atlantic/Cape_Verde": "Atlantic/Cape_Verde (UTC-01:00)",
    "Asia/Yekaterinburg": "Asia/Yekaterinburg (UTC+05:00)",
    "America/Louisville": "America/Louisville (UTC-04:00)",
    "Pacific/Johnston": "Pacific/Johnston (UTC-10:00)",
    "Pacific/Chatham": "Pacific/Chatham (UTC+12:45)",
    "Europe/Ljubljana": "Europe/Ljubljana (UTC+02:00)",
    "America/Sao_Paulo": "America/Sao_Paulo (UTC-03:00)",
    "Asia/Jayapura": "Asia/Jayapura (UTC+09:00)",
    "America/Curacao": "America/Curacao (UTC-04:00)",
    "Asia/Dushanbe": "Asia/Dushanbe (UTC+05:00)",
    "America/Guyana": "America/Guyana (UTC-04:00)",
    "America/Guayaquil": "America/Guayaquil (UTC-05:00)",
    "America/Martinique": "America/Martinique (UTC-04:00)",
    "Portugal": "Portugal (UTC+01:00)",
    "Europe/Berlin": "Europe/Berlin (UTC+02:00)",
    "Europe/Moscow": "Europe/Moscow (UTC+03:00)",
    "Europe/Chisinau": "Europe/Chisinau (UTC+03:00)",
    "America/Puerto_Rico": "America/Puerto_Rico (UTC-04:00)",
    "America/Rankin_Inlet": "America/Rankin_Inlet (UTC-05:00)",
    "Pacific/Ponape": "Pacific/Ponape (UTC+11:00)",
    "Europe/Stockholm": "Europe/Stockholm (UTC+02:00)",
    "Europe/Budapest": "Europe/Budapest (UTC+02:00)",
    "America/Argentina/Jujuy": "America/Argentina/Jujuy (UTC-03:00)",
    "Australia/Eucla": "Australia/Eucla (UTC+08:45)",
    "Asia/Shanghai": "Asia/Shanghai (UTC+08:00)",
    "Universal": "Universal (UTC+00:00)",
    "Europe/Zagreb": "Europe/Zagreb (UTC+02:00)",
    "America/Port_of_Spain": "America/Port_of_Spain (UTC-04:00)",
    "Europe/Helsinki": "Europe/Helsinki (UTC+03:00)",
    "Asia/Beirut": "Asia/Beirut (UTC+03:00)",
    "Asia/Tel_Aviv": "Asia/Tel_Aviv (UTC+03:00)",
    "Pacific/Bougainville": "Pacific/Bougainville (UTC+11:00)",
    "US/Central": "US/Central (UTC-05:00)",
    "Africa/Sao_Tome": "Africa/Sao_Tome (UTC+00:00)",
    "Indian/Chagos": "Indian/Chagos (UTC+06:00)",
    "America/Cayenne": "America/Cayenne (UTC-03:00)",
    "Asia/Yakutsk": "Asia/Yakutsk (UTC+09:00)",
    "Pacific/Galapagos": "Pacific/Galapagos (UTC-06:00)",
    "Australia/North": "Australia/North (UTC+09:30)",
    "Europe/Paris": "Europe/Paris (UTC+02:00)",
    "Africa/Ndjamena": "Africa/Ndjamena (UTC+01:00)",
    "Pacific/Fiji": "Pacific/Fiji (UTC+12:00)",
    "America/Rainy_River": "America/Rainy_River (UTC-05:00)",
    "Indian/Maldives": "Indian/Maldives (UTC+05:00)",
    "Australia/Yancowinna": "Australia/Yancowinna (UTC+09:30)",
    "SystemV/AST4": "SystemV/AST4 (UTC-04:00)",
    "Asia/Oral": "Asia/Oral (UTC+05:00)",
    "America/Yellowknife": "America/Yellowknife (UTC-06:00)",
    "Pacific/Enderbury": "Pacific/Enderbury (UTC+13:00)",
    "America/Juneau": "America/Juneau (UTC-08:00)",
    "Australia/Victoria": "Australia/Victoria (UTC+10:00)",
    "America/Indiana/Vevay": "America/Indiana/Vevay (UTC-04:00)",
    "Asia/Tashkent": "Asia/Tashkent (UTC+05:00)",
    "Asia/Jakarta": "Asia/Jakarta (UTC+07:00)",
    "Africa/Ceuta": "Africa/Ceuta (UTC+02:00)",
    "America/Recife": "America/Recife (UTC-03:00)",
    "America/Buenos_Aires": "America/Buenos_Aires (UTC-03:00)",
    "America/Noronha": "America/Noronha (UTC-02:00)",
    "America/Swift_Current": "America/Swift_Current (UTC-06:00)",
    "Australia/Adelaide": "Australia/Adelaide (UTC+09:30)",
    "America/Metlakatla": "America/Metlakatla (UTC-08:00)",
    "Africa/Djibouti": "Africa/Djibouti (UTC+03:00)",
    "America/Paramaribo": "America/Paramaribo (UTC-03:00)",
    "Europe/Simferopol": "Europe/Simferopol (UTC+03:00)",
    "Europe/Sofia": "Europe/Sofia (UTC+03:00)",
    "Africa/Nouakchott": "Africa/Nouakchott (UTC+00:00)",
    "Europe/Prague": "Europe/Prague (UTC+02:00)",
    "America/Indiana/Vincennes": "America/Indiana/Vincennes (UTC-04:00)",
    "Antarctica/Mawson": "Antarctica/Mawson (UTC+05:00)",
    "America/Kralendijk": "America/Kralendijk (UTC-04:00)",
    "Antarctica/Troll": "Antarctica/Troll (UTC+02:00)",
    "Europe/Samara": "Europe/Samara (UTC+04:00)",
    "Indian/Christmas": "Indian/Christmas (UTC+07:00)",
    "America/Antigua": "America/Antigua (UTC-04:00)",
    "Pacific/Gambier": "Pacific/Gambier (UTC-09:00)",
    "America/Indianapolis": "America/Indianapolis (UTC-04:00)",
    "America/Inuvik": "America/Inuvik (UTC-06:00)",
    "America/Iqaluit": "America/Iqaluit (UTC-04:00)",
    "Pacific/Funafuti": "Pacific/Funafuti (UTC+12:00)",
    "UTC": "UTC (UTC+00:00)",
    "Antarctica/Macquarie": "Antarctica/Macquarie (UTC+11:00)",
    "Canada/Pacific": "Canada/Pacific (UTC-07:00)",
    "America/Moncton": "America/Moncton (UTC-03:00)",
    "Africa/Gaborone": "Africa/Gaborone (UTC+02:00)",
    "Pacific/Chuuk": "Pacific/Chuuk (UTC+10:00)",
    "Asia/Pyongyang": "Asia/Pyongyang (UTC+08:30)",
    "America/St_Vincent": "America/St_Vincent (UTC-04:00)",
    "Asia/Gaza": "Asia/Gaza (UTC+03:00)",
    "Etc/Universal": "Etc/Universal (UTC+00:00)",
    "PST8PDT": "PST8PDT (UTC-07:00)",
    "Atlantic/Faeroe": "Atlantic/Faeroe (UTC+01:00)",
    "Asia/Qyzylorda": "Asia/Qyzylorda (UTC+06:00)",
    "Canada/Newfoundland": "Canada/Newfoundland (UTC-02:30)",
    "America/Kentucky/Louisville": "America/Kentucky/Louisville (UTC-04:00)",
    "America/Yakutat": "America/Yakutat (UTC-08:00)",
    "Asia/Ho_Chi_Minh": "Asia/Ho_Chi_Minh (UTC+07:00)",
    "Antarctica/Casey": "Antarctica/Casey (UTC+08:00)",
    "Europe/Copenhagen": "Europe/Copenhagen (UTC+02:00)",
    "Africa/Asmara": "Africa/Asmara (UTC+03:00)",
    "Atlantic/Azores": "Atlantic/Azores (UTC+00:00)",
    "Europe/Vienna": "Europe/Vienna (UTC+02:00)",
    "ROK": "ROK (UTC+09:00)",
    "Pacific/Pitcairn": "Pacific/Pitcairn (UTC-08:00)",
    "America/Mazatlan": "America/Mazatlan (UTC-06:00)",
    "Australia/Queensland": "Australia/Queensland (UTC+10:00)",
    "Pacific/Nauru": "Pacific/Nauru (UTC+12:00)",
    "Europe/Tirane": "Europe/Tirane (UTC+02:00)",
    "Asia/Kolkata": "Asia/Kolkata (UTC+05:30)",
    "SystemV/MST7": "SystemV/MST7 (UTC-07:00)",
    "Australia/Canberra": "Australia/Canberra (UTC+10:00)",
    "MET": "MET (UTC+02:00)",
    "Australia/Broken_Hill": "Australia/Broken_Hill (UTC+09:30)",
    "Europe/Riga": "Europe/Riga (UTC+03:00)",
    "America/Dominica": "America/Dominica (UTC-04:00)",
    "Africa/Abidjan": "Africa/Abidjan (UTC+00:00)",
    "America/Mendoza": "America/Mendoza (UTC-03:00)",
    "America/Santarem": "America/Santarem (UTC-03:00)",
    "Kwajalein": "Kwajalein (UTC+12:00)",
    "America/Asuncion": "America/Asuncion (UTC-04:00)",
    "Asia/Ulan_Bator": "Asia/Ulan_Bator (UTC+09:00)",
    "NZ": "NZ (UTC+12:00)",
    "America/Boise": "America/Boise (UTC-06:00)",
    "Australia/Currie": "Australia/Currie (UTC+10:00)",
    "EST5EDT": "EST5EDT (UTC-04:00)",
    "Pacific/Guam": "Pacific/Guam (UTC+10:00)",
    "Pacific/Wake": "Pacific/Wake (UTC+12:00)",
    "Atlantic/Bermuda": "Atlantic/Bermuda (UTC-03:00)",
    "America/Costa_Rica": "America/Costa_Rica (UTC-06:00)",
    "America/Dawson": "America/Dawson (UTC-07:00)",
    "Asia/Chongqing": "Asia/Chongqing (UTC+08:00)",
    "Eire": "Eire (UTC+01:00)",
    "Europe/Amsterdam": "Europe/Amsterdam (UTC+02:00)",
    "America/Indiana/Knox": "America/Indiana/Knox (UTC-05:00)",
    "America/North_Dakota/Beulah": "America/North_Dakota/Beulah (UTC-05:00)",
    "Africa/Accra": "Africa/Accra (UTC+00:00)",
    "Atlantic/Faroe": "Atlantic/Faroe (UTC+01:00)",
    "Mexico/BajaNorte": "Mexico/BajaNorte (UTC-07:00)",
    "America/Maceio": "America/Maceio (UTC-03:00)",
    "Etc/UCT": "Etc/UCT (UTC+00:00)",
    "Pacific/Apia": "Pacific/Apia (UTC+13:00)",
    "GMT0": "GMT0 (UTC+00:00)",
    "America/Atka": "America/Atka (UTC-09:00)",
    "Pacific/Niue": "Pacific/Niue (UTC-11:00)",
    "Canada/East-Saskatchewan": "Canada/East-Saskatchewan (UTC-06:00)",
    "Australia/Lord_Howe": "Australia/Lord_Howe (UTC+10:30)",
    "Europe/Dublin": "Europe/Dublin (UTC+01:00)",
    "Pacific/Truk": "Pacific/Truk (UTC+10:00)",
    "MST7MDT": "MST7MDT (UTC-06:00)",
    "America/Monterrey": "America/Monterrey (UTC-05:00)",
    "America/Nassau": "America/Nassau (UTC-04:00)",
    "America/Jamaica": "America/Jamaica (UTC-05:00)",
    "Asia/Bishkek": "Asia/Bishkek (UTC+06:00)",
    "America/Atikokan": "America/Atikokan (UTC-05:00)",
    "Atlantic/Stanley": "Atlantic/Stanley (UTC-03:00)",
    "Australia/NSW": "Australia/NSW (UTC+10:00)",
    "US/Hawaii": "US/Hawaii (UTC-10:00)",
    "SystemV/CST6": "SystemV/CST6 (UTC-06:00)",
    "Indian/Mahe": "Indian/Mahe (UTC+04:00)",
    "Asia/Aqtobe": "Asia/Aqtobe (UTC+05:00)",
    "America/Sitka": "America/Sitka (UTC-08:00)",
    "Asia/Vladivostok": "Asia/Vladivostok (UTC+10:00)",
    "Africa/Libreville": "Africa/Libreville (UTC+01:00)",
    "Africa/Maputo": "Africa/Maputo (UTC+02:00)",
    "Zulu": "Zulu (UTC+00:00)",
    "America/Kentucky/Monticello": "America/Kentucky/Monticello (UTC-04:00)",
    "Africa/El_Aaiun": "Africa/El_Aaiun (UTC+01:00)",
    "Africa/Ouagadougou": "Africa/Ouagadougou (UTC+00:00)",
    "America/Coral_Harbour": "America/Coral_Harbour (UTC-05:00)",
    "Pacific/Marquesas": "Pacific/Marquesas (UTC-09:30)",
    "Brazil/West": "Brazil/West (UTC-04:00)",
    "America/Aruba": "America/Aruba (UTC-04:00)",
    "America/North_Dakota/Center": "America/North_Dakota/Center (UTC-05:00)",
    "America/Cayman": "America/Cayman (UTC-04:00)",
    "Asia/Ulaanbaatar": "Asia/Ulaanbaatar (UTC+09:00)",
    "Asia/Baghdad": "Asia/Baghdad (UTC+03:00)",
    "Europe/San_Marino": "Europe/San_Marino (UTC+02:00)",
    "America/Indiana/Tell_City": "America/Indiana/Tell_City (UTC-05:00)",
    "America/Tijuana": "America/Tijuana (UTC-07:00)",
    "Pacific/Saipan": "Pacific/Saipan (UTC+10:00)",
    "SystemV/YST9": "SystemV/YST9 (UTC-09:00)",
    "Africa/Douala": "Africa/Douala (UTC+01:00)",
    "America/Chihuahua": "America/Chihuahua (UTC-06:00)",
    "America/Ojinaga": "America/Ojinaga (UTC-06:00)",
    "Asia/Hovd": "Asia/Hovd (UTC+08:00)",
    "America/Anchorage": "America/Anchorage (UTC-08:00)",
    "Chile/EasterIsland": "Chile/EasterIsland (UTC-05:00)",
    "America/Halifax": "America/Halifax (UTC-03:00)",
    "Antarctica/Rothera": "Antarctica/Rothera (UTC-03:00)",
    "America/Indiana/Indianapolis": "America/Indiana/Indianapolis (UTC-04:00)",
    "US/Mountain": "US/Mountain (UTC-06:00)",
    "Asia/Damascus": "Asia/Damascus (UTC+03:00)",
    "America/Argentina/San_Luis": "America/Argentina/San_Luis (UTC-03:00)",
    "America/Santiago": "America/Santiago (UTC-03:00)",
    "Asia/Baku": "Asia/Baku (UTC+05:00)",
    "America/Argentina/Ushuaia": "America/Argentina/Ushuaia (UTC-03:00)",
    "Atlantic/Reykjavik": "Atlantic/Reykjavik (UTC+00:00)",
    "Africa/Brazzaville": "Africa/Brazzaville (UTC+01:00)",
    "Africa/Porto-Novo": "Africa/Porto-Novo (UTC+01:00)",
    "America/La_Paz": "America/La_Paz (UTC-04:00)",
    "Antarctica/DumontDUrville": "Antarctica/DumontDUrville (UTC+10:00)",
    "Asia/Taipei": "Asia/Taipei (UTC+08:00)",
    "Antarctica/South_Pole": "Antarctica/South_Pole (UTC+12:00)",
    "Asia/Manila": "Asia/Manila (UTC+08:00)",
    "Asia/Bangkok": "Asia/Bangkok (UTC+07:00)",
    "Africa/Dar_es_Salaam": "Africa/Dar_es_Salaam (UTC+03:00)",
    "Poland": "Poland (UTC+02:00)",
    "Atlantic/Madeira": "Atlantic/Madeira (UTC+01:00)",
    "Antarctica/Palmer": "Antarctica/Palmer (UTC-03:00)",
    "America/Thunder_Bay": "America/Thunder_Bay (UTC-04:00)",
    "Africa/Addis_Ababa": "Africa/Addis_Ababa (UTC+03:00)",
    "Europe/Uzhgorod": "Europe/Uzhgorod (UTC+03:00)",
    "Brazil/DeNoronha": "Brazil/DeNoronha (UTC-02:00)",
    "Asia/Ashkhabad": "Asia/Ashkhabad (UTC+05:00)",
    "Etc/Zulu": "Etc/Zulu (UTC+00:00)",
    "America/Indiana/Marengo": "America/Indiana/Marengo (UTC-04:00)",
    "America/Creston": "America/Creston (UTC-07:00)",
    "America/Mexico_City": "America/Mexico_City (UTC-05:00)",
    "Antarctica/Vostok": "Antarctica/Vostok (UTC+06:00)",
    "Asia/Jerusalem": "Asia/Jerusalem (UTC+03:00)",
    "Europe/Andorra": "Europe/Andorra (UTC+02:00)",
    "US/Samoa": "US/Samoa (UTC-11:00)",
    "PRC": "PRC (UTC+08:00)",
    "Asia/Vientiane": "Asia/Vientiane (UTC+07:00)",
    "Pacific/Kiritimati": "Pacific/Kiritimati (UTC+14:00)",
    "America/Matamoros": "America/Matamoros (UTC-05:00)",
    "America/Blanc-Sablon": "America/Blanc-Sablon (UTC-04:00)",
    "Asia/Riyadh": "Asia/Riyadh (UTC+03:00)",
    "Iceland": "Iceland (UTC+00:00)",
    "Pacific/Pohnpei": "Pacific/Pohnpei (UTC+11:00)",
    "Asia/Ujung_Pandang": "Asia/Ujung_Pandang (UTC+08:00)",
    "Atlantic/South_Georgia": "Atlantic/South_Georgia (UTC-02:00)",
    "Europe/Lisbon": "Europe/Lisbon (UTC+01:00)",
    "Asia/Harbin": "Asia/Harbin (UTC+08:00)",
    "Europe/Oslo": "Europe/Oslo (UTC+02:00)",
    "Asia/Novokuznetsk": "Asia/Novokuznetsk (UTC+07:00)",
    "CST6CDT": "CST6CDT (UTC-05:00)",
    "Atlantic/Canary": "Atlantic/Canary (UTC+01:00)",
    "America/Knox_IN": "America/Knox_IN (UTC-05:00)",
    "Asia/Kuwait": "Asia/Kuwait (UTC+03:00)",
    "SystemV/HST10": "SystemV/HST10 (UTC-10:00)",
    "Pacific/Efate": "Pacific/Efate (UTC+11:00)",
    "Africa/Lome": "Africa/Lome (UTC+00:00)",
    "America/Bogota": "America/Bogota (UTC-05:00)",
    "America/Menominee": "America/Menominee (UTC-05:00)",
    "America/Adak": "America/Adak (UTC-09:00)",
    "Pacific/Norfolk": "Pacific/Norfolk (UTC+11:00)",
    "America/Resolute": "America/Resolute (UTC-05:00)",
    "Pacific/Tarawa": "Pacific/Tarawa (UTC+12:00)",
    "Africa/Kampala": "Africa/Kampala (UTC+03:00)",
    "Asia/Krasnoyarsk": "Asia/Krasnoyarsk (UTC+07:00)",
    "Greenwich": "Greenwich (UTC+00:00)",
    "SystemV/EST5": "SystemV/EST5 (UTC-05:00)",
    "America/Edmonton": "America/Edmonton (UTC-06:00)",
    "Europe/Podgorica": "Europe/Podgorica (UTC+02:00)",
    "Australia/South": "Australia/South (UTC+09:30)",
    "Canada/Central": "Canada/Central (UTC-05:00)",
    "Africa/Bujumbura": "Africa/Bujumbura (UTC+02:00)",
    "America/Santo_Domingo": "America/Santo_Domingo (UTC-04:00)",
    "US/Eastern": "US/Eastern (UTC-04:00)",
    "Europe/Minsk": "Europe/Minsk (UTC+03:00)",
    "Pacific/Auckland": "Pacific/Auckland (UTC+12:00)",
    "Africa/Casablanca": "Africa/Casablanca (UTC+01:00)",
    "America/Glace_Bay": "America/Glace_Bay (UTC-03:00)",
    "Canada/Eastern": "Canada/Eastern (UTC-04:00)",
    "Asia/Qatar": "Asia/Qatar (UTC+03:00)",
    "Europe/Kiev": "Europe/Kiev (UTC+03:00)",
    "Singapore": "Singapore (UTC+08:00)",
    "Asia/Magadan": "Asia/Magadan (UTC+10:00)",
    "SystemV/PST8": "SystemV/PST8 (UTC-08:00)",
    "America/Port-au-Prince": "America/Port-au-Prince (UTC-04:00)",
    "Europe/Belfast": "Europe/Belfast (UTC+01:00)",
    "America/St_Barthelemy": "America/St_Barthelemy (UTC-04:00)",
    "Asia/Ashgabat": "Asia/Ashgabat (UTC+05:00)",
    "Africa/Luanda": "Africa/Luanda (UTC+01:00)",
    "America/Nipigon": "America/Nipigon (UTC-04:00)",
    "Atlantic/Jan_Mayen": "Atlantic/Jan_Mayen (UTC+02:00)",
    "Brazil/Acre": "Brazil/Acre (UTC-05:00)",
    "Asia/Muscat": "Asia/Muscat (UTC+04:00)",
    "Asia/Bahrain": "Asia/Bahrain (UTC+03:00)",
    "Europe/Vilnius": "Europe/Vilnius (UTC+03:00)",
    "America/Fortaleza": "America/Fortaleza (UTC-03:00)",
    "Etc/GMT0": "Etc/GMT0 (UTC+00:00)",
    "US/East-Indiana": "US/East-Indiana (UTC-04:00)",
    "America/Hermosillo": "America/Hermosillo (UTC-07:00)",
    "America/Cancun": "America/Cancun (UTC-05:00)",
    "Africa/Maseru": "Africa/Maseru (UTC+02:00)",
    "Pacific/Kosrae": "Pacific/Kosrae (UTC+11:00)",
    "Africa/Kinshasa": "Africa/Kinshasa (UTC+01:00)",
    "Asia/Kathmandu": "Asia/Kathmandu (UTC+05:45)",
    "Asia/Seoul": "Asia/Seoul (UTC+09:00)",
    "Australia/Sydney": "Australia/Sydney (UTC+10:00)",
    "America/Lima": "America/Lima (UTC-05:00)",
    "Australia/LHI": "Australia/LHI (UTC+10:30)",
    "America/St_Lucia": "America/St_Lucia (UTC-04:00)",
    "Europe/Madrid": "Europe/Madrid (UTC+02:00)",
    "America/Bahia_Banderas": "America/Bahia_Banderas (UTC-05:00)",
    "America/Montserrat": "America/Montserrat (UTC-04:00)",
    "Asia/Brunei": "Asia/Brunei (UTC+08:00)",
    "America/Santa_Isabel": "America/Santa_Isabel (UTC-07:00)",
    "Canada/Mountain": "Canada/Mountain (UTC-06:00)",
    "America/Cambridge_Bay": "America/Cambridge_Bay (UTC-06:00)",
    "Asia/Colombo": "Asia/Colombo (UTC+05:30)",
    "Australia/West": "Australia/West (UTC+08:00)",
    "Indian/Antananarivo": "Indian/Antananarivo (UTC+03:00)",
    "Australia/Brisbane": "Australia/Brisbane (UTC+10:00)",
    "Indian/Mayotte": "Indian/Mayotte (UTC+03:00)",
    "US/Indiana-Starke": "US/Indiana-Starke (UTC-05:00)",
    "Asia/Urumqi": "Asia/Urumqi (UTC+06:00)",
    "US/Aleutian": "US/Aleutian (UTC-09:00)",
    "Europe/Volgograd": "Europe/Volgograd (UTC+03:00)",
    "America/Lower_Princes": "America/Lower_Princes (UTC-04:00)",
    "America/Vancouver": "America/Vancouver (UTC-07:00)",
    "Africa/Blantyre": "Africa/Blantyre (UTC+02:00)",
    "America/Rio_Branco": "America/Rio_Branco (UTC-05:00)",
    "America/Danmarkshavn": "America/Danmarkshavn (UTC+00:00)",
    "America/Detroit": "America/Detroit (UTC-04:00)",
    "America/Thule": "America/Thule (UTC-03:00)",
    "Africa/Lusaka": "Africa/Lusaka (UTC+02:00)",
    "Asia/Hong_Kong": "Asia/Hong_Kong (UTC+08:00)",
    "Iran": "Iran (UTC+04:30)",
    "America/Argentina/La_Rioja": "America/Argentina/La_Rioja (UTC-03:00)",
    "Africa/Dakar": "Africa/Dakar (UTC+00:00)",
    "SystemV/CST6CDT": "SystemV/CST6CDT (UTC-06:00)",
    "America/Tortola": "America/Tortola (UTC-04:00)",
    "America/Porto_Velho": "America/Porto_Velho (UTC-04:00)",
    "Asia/Sakhalin": "Asia/Sakhalin (UTC+10:00)",
    "Etc/GMT+10": "Etc/GMT+10 (UTC-10:00)",
    "America/Scoresbysund": "America/Scoresbysund (UTC+00:00)",
    "Asia/Kamchatka": "Asia/Kamchatka (UTC+12:00)",
    "Asia/Thimbu": "Asia/Thimbu (UTC+06:00)",
    "Africa/Harare": "Africa/Harare (UTC+02:00)",
    "Etc/GMT+12": "Etc/GMT+12 (UTC-12:00)",
    "Etc/GMT+11": "Etc/GMT+11 (UTC-11:00)",
    "Navajo": "Navajo (UTC-06:00)",
    "America/Nome": "America/Nome (UTC-08:00)",
    "Europe/Tallinn": "Europe/Tallinn (UTC+03:00)",
    "Turkey": "Turkey (UTC+03:00)",
    "Africa/Khartoum": "Africa/Khartoum (UTC+03:00)",
    "Africa/Johannesburg": "Africa/Johannesburg (UTC+02:00)",
    "Africa/Bangui": "Africa/Bangui (UTC+01:00)",
    "Europe/Belgrade": "Europe/Belgrade (UTC+02:00)",
    "Jamaica": "Jamaica (UTC-05:00)",
    "Africa/Bissau": "Africa/Bissau (UTC+00:00)",
    "Asia/Tehran": "Asia/Tehran (UTC+04:30)",
    "WET": "WET (UTC+01:00)",
    "Africa/Juba": "Africa/Juba (UTC+03:00)",
    "America/Campo_Grande": "America/Campo_Grande (UTC-04:00)",
    "America/Belem": "America/Belem (UTC-03:00)",
    "Etc/Greenwich": "Etc/Greenwich (UTC+00:00)",
    "Asia/Saigon": "Asia/Saigon (UTC+07:00)",
    "America/Ensenada": "America/Ensenada (UTC-07:00)",
    "Pacific/Midway": "Pacific/Midway (UTC-11:00)",
    "America/Jujuy": "America/Jujuy (UTC-03:00)",
    "Africa/Timbuktu": "Africa/Timbuktu (UTC+00:00)",
    "America/Bahia": "America/Bahia (UTC-03:00)",
    "America/Goose_Bay": "America/Goose_Bay (UTC-03:00)",
    "America/Virgin": "America/Virgin (UTC-04:00)",
    "America/Pangnirtung": "America/Pangnirtung (UTC-04:00)",
    "Asia/Katmandu": "Asia/Katmandu (UTC+05:45)",
    "America/Phoenix": "America/Phoenix (UTC-07:00)",
    "Africa/Niamey": "Africa/Niamey (UTC+01:00)",
    "America/Whitehorse": "America/Whitehorse (UTC-07:00)",
    "Pacific/Noumea": "Pacific/Noumea (UTC+11:00)",
    "Asia/Tbilisi": "Asia/Tbilisi (UTC+04:00)",
    "America/Montreal": "America/Montreal (UTC-04:00)",
    "Asia/Makassar": "Asia/Makassar (UTC+08:00)",
    "America/Argentina/San_Juan": "America/Argentina/San_Juan (UTC-03:00)",
    "Hongkong": "Hongkong (UTC+08:00)",
    "UCT": "UCT (UTC+00:00)",
    "Asia/Nicosia": "Asia/Nicosia (UTC+03:00)",
    "America/Indiana/Winamac": "America/Indiana/Winamac (UTC-04:00)",
    "SystemV/MST7MDT": "SystemV/MST7MDT (UTC-07:00)",
    "America/Argentina/ComodRivadavia": "America/Argentina/ComodRivadavia (UTC-03:00)",
    "America/Boa_Vista": "America/Boa_Vista (UTC-04:00)",
    "America/Grenada": "America/Grenada (UTC-04:00)",
    "Australia/Darwin": "Australia/Darwin (UTC+09:30)",
    "Asia/Khandyga": "Asia/Khandyga (UTC+09:00)",
    "Asia/Kuala_Lumpur": "Asia/Kuala_Lumpur (UTC+08:00)",
    "Asia/Thimphu": "Asia/Thimphu (UTC+06:00)",
    "Asia/Rangoon": "Asia/Rangoon (UTC+06:30)",
    "Europe/Bratislava": "Europe/Bratislava (UTC+02:00)",
    "Asia/Calcutta": "Asia/Calcutta (UTC+05:30)",
    "America/Argentina/Tucuman": "America/Argentina/Tucuman (UTC-03:00)",
    "Asia/Kabul": "Asia/Kabul (UTC+04:30)",
    "Indian/Cocos": "Indian/Cocos (UTC+06:30)",
    "Japan": "Japan (UTC+09:00)",
    "Pacific/Tongatapu": "Pacific/Tongatapu (UTC+13:00)",
    "America/New_York": "America/New_York (UTC-04:00)",
    "Etc/GMT-12": "Etc/GMT-12 (UTC+12:00)",
    "Etc/GMT-11": "Etc/GMT-11 (UTC+11:00)",
    "Etc/GMT-10": "Etc/GMT-10 (UTC+10:00)",
    "SystemV/YST9YDT": "SystemV/YST9YDT (UTC-09:00)",
    "Etc/GMT-14": "Etc/GMT-14 (UTC+14:00)",
    "Etc/GMT-13": "Etc/GMT-13 (UTC+13:00)",
    "W-SU": "W-SU (UTC+03:00)",
    "America/Merida": "America/Merida (UTC-05:00)",
    "EET": "EET (UTC+03:00)",
    "America/Rosario": "America/Rosario (UTC-03:00)",
    "Canada/Saskatchewan": "Canada/Saskatchewan (UTC-06:00)",
    "America/St_Kitts": "America/St_Kitts (UTC-04:00)",
    "Arctic/Longyearbyen": "Arctic/Longyearbyen (UTC+02:00)",
    "America/Fort_Nelson": "America/Fort_Nelson (UTC-07:00)",
    "America/Caracas": "America/Caracas (UTC-04:30)",
    "America/Guadeloupe": "America/Guadeloupe (UTC-04:00)",
    "Asia/Hebron": "Asia/Hebron (UTC+03:00)",
    "Indian/Kerguelen": "Indian/Kerguelen (UTC+05:00)",
    "SystemV/PST8PDT": "SystemV/PST8PDT (UTC-08:00)",
    "Africa/Monrovia": "Africa/Monrovia (UTC+00:00)",
    "Asia/Ust-Nera": "Asia/Ust-Nera (UTC+10:00)",
    "Egypt": "Egypt (UTC+02:00)",
    "Asia/Srednekolymsk": "Asia/Srednekolymsk (UTC+11:00)",
    "America/North_Dakota/New_Salem": "America/North_Dakota/New_Salem (UTC-05:00)",
    "Asia/Anadyr": "Asia/Anadyr (UTC+12:00)",
    "Australia/Melbourne": "Australia/Melbourne (UTC+10:00)",
    "Asia/Irkutsk": "Asia/Irkutsk (UTC+08:00)",
    "America/Shiprock": "America/Shiprock (UTC-06:00)",
    "America/Winnipeg": "America/Winnipeg (UTC-05:00)",
    "Europe/Vatican": "Europe/Vatican (UTC+02:00)",
    "Asia/Amman": "Asia/Amman (UTC+03:00)",
    "Etc/UTC": "Etc/UTC (UTC+00:00)",
    "SystemV/AST4ADT": "SystemV/AST4ADT (UTC-04:00)",
    "Asia/Tokyo": "Asia/Tokyo (UTC+09:00)",
    "America/Toronto": "America/Toronto (UTC-04:00)",
    "Asia/Singapore": "Asia/Singapore (UTC+08:00)",
    "Australia/Lindeman": "Australia/Lindeman (UTC+10:00)",
    "America/Los_Angeles": "America/Los_Angeles (UTC-07:00)",
    "SystemV/EST5EDT": "SystemV/EST5EDT (UTC-05:00)",
    "Pacific/Majuro": "Pacific/Majuro (UTC+12:00)",
    "America/Argentina/Buenos_Aires": "America/Argentina/Buenos_Aires (UTC-03:00)",
    "Europe/Nicosia": "Europe/Nicosia (UTC+03:00)",
    "Pacific/Guadalcanal": "Pacific/Guadalcanal (UTC+11:00)",
    "Europe/Athens": "Europe/Athens (UTC+03:00)",
    "US/Pacific": "US/Pacific (UTC-07:00)",
    "Europe/Monaco": "Europe/Monaco (UTC+02:00)",
};
