import Color from "color";

import { ClientError } from "../../../common/errors/ClientError";
import { getLocale } from "../../../common/locale";
import { ResourceType } from "../../../common/Messages";
import { getMessage } from "../locale";
import { LOCALE_LABELS } from "../locale/labels";

const backgroundColor = "#4C5F85";
const hoverBackgroundColor = Color(backgroundColor).darken(0.1).hex();
const borderColor = Color(backgroundColor).darken(0.1).hex();

const STYLES = {
    normal: {
        borderWidth: "1px",
        borderColor,
        borderStyle: "solid",
        backgroundColor,
        color: "#fff",
        borderRadius: "5px",
        padding: "5px 10px",
        fontSize: "13px",
        fontWeight: "bold",
        fontFamily: "Helvetica, Arial, sans-serif",
        outlineStyle: "none",
        textShadow: "1px 1px 0 #333",
    },
    small: {
        fontSize: "11px",
        borderRadius: "3px",
        padding: "3px 8px",
    },
    large: {
        fontSize: "15px",
        borderRadius: "8px",
        padding: "8px 15px",
    },
    hover: {
        backgroundColor: hoverBackgroundColor,
        cursor: "pointer",
    },
    error: {
        fontFamily: "monospace",
    },
};

const applyStyles = (el: HTMLElement, styles: any): void => {
    Object.keys(styles).forEach((k: string) => (el.style[k] = styles[k]));
};

const getStyles = (size: ButtonSize, customStyles: Record<string, string>, hover = false) => {
    let styles: Record<string, string> = STYLES.normal;

    switch (size) {
        case "small":
            styles = { ...styles, ...STYLES.small };
            break;

        case "large":
            styles = { ...styles, ...STYLES.large };
            break;

        default:
    }

    return { ...styles, ...(hover ? STYLES.hover : null), ...customStyles };
};

export type ButtonSize = "small" | "normal" | "large";

type ButtonOptions = {
    text: string;
    style?: string;
    hoverStyle?: string;
    className?: string;
    id?: string;
    size?: ButtonSize;
};

export const renderButton = ({
    text,
    style,
    hoverStyle,
    className,
    id,
    size = "normal",
}: ButtonOptions): HTMLElement => {
    const button: HTMLButtonElement = window.document.createElement("button");

    button.setAttribute("type", "button");

    const customStyles = (style || "").split(";").reduce((acc, part) => {
        const [key, value] = part.split(":");

        if (key && value) {
            acc[key.trim()] = value.trim();
        }

        return acc;
    }, {});

    const cuestomHoverStyle = (hoverStyle || "").split(";").reduce(
        (acc, part) => {
            const [key, value] = part.split(":");

            if (key && value) {
                acc[key.trim()] = value.trim();
            }

            return acc;
        },
        { ...customStyles }
    );

    if (className) {
        button.className = className;
    }

    if (id) {
        button.id = id;
    }

    button.innerText = text;
    applyStyles(button, getStyles(size, customStyles));

    button.addEventListener("mouseenter", () => applyStyles(button, getStyles(size, cuestomHoverStyle, true)));
    button.addEventListener("mouseleave", () => applyStyles(button, getStyles(size, customStyles)));

    return button;
};

export const renderError = (errors: ClientError[], locale?: string): HTMLElement => {
    const _locale = getLocale(locale);
    const errorElement: HTMLElement = window.document.createElement("div");
    const titleElement: HTMLElement = window.document.createElement("h4");
    titleElement.innerText = getMessage(LOCALE_LABELS.ERRORS_TITLE, _locale);

    errorElement.appendChild(titleElement);

    errors.forEach((error: ClientError) => {
        const span = window.document.createElement("span");
        const message = getMessage(error.message, _locale);
        span.innerText = "* " + message;
        applyStyles(span, {
            display: "inline-block",
        });
        errorElement.appendChild(span);
    });
    applyStyles(errorElement, {
        color: "red",
        border: "1px solid red",
        display: "inline-block",
        margin: "0.5em",
        padding: "0.5em",
        paddingTop: "0",
        fontFamily: "Console, Courier, monospace",
    });
    errorElement.setAttribute("data-error", "render");

    return errorElement;
};

export const getInputName = (resourceType: ResourceType) => {
    switch (resourceType) {
        case ResourceType.TRANSACTION_TOKEN:
            return "univapayTokenId";

        case ResourceType.CHARGE:
            return "univapayChargeId";

        case ResourceType.SUBSCRIPTION:
            return "univapaySubscriptionId";

        default:
            console.warn(`No name found for type ${resourceType}`);
            return;
    }
};

const getLegacyInputName = (resourceType: ResourceType) => {
    switch (resourceType) {
        case ResourceType.TRANSACTION_TOKEN:
            return "gopayTokenId";

        case ResourceType.CHARGE:
            return "gopayChargeId";

        case ResourceType.SUBSCRIPTION:
            return "gopaySubscriptionId";

        default:
            // no legacy support for this parameter. Skipping.
            return;
    }
};

export const renderInput = (name: string, value?: string): HTMLInputElement => {
    const input: HTMLInputElement = window.document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);

    return input;
};

type InputAndLegacy = {
    input: HTMLInputElement;
    legacyInput: HTMLInputElement;
};

export const renderInputWithLegacy = (resourceType: ResourceType, name: string, resourceId: string): InputAndLegacy => {
    const inputName = name || getInputName(resourceType);
    const input = renderInput(inputName, resourceId);

    const legacyInputName = getLegacyInputName(resourceType);
    const legacyInput = renderInput(legacyInputName, resourceId);

    return { input, legacyInput };
};
