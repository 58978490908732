import { SCRIPT_NAME } from "../../common/constants";
import { extractHostname } from "../../common/utils/url";

let checkoutBaseUrl = CHECKOUT_URL;
let checkoutUrl = CHECKOUT_URL;

const script = document.querySelector(`script[src$="${SCRIPT_NAME}"]`);

if (script) {
    const scriptUrl = script ? script.getAttribute("src") : null;
    const a = document.createElement("a");
    a.href = scriptUrl;
    const domain = extractHostname(scriptUrl);

    checkoutBaseUrl = `${a.protocol}//${domain}:${a.port}`;
    checkoutUrl = `${checkoutBaseUrl}/checkout/index.html`;
}

export const BASE_CHECKOUT_URL = checkoutBaseUrl;
export const FULL_CHECKOUT_URL = checkoutUrl;
