import JSONBig from "json-bigint";

const { parse, stringify } = JSONBig({ useNativeBigInt: true });

export const JSONParse = <Out>(
    input: string,
    reviver?: (key: string, value: unknown) => any,
    defaultValue?: Out
): Out => {
    try {
        return parse(input, reviver);
    } catch (error) {
        if (error instanceof SyntaxError || error.name === "SyntaxError") {
            return defaultValue;
        } else {
            throw error;
        }
    }
};

export const JSONStringify = <In>(data: In): string => stringify(data);
