import * as COUNTRIES_LABELS from "../labels/countries";

export const LABELS = {
    [COUNTRIES_LABELS.COUNTRIES_AF]: "Afghanistan",
    [COUNTRIES_LABELS.COUNTRIES_AX]: "Åland Islands",
    [COUNTRIES_LABELS.COUNTRIES_AL]: "Albania",
    [COUNTRIES_LABELS.COUNTRIES_DZ]: "Algeria",
    [COUNTRIES_LABELS.COUNTRIES_AS]: "American Samoa",
    [COUNTRIES_LABELS.COUNTRIES_AD]: "Andorra",
    [COUNTRIES_LABELS.COUNTRIES_AO]: "Angola",
    [COUNTRIES_LABELS.COUNTRIES_AI]: "Anguilla",
    [COUNTRIES_LABELS.COUNTRIES_AQ]: "Antarctica",
    [COUNTRIES_LABELS.COUNTRIES_AG]: "Antigua & Barbuda",
    [COUNTRIES_LABELS.COUNTRIES_AR]: "Argentina",
    [COUNTRIES_LABELS.COUNTRIES_AM]: "Armenia",
    [COUNTRIES_LABELS.COUNTRIES_AW]: "Aruba",
    [COUNTRIES_LABELS.COUNTRIES_AC]: "Ascension Island",
    [COUNTRIES_LABELS.COUNTRIES_AU]: "Australia",
    [COUNTRIES_LABELS.COUNTRIES_AT]: "Austria",
    [COUNTRIES_LABELS.COUNTRIES_AZ]: "Azerbaijan",
    [COUNTRIES_LABELS.COUNTRIES_BS]: "Bahamas",
    [COUNTRIES_LABELS.COUNTRIES_BH]: "Bahrain",
    [COUNTRIES_LABELS.COUNTRIES_BD]: "Bangladesh",
    [COUNTRIES_LABELS.COUNTRIES_BB]: "Barbados",
    [COUNTRIES_LABELS.COUNTRIES_BY]: "Belarus",
    [COUNTRIES_LABELS.COUNTRIES_BE]: "Belgium",
    [COUNTRIES_LABELS.COUNTRIES_BZ]: "Belize",
    [COUNTRIES_LABELS.COUNTRIES_BJ]: "Benin",
    [COUNTRIES_LABELS.COUNTRIES_BM]: "Bermuda",
    [COUNTRIES_LABELS.COUNTRIES_BT]: "Bhutan",
    [COUNTRIES_LABELS.COUNTRIES_BO]: "Bolivia",
    [COUNTRIES_LABELS.COUNTRIES_BA]: "Bosnia & Herzegovina",
    [COUNTRIES_LABELS.COUNTRIES_BW]: "Botswana",
    [COUNTRIES_LABELS.COUNTRIES_BR]: "Brazil",
    [COUNTRIES_LABELS.COUNTRIES_IO]: "British Indian Ocean Territory",
    [COUNTRIES_LABELS.COUNTRIES_VG]: "British Virgin Islands",
    [COUNTRIES_LABELS.COUNTRIES_BN]: "Brunei",
    [COUNTRIES_LABELS.COUNTRIES_BG]: "Bulgaria",
    [COUNTRIES_LABELS.COUNTRIES_BF]: "Burkina Faso",
    [COUNTRIES_LABELS.COUNTRIES_BI]: "Burundi",
    [COUNTRIES_LABELS.COUNTRIES_KH]: "Cambodia",
    [COUNTRIES_LABELS.COUNTRIES_CM]: "Cameroon",
    [COUNTRIES_LABELS.COUNTRIES_CA]: "Canada",
    [COUNTRIES_LABELS.COUNTRIES_IC]: "Canary Islands",
    [COUNTRIES_LABELS.COUNTRIES_CV]: "Cape Verde",
    [COUNTRIES_LABELS.COUNTRIES_BQ]: "Caribbean Netherlands",
    [COUNTRIES_LABELS.COUNTRIES_KY]: "Cayman Islands",
    [COUNTRIES_LABELS.COUNTRIES_CF]: "Central African Republic",
    [COUNTRIES_LABELS.COUNTRIES_EA]: "Ceuta & Melilla",
    [COUNTRIES_LABELS.COUNTRIES_TD]: "Chad",
    [COUNTRIES_LABELS.COUNTRIES_CL]: "Chile",
    [COUNTRIES_LABELS.COUNTRIES_CN]: "China",
    [COUNTRIES_LABELS.COUNTRIES_CX]: "Christmas Island",
    [COUNTRIES_LABELS.COUNTRIES_CC]: "Cocos (Keeling) Islands",
    [COUNTRIES_LABELS.COUNTRIES_CO]: "Colombia",
    [COUNTRIES_LABELS.COUNTRIES_KM]: "Comoros",
    [COUNTRIES_LABELS.COUNTRIES_CG]: "Congo - Brazzaville",
    [COUNTRIES_LABELS.COUNTRIES_CD]: "Congo - Kinshasa",
    [COUNTRIES_LABELS.COUNTRIES_CK]: "Cook Islands",
    [COUNTRIES_LABELS.COUNTRIES_CR]: "Costa Rica",
    [COUNTRIES_LABELS.COUNTRIES_CI]: "Côte d’Ivoire",
    [COUNTRIES_LABELS.COUNTRIES_HR]: "Croatia",
    [COUNTRIES_LABELS.COUNTRIES_CU]: "Cuba",
    [COUNTRIES_LABELS.COUNTRIES_CW]: "Curaçao",
    [COUNTRIES_LABELS.COUNTRIES_CY]: "Cyprus",
    [COUNTRIES_LABELS.COUNTRIES_CZ]: "Czech Republic",
    [COUNTRIES_LABELS.COUNTRIES_DK]: "Denmark",
    [COUNTRIES_LABELS.COUNTRIES_DG]: "Diego Garcia",
    [COUNTRIES_LABELS.COUNTRIES_DJ]: "Djibouti",
    [COUNTRIES_LABELS.COUNTRIES_DM]: "Dominica",
    [COUNTRIES_LABELS.COUNTRIES_DO]: "Dominican Republic",
    [COUNTRIES_LABELS.COUNTRIES_EC]: "Ecuador",
    [COUNTRIES_LABELS.COUNTRIES_EG]: "Egypt",
    [COUNTRIES_LABELS.COUNTRIES_SV]: "El Salvador",
    [COUNTRIES_LABELS.COUNTRIES_GQ]: "Equatorial Guinea",
    [COUNTRIES_LABELS.COUNTRIES_ER]: "Eritrea",
    [COUNTRIES_LABELS.COUNTRIES_EE]: "Estonia",
    [COUNTRIES_LABELS.COUNTRIES_ET]: "Ethiopia",
    [COUNTRIES_LABELS.COUNTRIES_FK]: "Falkland Islands",
    [COUNTRIES_LABELS.COUNTRIES_FO]: "Faroe Islands",
    [COUNTRIES_LABELS.COUNTRIES_FJ]: "Fiji",
    [COUNTRIES_LABELS.COUNTRIES_FI]: "Finland",
    [COUNTRIES_LABELS.COUNTRIES_FR]: "France",
    [COUNTRIES_LABELS.COUNTRIES_GF]: "French Guiana",
    [COUNTRIES_LABELS.COUNTRIES_PF]: "French Polynesia",
    [COUNTRIES_LABELS.COUNTRIES_TF]: "French Southern Territories",
    [COUNTRIES_LABELS.COUNTRIES_GA]: "Gabon",
    [COUNTRIES_LABELS.COUNTRIES_GM]: "Gambia",
    [COUNTRIES_LABELS.COUNTRIES_GE]: "Georgia",
    [COUNTRIES_LABELS.COUNTRIES_DE]: "Germany",
    [COUNTRIES_LABELS.COUNTRIES_GH]: "Ghana",
    [COUNTRIES_LABELS.COUNTRIES_GI]: "Gibraltar",
    [COUNTRIES_LABELS.COUNTRIES_GR]: "Greece",
    [COUNTRIES_LABELS.COUNTRIES_GL]: "Greenland",
    [COUNTRIES_LABELS.COUNTRIES_GD]: "Grenada",
    [COUNTRIES_LABELS.COUNTRIES_GP]: "Guadeloupe",
    [COUNTRIES_LABELS.COUNTRIES_GU]: "Guam",
    [COUNTRIES_LABELS.COUNTRIES_GT]: "Guatemala",
    [COUNTRIES_LABELS.COUNTRIES_GG]: "Guernsey",
    [COUNTRIES_LABELS.COUNTRIES_GN]: "Guinea",
    [COUNTRIES_LABELS.COUNTRIES_GW]: "Guinea-Bissau",
    [COUNTRIES_LABELS.COUNTRIES_GY]: "Guyana",
    [COUNTRIES_LABELS.COUNTRIES_HT]: "Haiti",
    [COUNTRIES_LABELS.COUNTRIES_HN]: "Honduras",
    [COUNTRIES_LABELS.COUNTRIES_HK]: "Hong Kong SAR China",
    [COUNTRIES_LABELS.COUNTRIES_HU]: "Hungary",
    [COUNTRIES_LABELS.COUNTRIES_IS]: "Iceland",
    [COUNTRIES_LABELS.COUNTRIES_IN]: "India",
    [COUNTRIES_LABELS.COUNTRIES_ID]: "Indonesia",
    [COUNTRIES_LABELS.COUNTRIES_IR]: "Iran",
    [COUNTRIES_LABELS.COUNTRIES_IQ]: "Iraq",
    [COUNTRIES_LABELS.COUNTRIES_IE]: "Ireland",
    [COUNTRIES_LABELS.COUNTRIES_IM]: "Isle of Man",
    [COUNTRIES_LABELS.COUNTRIES_IL]: "Israel",
    [COUNTRIES_LABELS.COUNTRIES_IT]: "Italy",
    [COUNTRIES_LABELS.COUNTRIES_JM]: "Jamaica",
    [COUNTRIES_LABELS.COUNTRIES_JP]: "Japan",
    [COUNTRIES_LABELS.COUNTRIES_JE]: "Jersey",
    [COUNTRIES_LABELS.COUNTRIES_JO]: "Jordan",
    [COUNTRIES_LABELS.COUNTRIES_KZ]: "Kazakhstan",
    [COUNTRIES_LABELS.COUNTRIES_KE]: "Kenya",
    [COUNTRIES_LABELS.COUNTRIES_KI]: "Kiribati",
    [COUNTRIES_LABELS.COUNTRIES_XK]: "Kosovo",
    [COUNTRIES_LABELS.COUNTRIES_KW]: "Kuwait",
    [COUNTRIES_LABELS.COUNTRIES_KG]: "Kyrgyzstan",
    [COUNTRIES_LABELS.COUNTRIES_LA]: "Laos",
    [COUNTRIES_LABELS.COUNTRIES_LV]: "Latvia",
    [COUNTRIES_LABELS.COUNTRIES_LB]: "Lebanon",
    [COUNTRIES_LABELS.COUNTRIES_LS]: "Lesotho",
    [COUNTRIES_LABELS.COUNTRIES_LR]: "Liberia",
    [COUNTRIES_LABELS.COUNTRIES_LY]: "Libya",
    [COUNTRIES_LABELS.COUNTRIES_LI]: "Liechtenstein",
    [COUNTRIES_LABELS.COUNTRIES_LT]: "Lithuania",
    [COUNTRIES_LABELS.COUNTRIES_LU]: "Luxembourg",
    [COUNTRIES_LABELS.COUNTRIES_MO]: "Macau SAR China",
    [COUNTRIES_LABELS.COUNTRIES_MK]: "Macedonia",
    [COUNTRIES_LABELS.COUNTRIES_MG]: "Madagascar",
    [COUNTRIES_LABELS.COUNTRIES_MW]: "Malawi",
    [COUNTRIES_LABELS.COUNTRIES_MY]: "Malaysia",
    [COUNTRIES_LABELS.COUNTRIES_MV]: "Maldives",
    [COUNTRIES_LABELS.COUNTRIES_ML]: "Mali",
    [COUNTRIES_LABELS.COUNTRIES_MT]: "Malta",
    [COUNTRIES_LABELS.COUNTRIES_MH]: "Marshall Islands",
    [COUNTRIES_LABELS.COUNTRIES_MQ]: "Martinique",
    [COUNTRIES_LABELS.COUNTRIES_MR]: "Mauritania",
    [COUNTRIES_LABELS.COUNTRIES_MU]: "Mauritius",
    [COUNTRIES_LABELS.COUNTRIES_YT]: "Mayotte",
    [COUNTRIES_LABELS.COUNTRIES_MX]: "Mexico",
    [COUNTRIES_LABELS.COUNTRIES_FM]: "Micronesia",
    [COUNTRIES_LABELS.COUNTRIES_MD]: "Moldova",
    [COUNTRIES_LABELS.COUNTRIES_MC]: "Monaco",
    [COUNTRIES_LABELS.COUNTRIES_MN]: "Mongolia",
    [COUNTRIES_LABELS.COUNTRIES_ME]: "Montenegro",
    [COUNTRIES_LABELS.COUNTRIES_MS]: "Montserrat",
    [COUNTRIES_LABELS.COUNTRIES_MA]: "Morocco",
    [COUNTRIES_LABELS.COUNTRIES_MZ]: "Mozambique",
    [COUNTRIES_LABELS.COUNTRIES_MM]: "Myanmar (Burma)",
    [COUNTRIES_LABELS.COUNTRIES_NA]: "Namibia",
    [COUNTRIES_LABELS.COUNTRIES_NR]: "Nauru",
    [COUNTRIES_LABELS.COUNTRIES_NP]: "Nepal",
    [COUNTRIES_LABELS.COUNTRIES_NL]: "Netherlands",
    [COUNTRIES_LABELS.COUNTRIES_NC]: "New Caledonia",
    [COUNTRIES_LABELS.COUNTRIES_NZ]: "New Zealand",
    [COUNTRIES_LABELS.COUNTRIES_NI]: "Nicaragua",
    [COUNTRIES_LABELS.COUNTRIES_NE]: "Niger",
    [COUNTRIES_LABELS.COUNTRIES_NG]: "Nigeria",
    [COUNTRIES_LABELS.COUNTRIES_NU]: "Niue",
    [COUNTRIES_LABELS.COUNTRIES_NF]: "Norfolk Island",
    [COUNTRIES_LABELS.COUNTRIES_KP]: "North Korea",
    [COUNTRIES_LABELS.COUNTRIES_MP]: "Northern Mariana Islands",
    [COUNTRIES_LABELS.COUNTRIES_NO]: "Norway",
    [COUNTRIES_LABELS.COUNTRIES_OM]: "Oman",
    [COUNTRIES_LABELS.COUNTRIES_PK]: "Pakistan",
    [COUNTRIES_LABELS.COUNTRIES_PW]: "Palau",
    [COUNTRIES_LABELS.COUNTRIES_PS]: "Palestinian Territories",
    [COUNTRIES_LABELS.COUNTRIES_PA]: "Panama",
    [COUNTRIES_LABELS.COUNTRIES_PG]: "Papua New Guinea",
    [COUNTRIES_LABELS.COUNTRIES_PY]: "Paraguay",
    [COUNTRIES_LABELS.COUNTRIES_PE]: "Peru",
    [COUNTRIES_LABELS.COUNTRIES_PH]: "Philippines",
    [COUNTRIES_LABELS.COUNTRIES_PN]: "Pitcairn Islands",
    [COUNTRIES_LABELS.COUNTRIES_PL]: "Poland",
    [COUNTRIES_LABELS.COUNTRIES_PT]: "Portugal",
    [COUNTRIES_LABELS.COUNTRIES_PR]: "Puerto Rico",
    [COUNTRIES_LABELS.COUNTRIES_QA]: "Qatar",
    [COUNTRIES_LABELS.COUNTRIES_RE]: "Réunion",
    [COUNTRIES_LABELS.COUNTRIES_RO]: "Romania",
    [COUNTRIES_LABELS.COUNTRIES_RU]: "Russia",
    [COUNTRIES_LABELS.COUNTRIES_RW]: "Rwanda",
    [COUNTRIES_LABELS.COUNTRIES_WS]: "Samoa",
    [COUNTRIES_LABELS.COUNTRIES_SM]: "San Marino",
    [COUNTRIES_LABELS.COUNTRIES_ST]: "São Tomé & Príncipe",
    [COUNTRIES_LABELS.COUNTRIES_SA]: "Saudi Arabia",
    [COUNTRIES_LABELS.COUNTRIES_SN]: "Senegal",
    [COUNTRIES_LABELS.COUNTRIES_RS]: "Serbia",
    [COUNTRIES_LABELS.COUNTRIES_SC]: "Seychelles",
    [COUNTRIES_LABELS.COUNTRIES_SL]: "Sierra Leone",
    [COUNTRIES_LABELS.COUNTRIES_SG]: "Singapore",
    [COUNTRIES_LABELS.COUNTRIES_SX]: "Sint Maarten",
    [COUNTRIES_LABELS.COUNTRIES_SK]: "Slovakia",
    [COUNTRIES_LABELS.COUNTRIES_SI]: "Slovenia",
    [COUNTRIES_LABELS.COUNTRIES_SB]: "Solomon Islands",
    [COUNTRIES_LABELS.COUNTRIES_SO]: "Somalia",
    [COUNTRIES_LABELS.COUNTRIES_ZA]: "South Africa",
    [COUNTRIES_LABELS.COUNTRIES_GS]: "South Georgia & South Sandwich Islands",
    [COUNTRIES_LABELS.COUNTRIES_KR]: "South Korea",
    [COUNTRIES_LABELS.COUNTRIES_SS]: "South Sudan",
    [COUNTRIES_LABELS.COUNTRIES_ES]: "Spain",
    [COUNTRIES_LABELS.COUNTRIES_LK]: "Sri Lanka",
    [COUNTRIES_LABELS.COUNTRIES_BL]: "St. Barthélemy",
    [COUNTRIES_LABELS.COUNTRIES_SH]: "St. Helena",
    [COUNTRIES_LABELS.COUNTRIES_KN]: "St. Kitts & Nevis",
    [COUNTRIES_LABELS.COUNTRIES_LC]: "St. Lucia",
    [COUNTRIES_LABELS.COUNTRIES_MF]: "St. Martin",
    [COUNTRIES_LABELS.COUNTRIES_PM]: "St. Pierre & Miquelon",
    [COUNTRIES_LABELS.COUNTRIES_VC]: "St. Vincent & Grenadines",
    [COUNTRIES_LABELS.COUNTRIES_SD]: "Sudan",
    [COUNTRIES_LABELS.COUNTRIES_SR]: "Suriname",
    [COUNTRIES_LABELS.COUNTRIES_SJ]: "Svalbard & Jan Mayen",
    [COUNTRIES_LABELS.COUNTRIES_SZ]: "Swaziland",
    [COUNTRIES_LABELS.COUNTRIES_SE]: "Sweden",
    [COUNTRIES_LABELS.COUNTRIES_CH]: "Switzerland",
    [COUNTRIES_LABELS.COUNTRIES_SY]: "Syria",
    [COUNTRIES_LABELS.COUNTRIES_TW]: "Taiwan",
    [COUNTRIES_LABELS.COUNTRIES_TJ]: "Tajikistan",
    [COUNTRIES_LABELS.COUNTRIES_TZ]: "Tanzania",
    [COUNTRIES_LABELS.COUNTRIES_TH]: "Thailand",
    [COUNTRIES_LABELS.COUNTRIES_TL]: "Timor-Leste",
    [COUNTRIES_LABELS.COUNTRIES_TG]: "Togo",
    [COUNTRIES_LABELS.COUNTRIES_TK]: "Tokelau",
    [COUNTRIES_LABELS.COUNTRIES_TO]: "Tonga",
    [COUNTRIES_LABELS.COUNTRIES_TT]: "Trinidad & Tobago",
    [COUNTRIES_LABELS.COUNTRIES_TA]: "Tristan da Cunha",
    [COUNTRIES_LABELS.COUNTRIES_TN]: "Tunisia",
    [COUNTRIES_LABELS.COUNTRIES_TR]: "Turkey",
    [COUNTRIES_LABELS.COUNTRIES_TM]: "Turkmenistan",
    [COUNTRIES_LABELS.COUNTRIES_TC]: "Turks & Caicos Islands",
    [COUNTRIES_LABELS.COUNTRIES_TV]: "Tuvalu",
    [COUNTRIES_LABELS.COUNTRIES_UM]: "U.S. Outlying Islands",
    [COUNTRIES_LABELS.COUNTRIES_VI]: "U.S. Virgin Islands",
    [COUNTRIES_LABELS.COUNTRIES_UG]: "Uganda",
    [COUNTRIES_LABELS.COUNTRIES_UA]: "Ukraine",
    [COUNTRIES_LABELS.COUNTRIES_AE]: "United Arab Emirates",
    [COUNTRIES_LABELS.COUNTRIES_GB]: "United Kingdom",
    [COUNTRIES_LABELS.COUNTRIES_US]: "United States",
    [COUNTRIES_LABELS.COUNTRIES_UY]: "Uruguay",
    [COUNTRIES_LABELS.COUNTRIES_UZ]: "Uzbekistan",
    [COUNTRIES_LABELS.COUNTRIES_VU]: "Vanuatu",
    [COUNTRIES_LABELS.COUNTRIES_VA]: "Vatican City",
    [COUNTRIES_LABELS.COUNTRIES_VE]: "Venezuela",
    [COUNTRIES_LABELS.COUNTRIES_VN]: "Vietnam",
    [COUNTRIES_LABELS.COUNTRIES_WF]: "Wallis & Futuna",
    [COUNTRIES_LABELS.COUNTRIES_EH]: "Western Sahara",
    [COUNTRIES_LABELS.COUNTRIES_YE]: "Yemen",
    [COUNTRIES_LABELS.COUNTRIES_ZM]: "Zambia",
    [COUNTRIES_LABELS.COUNTRIES_ZW]: "Zimbabwe",
};

export default LABELS;
