import { SupportedLocale } from "../../../common/locale/labels";

import en_US from "./en_US";
import ja_JP from "./ja_JP";
import ko_KR from "./ko_KR";
import ru_RU from "./ru_RU";
import th_TH from "./th_TH";
import zh_CN from "./zh_CN";
import zh_TW from "./zh_TW";

export const locales = {
    [SupportedLocale.EN_US]: { locale: SupportedLocale.EN_US, messages: en_US },
    [SupportedLocale.JA_JP]: { locale: SupportedLocale.JA_JP, messages: ja_JP },
    [SupportedLocale.ZH_CN]: { locale: SupportedLocale.ZH_CN, messages: zh_CN },
    [SupportedLocale.ZH_TW]: { locale: SupportedLocale.ZH_TW, messages: zh_TW },
    [SupportedLocale.RU_RU]: { locale: SupportedLocale.RU_RU, messages: ru_RU },
    [SupportedLocale.TH_TH]: { locale: SupportedLocale.TH_TH, messages: th_TH },
    [SupportedLocale.KO_KR]: { locale: SupportedLocale.KO_KR, messages: ko_KR },
};

export const getMessage = (key: string, locale: string) => locales[locale]?.messages[key] || key;
