import * as COUNTRIES_LABELS from "../labels/countries";

export const LABELS = {
    [COUNTRIES_LABELS.COUNTRIES_IS]: "ประเทศไอซ์แลนด์",
    [COUNTRIES_LABELS.COUNTRIES_IE]: "ไอร์แลนด์",
    [COUNTRIES_LABELS.COUNTRIES_AZ]: "อาเซอร์ไบจาน",
    [COUNTRIES_LABELS.COUNTRIES_AC]: "เกาะสวรรค์",
    [COUNTRIES_LABELS.COUNTRIES_AF]: "ประเทศอัฟกานิสถาน",
    [COUNTRIES_LABELS.COUNTRIES_US]: "สหรัฐอเมริกา",
    [COUNTRIES_LABELS.COUNTRIES_AE]: "สหรัฐอาหรับเอมิเรตส์",
    [COUNTRIES_LABELS.COUNTRIES_DZ]: "ประเทศแอลจีเรีย",
    [COUNTRIES_LABELS.COUNTRIES_AR]: "อาร์เจนตินา",
    [COUNTRIES_LABELS.COUNTRIES_AW]: "อัลบ้า",
    [COUNTRIES_LABELS.COUNTRIES_AL]: "ประเทศแอลเบเนีย",
    [COUNTRIES_LABELS.COUNTRIES_AM]: "อาร์เมเนีย",
    [COUNTRIES_LABELS.COUNTRIES_AI]: "แอน",
    [COUNTRIES_LABELS.COUNTRIES_AO]: "แองโกลา",
    [COUNTRIES_LABELS.COUNTRIES_AG]: "Antigua Berbuda",
    [COUNTRIES_LABELS.COUNTRIES_AD]: "อันดรา",
    [COUNTRIES_LABELS.COUNTRIES_YE]: "เยเมน",
    [COUNTRIES_LABELS.COUNTRIES_GB]: "อังกฤษ",
    [COUNTRIES_LABELS.COUNTRIES_IL]: "ประเทศอิสราเอล",
    [COUNTRIES_LABELS.COUNTRIES_IT]: "อิตาลี",
    [COUNTRIES_LABELS.COUNTRIES_IQ]: "อิรัก",
    [COUNTRIES_LABELS.COUNTRIES_IR]: "อิหร่าน",
    [COUNTRIES_LABELS.COUNTRIES_IN]: "อินเดีย",
    [COUNTRIES_LABELS.COUNTRIES_ID]: "ประเทศอินโดนีเซีย",
    [COUNTRIES_LABELS.COUNTRIES_WF]: "Waris Futna",
    [COUNTRIES_LABELS.COUNTRIES_UG]: "ยูกันดา",
    [COUNTRIES_LABELS.COUNTRIES_UA]: "ยูเครน",
    [COUNTRIES_LABELS.COUNTRIES_UZ]: "อุซเบกิสถาน",
    [COUNTRIES_LABELS.COUNTRIES_UY]: "อุรุกวัย",
    [COUNTRIES_LABELS.COUNTRIES_EC]: "เอกวาดอร์",
    [COUNTRIES_LABELS.COUNTRIES_EG]: "อียิปต์",
    [COUNTRIES_LABELS.COUNTRIES_EE]: "ประเทศเอสโตเนีย",
    [COUNTRIES_LABELS.COUNTRIES_ET]: "เอธิโอเปีย",
    [COUNTRIES_LABELS.COUNTRIES_ER]: "เอริเทรีย",
    [COUNTRIES_LABELS.COUNTRIES_SV]: "เอลซัลวาดอร์",
    [COUNTRIES_LABELS.COUNTRIES_AU]: "ออสเตรเลีย",
    [COUNTRIES_LABELS.COUNTRIES_AT]: "ประเทศออสเตรีย",
    [COUNTRIES_LABELS.COUNTRIES_AX]: "หมู่เกาะออร์แลนโด",
    [COUNTRIES_LABELS.COUNTRIES_OM]: "โอมาน",
    [COUNTRIES_LABELS.COUNTRIES_NL]: "เนเธอร์แลนด์",
    [COUNTRIES_LABELS.COUNTRIES_BQ]: "แคริบเบียนดินแดนดัตช์",
    [COUNTRIES_LABELS.COUNTRIES_GH]: "ประเทศกานา",
    [COUNTRIES_LABELS.COUNTRIES_CV]: "Carbo Verde",
    [COUNTRIES_LABELS.COUNTRIES_GG]: "Gangnzie",
    [COUNTRIES_LABELS.COUNTRIES_GY]: "Gaiana",
    [COUNTRIES_LABELS.COUNTRIES_KZ]: "คาซัคสถาน",
    [COUNTRIES_LABELS.COUNTRIES_QA]: "กาตาร์",
    [COUNTRIES_LABELS.COUNTRIES_CA]: "แคนาดา",
    [COUNTRIES_LABELS.COUNTRIES_IC]: "หมู่เกาะคานารี",
    [COUNTRIES_LABELS.COUNTRIES_GA]: "กาบบอน",
    [COUNTRIES_LABELS.COUNTRIES_CM]: "แคเมอรูน",
    [COUNTRIES_LABELS.COUNTRIES_GM]: "แกมเบีย",
    [COUNTRIES_LABELS.COUNTRIES_KH]: "กัมพูชา",
    [COUNTRIES_LABELS.COUNTRIES_GN]: "กินี",
    [COUNTRIES_LABELS.COUNTRIES_GW]: "กินี Visau",
    [COUNTRIES_LABELS.COUNTRIES_CY]: "ไซปรัส",
    [COUNTRIES_LABELS.COUNTRIES_CU]: "คิวบา",
    [COUNTRIES_LABELS.COUNTRIES_CW]: "คู่หู",
    [COUNTRIES_LABELS.COUNTRIES_GR]: "กรีซ",
    [COUNTRIES_LABELS.COUNTRIES_KI]: "Kiribass",
    [COUNTRIES_LABELS.COUNTRIES_KG]: "คีร์กีซสถาน",
    [COUNTRIES_LABELS.COUNTRIES_GT]: "กัวเตมาลา",
    [COUNTRIES_LABELS.COUNTRIES_GP]: "คนโง่",
    [COUNTRIES_LABELS.COUNTRIES_GU]: "กวม",
    [COUNTRIES_LABELS.COUNTRIES_KW]: "คูเวต",
    [COUNTRIES_LABELS.COUNTRIES_CK]: "เกาะปรุงอาหาร",
    [COUNTRIES_LABELS.COUNTRIES_GL]: "กรีนแลนด์",
    [COUNTRIES_LABELS.COUNTRIES_CX]: "เกาะคริสต์มาส",
    [COUNTRIES_LABELS.COUNTRIES_GE]: "รัฐจอร์เจีย",
    [COUNTRIES_LABELS.COUNTRIES_GD]: "เกรเนดา",
    [COUNTRIES_LABELS.COUNTRIES_HR]: "โครเอเชีย",
    [COUNTRIES_LABELS.COUNTRIES_KY]: "หมู่เกาะเคย์เเมน",
    [COUNTRIES_LABELS.COUNTRIES_KE]: "ประเทศเคนยา",
    [COUNTRIES_LABELS.COUNTRIES_CI]: "ศาล jiboire",
    [COUNTRIES_LABELS.COUNTRIES_CC]: "เกาะ Cocos (Keyling)",
    [COUNTRIES_LABELS.COUNTRIES_CR]: "คอสตาริกา",
    [COUNTRIES_LABELS.COUNTRIES_XK]: "โคโซโว",
    [COUNTRIES_LABELS.COUNTRIES_KM]: "โคโมโร",
    [COUNTRIES_LABELS.COUNTRIES_CO]: "โคลัมเบีย",
    [COUNTRIES_LABELS.COUNTRIES_CG]: "คองโกสาธารณรัฐ (อาคารพี่)",
    [COUNTRIES_LABELS.COUNTRIES_CD]: "สาธารณรัฐประชาธิปไตยคองโก (Kinshasa)",
    [COUNTRIES_LABELS.COUNTRIES_SA]: "ซาอุดิอาราเบีย",
    [COUNTRIES_LABELS.COUNTRIES_WS]: "ซามัว",
    [COUNTRIES_LABELS.COUNTRIES_BL]: "เกาะ Saint Baltermy",
    [COUNTRIES_LABELS.COUNTRIES_MF]: "ซันมาร์ติน",
    [COUNTRIES_LABELS.COUNTRIES_ST]: "ซานตา",
    [COUNTRIES_LABELS.COUNTRIES_ZM]: "ชาวแซมเบีย",
    [COUNTRIES_LABELS.COUNTRIES_PM]: "เกาะ Sanpierre Micron Island",
    [COUNTRIES_LABELS.COUNTRIES_SM]: "Sun Marino",
    [COUNTRIES_LABELS.COUNTRIES_SL]: "เซียร์ราลีโอน",
    [COUNTRIES_LABELS.COUNTRIES_DJ]: "jibuchi",
    [COUNTRIES_LABELS.COUNTRIES_GI]: "ยิบรอลตาร์",
    [COUNTRIES_LABELS.COUNTRIES_JE]: "เสื้อ",
    [COUNTRIES_LABELS.COUNTRIES_JM]: "จาเมกา",
    [COUNTRIES_LABELS.COUNTRIES_SY]: "ซีเรีย",
    [COUNTRIES_LABELS.COUNTRIES_SG]: "สิงคโปร์",
    [COUNTRIES_LABELS.COUNTRIES_SX]: "Sint Maruten",
    [COUNTRIES_LABELS.COUNTRIES_ZW]: "ซิมบับเว",
    [COUNTRIES_LABELS.COUNTRIES_CH]: "สวิตเซอร์แลนด์",
    [COUNTRIES_LABELS.COUNTRIES_SE]: "สวีเดน",
    [COUNTRIES_LABELS.COUNTRIES_SD]: "ซูดาน",
    [COUNTRIES_LABELS.COUNTRIES_SJ]: "หมู่เกาะ Subarbar / เกาะ Yanmaien",
    [COUNTRIES_LABELS.COUNTRIES_ES]: "สเปน",
    [COUNTRIES_LABELS.COUNTRIES_SR]: "ลินัม",
    [COUNTRIES_LABELS.COUNTRIES_LK]: "ศรีลังกา",
    [COUNTRIES_LABELS.COUNTRIES_SK]: "สโลวาเกีย",
    [COUNTRIES_LABELS.COUNTRIES_SI]: "สโลวีเนีย",
    [COUNTRIES_LABELS.COUNTRIES_SZ]: "Swaji Land",
    [COUNTRIES_LABELS.COUNTRIES_EA]: "Suuta Melilla",
    [COUNTRIES_LABELS.COUNTRIES_SC]: "Saechel",
    [COUNTRIES_LABELS.COUNTRIES_SN]: "เซเนกัล",
    [COUNTRIES_LABELS.COUNTRIES_RS]: "ประเทศเซอร์เบีย",
    [COUNTRIES_LABELS.COUNTRIES_KN]: "St. Christopher Navis",
    [COUNTRIES_LABELS.COUNTRIES_VC]: "หมู่เกาะเซนต์วินเซนต์ Grenadine",
    [COUNTRIES_LABELS.COUNTRIES_SH]: "เซนต์เฮเลนา",
    [COUNTRIES_LABELS.COUNTRIES_LC]: "เซนต์ลูเซีย",
    [COUNTRIES_LABELS.COUNTRIES_SO]: "โซมาเลีย",
    [COUNTRIES_LABELS.COUNTRIES_SB]: "หมู่เกาะโซโลมอน",
    [COUNTRIES_LABELS.COUNTRIES_TC]: "เกาะ Thakus Kaikos",
    [COUNTRIES_LABELS.COUNTRIES_TH]: "ประเทศไทย",
    [COUNTRIES_LABELS.COUNTRIES_TJ]: "ทาจิกิสถาน",
    [COUNTRIES_LABELS.COUNTRIES_TZ]: "ประเทศแทนซาเนีย",
    [COUNTRIES_LABELS.COUNTRIES_CZ]: "สาธารณรัฐเช็ก",
    [COUNTRIES_LABELS.COUNTRIES_TD]: "ชาด",
    [COUNTRIES_LABELS.COUNTRIES_TN]: "ตูนิเซีย",
    [COUNTRIES_LABELS.COUNTRIES_CL]: "ชิลี",
    [COUNTRIES_LABELS.COUNTRIES_TV]: "สึบารุ",
    [COUNTRIES_LABELS.COUNTRIES_DG]: "เกาะ Diegogarcia",
    [COUNTRIES_LABELS.COUNTRIES_DK]: "เดนมาร์ก",
    [COUNTRIES_LABELS.COUNTRIES_DE]: "ประเทศเยอรมนี",
    [COUNTRIES_LABELS.COUNTRIES_TG]: "ไป",
    [COUNTRIES_LABELS.COUNTRIES_TK]: "Tokerau",
    [COUNTRIES_LABELS.COUNTRIES_DO]: "สาธารณรัฐโดมินิกัน",
    [COUNTRIES_LABELS.COUNTRIES_DM]: "ประเทศโดมินิกา",
    [COUNTRIES_LABELS.COUNTRIES_TA]: "Tristan da Cunia",
    [COUNTRIES_LABELS.COUNTRIES_TT]: "สาธารณรัฐตรินิแดดและโตเบโก",
    [COUNTRIES_LABELS.COUNTRIES_TM]: "แรงบิด Menistan",
    [COUNTRIES_LABELS.COUNTRIES_TR]: "türkiye",
    [COUNTRIES_LABELS.COUNTRIES_TO]: "ตองกา",
    [COUNTRIES_LABELS.COUNTRIES_NG]: "ประเทศไนจีเรีย",
    [COUNTRIES_LABELS.COUNTRIES_NR]: "นาอร์เรล",
    [COUNTRIES_LABELS.COUNTRIES_NA]: "นามิเบีย",
    [COUNTRIES_LABELS.COUNTRIES_NU]: "เกาะ Niyoe",
    [COUNTRIES_LABELS.COUNTRIES_NI]: "ประเทศนิการากัว",
    [COUNTRIES_LABELS.COUNTRIES_NE]: "ไส้เดือน",
    [COUNTRIES_LABELS.COUNTRIES_NC]: "นิวแคลิโดเนีย",
    [COUNTRIES_LABELS.COUNTRIES_NZ]: "นิวซีแลนด์",
    [COUNTRIES_LABELS.COUNTRIES_NP]: "ประเทศเนปาล",
    [COUNTRIES_LABELS.COUNTRIES_NF]: "เกาะนอร์โฟล์ค",
    [COUNTRIES_LABELS.COUNTRIES_NO]: "นอร์เวย์",
    [COUNTRIES_LABELS.COUNTRIES_BH]: "รถม้า",
    [COUNTRIES_LABELS.COUNTRIES_HT]: "เฮติ",
    [COUNTRIES_LABELS.COUNTRIES_PK]: "ปากีสถาน",
    [COUNTRIES_LABELS.COUNTRIES_VA]: "เมืองวาติกัน",
    [COUNTRIES_LABELS.COUNTRIES_PA]: "ปานามา",
    [COUNTRIES_LABELS.COUNTRIES_VU]: "Vanuatsu",
    [COUNTRIES_LABELS.COUNTRIES_BS]: "บาฮามาส",
    [COUNTRIES_LABELS.COUNTRIES_PG]: "ปาปัวนิวกินี",
    [COUNTRIES_LABELS.COUNTRIES_BM]: "เบอร์มิวดา",
    [COUNTRIES_LABELS.COUNTRIES_PW]: "พาลา",
    [COUNTRIES_LABELS.COUNTRIES_PY]: "ประเทศปารากวัย",
    [COUNTRIES_LABELS.COUNTRIES_BB]: "บาร์เบโดส",
    [COUNTRIES_LABELS.COUNTRIES_PS]: "ปาเลสไตน์",
    [COUNTRIES_LABELS.COUNTRIES_HU]: "ฮังการี",
    [COUNTRIES_LABELS.COUNTRIES_BD]: "ประเทศบังคลาเทศ",
    [COUNTRIES_LABELS.COUNTRIES_PN]: "หมู่เกาะพิตเซียน",
    [COUNTRIES_LABELS.COUNTRIES_FJ]: "ฟิจิ",
    [COUNTRIES_LABELS.COUNTRIES_PH]: "ฟิลิปปินส์",
    [COUNTRIES_LABELS.COUNTRIES_FI]: "ฟินแลนด์",
    [COUNTRIES_LABELS.COUNTRIES_BT]: "ภูฏาน",
    [COUNTRIES_LABELS.COUNTRIES_PR]: "เปอร์โตริโก้",
    [COUNTRIES_LABELS.COUNTRIES_FO]: "เพื่อนเกาะ",
    [COUNTRIES_LABELS.COUNTRIES_FK]: "หมู่เกาะฟัลก์แลนด์",
    [COUNTRIES_LABELS.COUNTRIES_BR]: "บราซิล",
    [COUNTRIES_LABELS.COUNTRIES_FR]: "ฝรั่งเศส",
    [COUNTRIES_LABELS.COUNTRIES_BG]: "บัลแกเรีย",
    [COUNTRIES_LABELS.COUNTRIES_BF]: "Burkina Faso",
    [COUNTRIES_LABELS.COUNTRIES_BN]: "บรูไน",
    [COUNTRIES_LABELS.COUNTRIES_BI]: "แปรง",
    [COUNTRIES_LABELS.COUNTRIES_VN]: "เวียดนาม",
    [COUNTRIES_LABELS.COUNTRIES_BJ]: "เบนิน",
    [COUNTRIES_LABELS.COUNTRIES_VE]: "เวเนซุเอลา",
    [COUNTRIES_LABELS.COUNTRIES_BY]: "เบลารุส",
    [COUNTRIES_LABELS.COUNTRIES_BZ]: "ชาวเบลีซ",
    [COUNTRIES_LABELS.COUNTRIES_PE]: "เปรู",
    [COUNTRIES_LABELS.COUNTRIES_BE]: "เบลเยียม",
    [COUNTRIES_LABELS.COUNTRIES_PL]: "โปแลนด์",
    [COUNTRIES_LABELS.COUNTRIES_BA]: "บอสเนีย-เฮอร์เซโกวีนา",
    [COUNTRIES_LABELS.COUNTRIES_BW]: "บอตวอนา",
    [COUNTRIES_LABELS.COUNTRIES_BO]: "ชาวโบลิเวีย",
    [COUNTRIES_LABELS.COUNTRIES_PT]: "โปรตุเกส",
    [COUNTRIES_LABELS.COUNTRIES_HN]: "ฮอนดูรัส",
    [COUNTRIES_LABELS.COUNTRIES_MH]: "หมู่เกาะมาร์แชลล์",
    [COUNTRIES_LABELS.COUNTRIES_MK]: "มาซิโดเนีย",
    [COUNTRIES_LABELS.COUNTRIES_MG]: "มาดากัสการ์",
    [COUNTRIES_LABELS.COUNTRIES_YT]: "เกาะมายูท",
    [COUNTRIES_LABELS.COUNTRIES_MW]: "มาลาวี",
    [COUNTRIES_LABELS.COUNTRIES_ML]: "มารี",
    [COUNTRIES_LABELS.COUNTRIES_MT]: "มอลตา",
    [COUNTRIES_LABELS.COUNTRIES_MQ]: "มอลตินี่",
    [COUNTRIES_LABELS.COUNTRIES_MY]: "ประเทศมาเลเซีย",
    [COUNTRIES_LABELS.COUNTRIES_IM]: "เกาะ",
    [COUNTRIES_LABELS.COUNTRIES_FM]: "สหพันธ์ไมโครนีเซีย",
    [COUNTRIES_LABELS.COUNTRIES_MM]: "พม่า",
    [COUNTRIES_LABELS.COUNTRIES_MX]: "เม็กซิโก",
    [COUNTRIES_LABELS.COUNTRIES_MU]: "ประเทศมอริเชียส",
    [COUNTRIES_LABELS.COUNTRIES_MR]: "ประเทศมอริเตเนีย",
    [COUNTRIES_LABELS.COUNTRIES_MZ]: "ประเทศโมซัมบิก",
    [COUNTRIES_LABELS.COUNTRIES_MC]: "โมนาโก",
    [COUNTRIES_LABELS.COUNTRIES_MV]: "มัลดีฟส์",
    [COUNTRIES_LABELS.COUNTRIES_MD]: "แม่เหล็ก",
    [COUNTRIES_LABELS.COUNTRIES_MA]: "ประเทศโมร็อกโก",
    [COUNTRIES_LABELS.COUNTRIES_MN]: "มองโกเลีย",
    [COUNTRIES_LABELS.COUNTRIES_ME]: "มอนเตเนโกร",
    [COUNTRIES_LABELS.COUNTRIES_MS]: "มอนแทรต",
    [COUNTRIES_LABELS.COUNTRIES_JO]: "จอร์แดน",
    [COUNTRIES_LABELS.COUNTRIES_LA]: "ลาว",
    [COUNTRIES_LABELS.COUNTRIES_LV]: "ลัตเวีย",
    [COUNTRIES_LABELS.COUNTRIES_LT]: "ลิทัวเนีย",
    [COUNTRIES_LABELS.COUNTRIES_LY]: "ลิเบีย",
    [COUNTRIES_LABELS.COUNTRIES_LI]: "ลิกเตนสไตน์",
    [COUNTRIES_LABELS.COUNTRIES_LR]: "ไลบีเรีย",
    [COUNTRIES_LABELS.COUNTRIES_RO]: "ประเทศโรมาเนีย",
    [COUNTRIES_LABELS.COUNTRIES_LU]: "ลักเซมเบิร์ก",
    [COUNTRIES_LABELS.COUNTRIES_RW]: "รวันดา",
    [COUNTRIES_LABELS.COUNTRIES_LS]: "เลสอท",
    [COUNTRIES_LABELS.COUNTRIES_LB]: "ชาวเลบานอน",
    [COUNTRIES_LABELS.COUNTRIES_RE]: "เกาะเรอูนียง",
    [COUNTRIES_LABELS.COUNTRIES_RU]: "รัสเซีย",
    [COUNTRIES_LABELS.COUNTRIES_IO]: "ภูมิภาคมหาสมุทรอินเดียอินเดีย",
    [COUNTRIES_LABELS.COUNTRIES_VG]: "หมู่เกาะบริติชเวอร์จิน",
    [COUNTRIES_LABELS.COUNTRIES_EH]: "เวสต์ซาฮาร่า",
    [COUNTRIES_LABELS.COUNTRIES_GQ]: "เส้นศูนย์สูตร",
    [COUNTRIES_LABELS.COUNTRIES_TW]: "ไต้หวัน",
    [COUNTRIES_LABELS.COUNTRIES_KR]: "เกาหลีใต้",
    [COUNTRIES_LABELS.COUNTRIES_CF]: "แอฟริกาตอนกลาง",
    [COUNTRIES_LABELS.COUNTRIES_MO]: "สาธารณรัฐประชาชนจีนมาเก๊ามาเก๊า",
    [COUNTRIES_LABELS.COUNTRIES_HK]: "สาธารณรัฐประชาชนจีนฮ่องกงเขตปกครองพิเศษ",
    [COUNTRIES_LABELS.COUNTRIES_CN]: "จีน",
    [COUNTRIES_LABELS.COUNTRIES_KP]: "สาธารณรัฐประชาธิปไตยเกาหลี",
    [COUNTRIES_LABELS.COUNTRIES_TL]: "ติมอร์ตะวันออก",
    [COUNTRIES_LABELS.COUNTRIES_ZA]: "แอฟริกาใต้",
    [COUNTRIES_LABELS.COUNTRIES_GS]: "จอร์เจียใต้หมู่เกาะแซนวิชใต้",
    [COUNTRIES_LABELS.COUNTRIES_SS]: "ซูดานใต้",
    [COUNTRIES_LABELS.COUNTRIES_AQ]: "แอนตาร์กติก",
    [COUNTRIES_LABELS.COUNTRIES_JP]: "ประเทศญี่ปุ่น",
    [COUNTRIES_LABELS.COUNTRIES_GF]: "Giana ฝรั่งเศส",
    [COUNTRIES_LABELS.COUNTRIES_PF]: "โปลินีเซียฝรั่งเศส",
    [COUNTRIES_LABELS.COUNTRIES_TF]: "หมู่เกาะฝรั่งเศสใต้",
    [COUNTRIES_LABELS.COUNTRIES_VI]: "หมู่เกาะเวอร์จินของสหรัฐอเมริกา",
    [COUNTRIES_LABELS.COUNTRIES_AS]: "ซามัวอาณาเขตของสหรัฐอเมริกา",
    [COUNTRIES_LABELS.COUNTRIES_UM]: "หมู่เกาะแปซิฟิกของสหรัฐอเมริกา",
    [COUNTRIES_LABELS.COUNTRIES_MP]: "หมู่เกาะนอร์ทมาเรียนา",
};

export default LABELS;
