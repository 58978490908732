export const NAMESPACE_NAME = "UnivapayCheckout";

export const SCRIPT_NAME = "client/checkout.js";

export const IFRAME_NAME = "univapay-checkout-connector";
export const IFRAME_ZINDEX = 2147483647;
export const LOAD_TIMEOUT = 20000;
export const SUBMIT_TIMEOUT = 300000; // 5 minutes;

export const AUTO_CLOSE_TIMEOUT_DEFAULT = 3000;

export const CHECKOUT_PAYMENT = "payment";
export const CHECKOUT_TOKEN = "token";
export const CHECKOUT_ORDER = "order";

export const POLLING_INTERVAL = 1000; // 1 second;

export const BUTTON_TEXT_DEFAULT = "Pay with UnivaPay";
export const HEADER_DEFAULT = "UnivaPay";

export const DARK_COLOR = "#000000";
export const LIGHT_COLOR = "#FFFFFF";

export const TEXT_DARK_THEME_COLOR = "#F9FAFB";
export const TEXT_LIGHT_THEME_COLOR = "#262626";

export const OVERLAY_DARK_COLOR = "rgba(255, 255, 255, 0.6)";
export const OVERLAY_LIGHT_COLOR = "rgba(0, 0, 0, 0.6)";

export const FORM_CUSTOMER_INFO = "formCustomerInfo";
export const FORM_ADDRESS = "formAddress";
export const FORM_CHECKOUT_NAME = "formCheckout";
export const FORM_ONLINE_DATA = "formOnlineData";
export const FORM_ONLINE_ADDRESS = "formOnlineAddress";
export const FORM_TOKEN_SELECT_NAME = "formTokenSelect";
export const FORM_CODE_CONFIRM_NAME = "formCodeConfirm";

// Currencies' decimal point according to ISO-4217
export const CURRENCIES_DECIMALS: { [decimal: number]: string[] } = {
    0: [
        "bif",
        "byr",
        "clp",
        "djf",
        "gnf",
        "isk",
        "jpy",
        "kmf",
        "krw",
        "pyg",
        "rwf",
        "ugx",
        "uyi",
        "vnd",
        "vuv",
        "xaf",
        "xof",
        "xpf",
    ],
    2: [
        "aed",
        "afn",
        "all",
        "amd",
        "ang",
        "aoa",
        "ars",
        "aud",
        "awg",
        "azn",
        "bam",
        "bbd",
        "bdt",
        "bgn",
        "bmd",
        "bnd",
        "bob",
        "bov",
        "brl",
        "bsd",
        "btn",
        "bwp",
        "bzd",
        "cad",
        "cdf",
        "che",
        "chf",
        "chw",
        "cny",
        "cop",
        "cou",
        "crc",
        "cuc",
        "cup",
        "cve",
        "czk",
        "dkk",
        "dop",
        "dzd",
        "egp",
        "ern",
        "etb",
        "eur",
        "fjd",
        "fkp",
        "gbp",
        "gel",
        "ghs",
        "gip",
        "gmd",
        "gtq",
        "gyd",
        "hkd",
        "hnl",
        "hrk",
        "htg",
        "huf",
        "idr",
        "ils",
        "inr",
        "irr",
        "jmd",
        "kes",
        "kgs",
        "khr",
        "kpw",
        "kyd",
        "kzt",
        "lak",
        "lbp",
        "lkr",
        "lrd",
        "lsl",
        "ltl",
        "mad",
        "mdl",
        "mga",
        "mkd",
        "mmk",
        "mnt",
        "mop",
        "mro",
        "mur",
        "mvr",
        "mwk",
        "mxn",
        "mxv",
        "myr",
        "mzn",
        "nad",
        "ngn",
        "nio",
        "nok",
        "npr",
        "nzd",
        "pab",
        "pen",
        "pgk",
        "php",
        "pkr",
        "pln",
        "qar",
        "ron",
        "rsd",
        "rub",
        "sar",
        "sbd",
        "scr",
        "sdg",
        "sek",
        "sgd",
        "shp",
        "sll",
        "sos",
        "srd",
        "ssp",
        "std",
        "svc",
        "syp",
        "szl",
        "thb",
        "tjs",
        "tmt",
        "top",
        "try",
        "ttd",
        "twd",
        "tzs",
        "uah",
        "usd",
        "usn",
        "uyu",
        "uzs",
        "vef",
        "wst",
        "xcd",
        "yer",
        "zar",
        "zmw",
        "zwl",
    ],
    3: ["bhd", "iqd", "jod", "kwd", "lyd", "omr", "tnd"],
    4: ["clf"],
};
