export const COUNTRIES_AF = "AF";
export const COUNTRIES_AX = "AX";
export const COUNTRIES_AL = "AL";
export const COUNTRIES_DZ = "DZ";
export const COUNTRIES_AS = "AS";
export const COUNTRIES_AD = "AD";
export const COUNTRIES_AO = "AO";
export const COUNTRIES_AI = "AI";
export const COUNTRIES_AQ = "AQ";
export const COUNTRIES_AG = "AG";
export const COUNTRIES_AR = "AR";
export const COUNTRIES_AM = "AM";
export const COUNTRIES_AW = "AW";
export const COUNTRIES_AC = "AC";
export const COUNTRIES_AU = "AU";
export const COUNTRIES_AT = "AT";
export const COUNTRIES_AZ = "AZ";
export const COUNTRIES_BS = "BS";
export const COUNTRIES_BH = "BH";
export const COUNTRIES_BD = "BD";
export const COUNTRIES_BB = "BB";
export const COUNTRIES_BY = "BY";
export const COUNTRIES_BE = "BE";
export const COUNTRIES_BZ = "BZ";
export const COUNTRIES_BJ = "BJ";
export const COUNTRIES_BM = "BM";
export const COUNTRIES_BT = "BT";
export const COUNTRIES_BO = "BO";
export const COUNTRIES_BA = "BA";
export const COUNTRIES_BW = "BW";
export const COUNTRIES_BR = "BR";
export const COUNTRIES_IO = "IO";
export const COUNTRIES_VG = "VG";
export const COUNTRIES_BN = "BN";
export const COUNTRIES_BG = "BG";
export const COUNTRIES_BF = "BF";
export const COUNTRIES_BI = "BI";
export const COUNTRIES_KH = "KH";
export const COUNTRIES_CM = "CM";
export const COUNTRIES_CA = "CA";
export const COUNTRIES_IC = "IC";
export const COUNTRIES_CV = "CV";
export const COUNTRIES_BQ = "BQ";
export const COUNTRIES_KY = "KY";
export const COUNTRIES_CF = "CF";
export const COUNTRIES_EA = "EA";
export const COUNTRIES_TD = "TD";
export const COUNTRIES_CL = "CL";
export const COUNTRIES_CN = "CN";
export const COUNTRIES_CX = "CX";
export const COUNTRIES_CC = "CC";
export const COUNTRIES_CO = "CO";
export const COUNTRIES_KM = "KM";
export const COUNTRIES_CG = "CG";
export const COUNTRIES_CD = "CD";
export const COUNTRIES_CK = "CK";
export const COUNTRIES_CR = "CR";
export const COUNTRIES_CI = "CI";
export const COUNTRIES_HR = "HR";
export const COUNTRIES_CU = "CU";
export const COUNTRIES_CW = "CW";
export const COUNTRIES_CY = "CY";
export const COUNTRIES_CZ = "CZ";
export const COUNTRIES_DK = "DK";
export const COUNTRIES_DG = "DG";
export const COUNTRIES_DJ = "DJ";
export const COUNTRIES_DM = "DM";
export const COUNTRIES_DO = "DO";
export const COUNTRIES_EC = "EC";
export const COUNTRIES_EG = "EG";
export const COUNTRIES_SV = "SV";
export const COUNTRIES_GQ = "GQ";
export const COUNTRIES_ER = "ER";
export const COUNTRIES_EE = "EE";
export const COUNTRIES_ET = "ET";
export const COUNTRIES_FK = "FK";
export const COUNTRIES_FO = "FO";
export const COUNTRIES_FJ = "FJ";
export const COUNTRIES_FI = "FI";
export const COUNTRIES_FR = "FR";
export const COUNTRIES_GF = "GF";
export const COUNTRIES_PF = "PF";
export const COUNTRIES_TF = "TF";
export const COUNTRIES_GA = "GA";
export const COUNTRIES_GM = "GM";
export const COUNTRIES_GE = "GE";
export const COUNTRIES_DE = "DE";
export const COUNTRIES_GH = "GH";
export const COUNTRIES_GI = "GI";
export const COUNTRIES_GR = "GR";
export const COUNTRIES_GL = "GL";
export const COUNTRIES_GD = "GD";
export const COUNTRIES_GP = "GP";
export const COUNTRIES_GU = "GU";
export const COUNTRIES_GT = "GT";
export const COUNTRIES_GG = "GG";
export const COUNTRIES_GN = "GN";
export const COUNTRIES_GW = "GW";
export const COUNTRIES_GY = "GY";
export const COUNTRIES_HT = "HT";
export const COUNTRIES_HN = "HN";
export const COUNTRIES_HK = "HK";
export const COUNTRIES_HU = "HU";
export const COUNTRIES_IS = "IS";
export const COUNTRIES_IN = "IN";
export const COUNTRIES_ID = "ID";
export const COUNTRIES_IR = "IR";
export const COUNTRIES_IQ = "IQ";
export const COUNTRIES_IE = "IE";
export const COUNTRIES_IM = "IM";
export const COUNTRIES_IL = "IL";
export const COUNTRIES_IT = "IT";
export const COUNTRIES_JM = "JM";
export const COUNTRIES_JP = "JP";
export const COUNTRIES_JE = "JE";
export const COUNTRIES_JO = "JO";
export const COUNTRIES_KZ = "KZ";
export const COUNTRIES_KE = "KE";
export const COUNTRIES_KI = "KI";
export const COUNTRIES_XK = "XK";
export const COUNTRIES_KW = "KW";
export const COUNTRIES_KG = "KG";
export const COUNTRIES_LA = "LA";
export const COUNTRIES_LV = "LV";
export const COUNTRIES_LB = "LB";
export const COUNTRIES_LS = "LS";
export const COUNTRIES_LR = "LR";
export const COUNTRIES_LY = "LY";
export const COUNTRIES_LI = "LI";
export const COUNTRIES_LT = "LT";
export const COUNTRIES_LU = "LU";
export const COUNTRIES_MO = "MO";
export const COUNTRIES_MK = "MK";
export const COUNTRIES_MG = "MG";
export const COUNTRIES_MW = "MW";
export const COUNTRIES_MY = "MY";
export const COUNTRIES_MV = "MV";
export const COUNTRIES_ML = "ML";
export const COUNTRIES_MT = "MT";
export const COUNTRIES_MH = "MH";
export const COUNTRIES_MQ = "MQ";
export const COUNTRIES_MR = "MR";
export const COUNTRIES_MU = "MU";
export const COUNTRIES_YT = "YT";
export const COUNTRIES_MX = "MX";
export const COUNTRIES_FM = "FM";
export const COUNTRIES_MD = "MD";
export const COUNTRIES_MC = "MC";
export const COUNTRIES_MN = "MN";
export const COUNTRIES_ME = "ME";
export const COUNTRIES_MS = "MS";
export const COUNTRIES_MA = "MA";
export const COUNTRIES_MZ = "MZ";
export const COUNTRIES_MM = "MM";
export const COUNTRIES_NA = "NA";
export const COUNTRIES_NR = "NR";
export const COUNTRIES_NP = "NP";
export const COUNTRIES_NL = "NL";
export const COUNTRIES_NC = "NC";
export const COUNTRIES_NZ = "NZ";
export const COUNTRIES_NI = "NI";
export const COUNTRIES_NE = "NE";
export const COUNTRIES_NG = "NG";
export const COUNTRIES_NU = "NU";
export const COUNTRIES_NF = "NF";
export const COUNTRIES_KP = "KP";
export const COUNTRIES_MP = "MP";
export const COUNTRIES_NO = "NO";
export const COUNTRIES_OM = "OM";
export const COUNTRIES_PK = "PK";
export const COUNTRIES_PW = "PW";
export const COUNTRIES_PS = "PS";
export const COUNTRIES_PA = "PA";
export const COUNTRIES_PG = "PG";
export const COUNTRIES_PY = "PY";
export const COUNTRIES_PE = "PE";
export const COUNTRIES_PH = "PH";
export const COUNTRIES_PN = "PN";
export const COUNTRIES_PL = "PL";
export const COUNTRIES_PT = "PT";
export const COUNTRIES_PR = "PR";
export const COUNTRIES_QA = "QA";
export const COUNTRIES_RE = "RE";
export const COUNTRIES_RO = "RO";
export const COUNTRIES_RU = "RU";
export const COUNTRIES_RW = "RW";
export const COUNTRIES_WS = "WS";
export const COUNTRIES_SM = "SM";
export const COUNTRIES_ST = "ST";
export const COUNTRIES_SA = "SA";
export const COUNTRIES_SN = "SN";
export const COUNTRIES_RS = "RS";
export const COUNTRIES_SC = "SC";
export const COUNTRIES_SL = "SL";
export const COUNTRIES_SG = "SG";
export const COUNTRIES_SX = "SX";
export const COUNTRIES_SK = "SK";
export const COUNTRIES_SI = "SI";
export const COUNTRIES_SB = "SB";
export const COUNTRIES_SO = "SO";
export const COUNTRIES_ZA = "ZA";
export const COUNTRIES_GS = "GS";
export const COUNTRIES_KR = "KR";
export const COUNTRIES_SS = "SS";
export const COUNTRIES_ES = "ES";
export const COUNTRIES_LK = "LK";
export const COUNTRIES_BL = "BL";
export const COUNTRIES_SH = "SH";
export const COUNTRIES_KN = "KN";
export const COUNTRIES_LC = "LC";
export const COUNTRIES_MF = "MF";
export const COUNTRIES_PM = "PM";
export const COUNTRIES_VC = "VC";
export const COUNTRIES_SD = "SD";
export const COUNTRIES_SR = "SR";
export const COUNTRIES_SJ = "SJ";
export const COUNTRIES_SZ = "SZ";
export const COUNTRIES_SE = "SE";
export const COUNTRIES_CH = "CH";
export const COUNTRIES_SY = "SY";
export const COUNTRIES_TW = "TW";
export const COUNTRIES_TJ = "TJ";
export const COUNTRIES_TZ = "TZ";
export const COUNTRIES_TH = "TH";
export const COUNTRIES_TL = "TL";
export const COUNTRIES_TG = "TG";
export const COUNTRIES_TK = "TK";
export const COUNTRIES_TO = "TO";
export const COUNTRIES_TT = "TT";
export const COUNTRIES_TA = "TA";
export const COUNTRIES_TN = "TN";
export const COUNTRIES_TR = "TR";
export const COUNTRIES_TM = "TM";
export const COUNTRIES_TC = "TC";
export const COUNTRIES_TV = "TV";
export const COUNTRIES_UM = "UM";
export const COUNTRIES_VI = "VI";
export const COUNTRIES_UG = "UG";
export const COUNTRIES_UA = "UA";
export const COUNTRIES_AE = "AE";
export const COUNTRIES_GB = "GB";
export const COUNTRIES_US = "US";
export const COUNTRIES_UY = "UY";
export const COUNTRIES_UZ = "UZ";
export const COUNTRIES_VU = "VU";
export const COUNTRIES_VA = "VA";
export const COUNTRIES_VE = "VE";
export const COUNTRIES_VN = "VN";
export const COUNTRIES_WF = "WF";
export const COUNTRIES_EH = "EH";
export const COUNTRIES_YE = "YE";
export const COUNTRIES_ZM = "ZM";
export const COUNTRIES_ZW = "ZW";
