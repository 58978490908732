import * as COUNTRIES_LABELS from "../labels/countries";

export const LABELS = {
    [COUNTRIES_LABELS.COUNTRIES_IS]: "アイスランド",
    [COUNTRIES_LABELS.COUNTRIES_IE]: "アイルランド",
    [COUNTRIES_LABELS.COUNTRIES_AZ]: "アゼルバイジャン",
    [COUNTRIES_LABELS.COUNTRIES_AC]: "アセンション島",
    [COUNTRIES_LABELS.COUNTRIES_AF]: "アフガニスタン",
    [COUNTRIES_LABELS.COUNTRIES_US]: "アメリカ合衆国",
    [COUNTRIES_LABELS.COUNTRIES_AE]: "アラブ首長国連邦",
    [COUNTRIES_LABELS.COUNTRIES_DZ]: "アルジェリア",
    [COUNTRIES_LABELS.COUNTRIES_AR]: "アルゼンチン",
    [COUNTRIES_LABELS.COUNTRIES_AW]: "アルバ",
    [COUNTRIES_LABELS.COUNTRIES_AL]: "アルバニア",
    [COUNTRIES_LABELS.COUNTRIES_AM]: "アルメニア",
    [COUNTRIES_LABELS.COUNTRIES_AI]: "アンギラ",
    [COUNTRIES_LABELS.COUNTRIES_AO]: "アンゴラ",
    [COUNTRIES_LABELS.COUNTRIES_AG]: "アンティグア・バーブーダ",
    [COUNTRIES_LABELS.COUNTRIES_AD]: "アンドラ",
    [COUNTRIES_LABELS.COUNTRIES_YE]: "イエメン",
    [COUNTRIES_LABELS.COUNTRIES_GB]: "イギリス",
    [COUNTRIES_LABELS.COUNTRIES_IL]: "イスラエル",
    [COUNTRIES_LABELS.COUNTRIES_IT]: "イタリア",
    [COUNTRIES_LABELS.COUNTRIES_IQ]: "イラク",
    [COUNTRIES_LABELS.COUNTRIES_IR]: "イラン",
    [COUNTRIES_LABELS.COUNTRIES_IN]: "インド",
    [COUNTRIES_LABELS.COUNTRIES_ID]: "インドネシア",
    [COUNTRIES_LABELS.COUNTRIES_WF]: "ウォリス・フツナ",
    [COUNTRIES_LABELS.COUNTRIES_UG]: "ウガンダ",
    [COUNTRIES_LABELS.COUNTRIES_UA]: "ウクライナ",
    [COUNTRIES_LABELS.COUNTRIES_UZ]: "ウズベキスタン",
    [COUNTRIES_LABELS.COUNTRIES_UY]: "ウルグアイ",
    [COUNTRIES_LABELS.COUNTRIES_EC]: "エクアドル",
    [COUNTRIES_LABELS.COUNTRIES_EG]: "エジプト",
    [COUNTRIES_LABELS.COUNTRIES_EE]: "エストニア",
    [COUNTRIES_LABELS.COUNTRIES_ET]: "エチオピア",
    [COUNTRIES_LABELS.COUNTRIES_ER]: "エリトリア",
    [COUNTRIES_LABELS.COUNTRIES_SV]: "エルサルバドル",
    [COUNTRIES_LABELS.COUNTRIES_AU]: "オーストラリア",
    [COUNTRIES_LABELS.COUNTRIES_AT]: "オーストリア",
    [COUNTRIES_LABELS.COUNTRIES_AX]: "オーランド諸島",
    [COUNTRIES_LABELS.COUNTRIES_OM]: "オマーン",
    [COUNTRIES_LABELS.COUNTRIES_NL]: "オランダ",
    [COUNTRIES_LABELS.COUNTRIES_BQ]: "オランダ領カリブ",
    [COUNTRIES_LABELS.COUNTRIES_GH]: "ガーナ",
    [COUNTRIES_LABELS.COUNTRIES_CV]: "カーボベルデ",
    [COUNTRIES_LABELS.COUNTRIES_GG]: "ガーンジー",
    [COUNTRIES_LABELS.COUNTRIES_GY]: "ガイアナ",
    [COUNTRIES_LABELS.COUNTRIES_KZ]: "カザフスタン",
    [COUNTRIES_LABELS.COUNTRIES_QA]: "カタール",
    [COUNTRIES_LABELS.COUNTRIES_CA]: "カナダ",
    [COUNTRIES_LABELS.COUNTRIES_IC]: "カナリア諸島",
    [COUNTRIES_LABELS.COUNTRIES_GA]: "ガボン",
    [COUNTRIES_LABELS.COUNTRIES_CM]: "カメルーン",
    [COUNTRIES_LABELS.COUNTRIES_GM]: "ガンビア",
    [COUNTRIES_LABELS.COUNTRIES_KH]: "カンボジア",
    [COUNTRIES_LABELS.COUNTRIES_GN]: "ギニア",
    [COUNTRIES_LABELS.COUNTRIES_GW]: "ギニアビサウ",
    [COUNTRIES_LABELS.COUNTRIES_CY]: "キプロス",
    [COUNTRIES_LABELS.COUNTRIES_CU]: "キューバ",
    [COUNTRIES_LABELS.COUNTRIES_CW]: "キュラソー",
    [COUNTRIES_LABELS.COUNTRIES_GR]: "ギリシャ",
    [COUNTRIES_LABELS.COUNTRIES_KI]: "キリバス",
    [COUNTRIES_LABELS.COUNTRIES_KG]: "キルギス",
    [COUNTRIES_LABELS.COUNTRIES_GT]: "グアテマラ",
    [COUNTRIES_LABELS.COUNTRIES_GP]: "グアドループ",
    [COUNTRIES_LABELS.COUNTRIES_GU]: "グアム",
    [COUNTRIES_LABELS.COUNTRIES_KW]: "クウェート",
    [COUNTRIES_LABELS.COUNTRIES_CK]: "クック諸島",
    [COUNTRIES_LABELS.COUNTRIES_GL]: "グリーンランド",
    [COUNTRIES_LABELS.COUNTRIES_CX]: "クリスマス島",
    [COUNTRIES_LABELS.COUNTRIES_GE]: "グルジア",
    [COUNTRIES_LABELS.COUNTRIES_GD]: "グレナダ",
    [COUNTRIES_LABELS.COUNTRIES_HR]: "クロアチア",
    [COUNTRIES_LABELS.COUNTRIES_KY]: "ケイマン諸島",
    [COUNTRIES_LABELS.COUNTRIES_KE]: "ケニア",
    [COUNTRIES_LABELS.COUNTRIES_CI]: "コートジボワール",
    [COUNTRIES_LABELS.COUNTRIES_CC]: "ココス(キーリング)諸島",
    [COUNTRIES_LABELS.COUNTRIES_CR]: "コスタリカ",
    [COUNTRIES_LABELS.COUNTRIES_XK]: "コソボ",
    [COUNTRIES_LABELS.COUNTRIES_KM]: "コモロ",
    [COUNTRIES_LABELS.COUNTRIES_CO]: "コロンビア",
    [COUNTRIES_LABELS.COUNTRIES_CG]: "コンゴ共和国(ブラザビル)",
    [COUNTRIES_LABELS.COUNTRIES_CD]: "コンゴ民主共和国(キンシャサ)",
    [COUNTRIES_LABELS.COUNTRIES_SA]: "サウジアラビア",
    [COUNTRIES_LABELS.COUNTRIES_WS]: "サモア",
    [COUNTRIES_LABELS.COUNTRIES_BL]: "サン・バルテルミー島",
    [COUNTRIES_LABELS.COUNTRIES_MF]: "サン・マルタン",
    [COUNTRIES_LABELS.COUNTRIES_ST]: "サントメ・プリンシペ",
    [COUNTRIES_LABELS.COUNTRIES_ZM]: "ザンビア",
    [COUNTRIES_LABELS.COUNTRIES_PM]: "サンピエール島・ミクロン島",
    [COUNTRIES_LABELS.COUNTRIES_SM]: "サンマリノ",
    [COUNTRIES_LABELS.COUNTRIES_SL]: "シエラレオネ",
    [COUNTRIES_LABELS.COUNTRIES_DJ]: "ジブチ",
    [COUNTRIES_LABELS.COUNTRIES_GI]: "ジブラルタル",
    [COUNTRIES_LABELS.COUNTRIES_JE]: "ジャージー",
    [COUNTRIES_LABELS.COUNTRIES_JM]: "ジャマイカ",
    [COUNTRIES_LABELS.COUNTRIES_SY]: "シリア",
    [COUNTRIES_LABELS.COUNTRIES_SG]: "シンガポール",
    [COUNTRIES_LABELS.COUNTRIES_SX]: "シント・マールテン",
    [COUNTRIES_LABELS.COUNTRIES_ZW]: "ジンバブエ",
    [COUNTRIES_LABELS.COUNTRIES_CH]: "スイス",
    [COUNTRIES_LABELS.COUNTRIES_SE]: "スウェーデン",
    [COUNTRIES_LABELS.COUNTRIES_SD]: "スーダン",
    [COUNTRIES_LABELS.COUNTRIES_SJ]: "スバールバル諸島・ヤンマイエン島",
    [COUNTRIES_LABELS.COUNTRIES_ES]: "スペイン",
    [COUNTRIES_LABELS.COUNTRIES_SR]: "スリナム",
    [COUNTRIES_LABELS.COUNTRIES_LK]: "スリランカ",
    [COUNTRIES_LABELS.COUNTRIES_SK]: "スロバキア",
    [COUNTRIES_LABELS.COUNTRIES_SI]: "スロベニア",
    [COUNTRIES_LABELS.COUNTRIES_SZ]: "スワジランド",
    [COUNTRIES_LABELS.COUNTRIES_EA]: "セウタ・メリリャ",
    [COUNTRIES_LABELS.COUNTRIES_SC]: "セーシェル",
    [COUNTRIES_LABELS.COUNTRIES_SN]: "セネガル",
    [COUNTRIES_LABELS.COUNTRIES_RS]: "セルビア",
    [COUNTRIES_LABELS.COUNTRIES_KN]: "セントクリストファー・ネイビス",
    [COUNTRIES_LABELS.COUNTRIES_VC]: "セントビンセント・グレナディーン諸島",
    [COUNTRIES_LABELS.COUNTRIES_SH]: "セントヘレナ",
    [COUNTRIES_LABELS.COUNTRIES_LC]: "セントルシア",
    [COUNTRIES_LABELS.COUNTRIES_SO]: "ソマリア",
    [COUNTRIES_LABELS.COUNTRIES_SB]: "ソロモン諸島",
    [COUNTRIES_LABELS.COUNTRIES_TC]: "タークス・カイコス諸島",
    [COUNTRIES_LABELS.COUNTRIES_TH]: "タイ",
    [COUNTRIES_LABELS.COUNTRIES_TJ]: "タジキスタン",
    [COUNTRIES_LABELS.COUNTRIES_TZ]: "タンザニア",
    [COUNTRIES_LABELS.COUNTRIES_CZ]: "チェコ共和国",
    [COUNTRIES_LABELS.COUNTRIES_TD]: "チャド",
    [COUNTRIES_LABELS.COUNTRIES_TN]: "チュニジア",
    [COUNTRIES_LABELS.COUNTRIES_CL]: "チリ",
    [COUNTRIES_LABELS.COUNTRIES_TV]: "ツバル",
    [COUNTRIES_LABELS.COUNTRIES_DG]: "ディエゴガルシア島",
    [COUNTRIES_LABELS.COUNTRIES_DK]: "デンマーク",
    [COUNTRIES_LABELS.COUNTRIES_DE]: "ドイツ",
    [COUNTRIES_LABELS.COUNTRIES_TG]: "トーゴ",
    [COUNTRIES_LABELS.COUNTRIES_TK]: "トケラウ",
    [COUNTRIES_LABELS.COUNTRIES_DO]: "ドミニカ共和国",
    [COUNTRIES_LABELS.COUNTRIES_DM]: "ドミニカ国",
    [COUNTRIES_LABELS.COUNTRIES_TA]: "トリスタン・ダ・クーニャ",
    [COUNTRIES_LABELS.COUNTRIES_TT]: "トリニダード・トバゴ",
    [COUNTRIES_LABELS.COUNTRIES_TM]: "トルクメニスタン",
    [COUNTRIES_LABELS.COUNTRIES_TR]: "トルコ",
    [COUNTRIES_LABELS.COUNTRIES_TO]: "トンガ",
    [COUNTRIES_LABELS.COUNTRIES_NG]: "ナイジェリア",
    [COUNTRIES_LABELS.COUNTRIES_NR]: "ナウル",
    [COUNTRIES_LABELS.COUNTRIES_NA]: "ナミビア",
    [COUNTRIES_LABELS.COUNTRIES_NU]: "ニウエ島",
    [COUNTRIES_LABELS.COUNTRIES_NI]: "ニカラグア",
    [COUNTRIES_LABELS.COUNTRIES_NE]: "ニジェール",
    [COUNTRIES_LABELS.COUNTRIES_NC]: "ニューカレドニア",
    [COUNTRIES_LABELS.COUNTRIES_NZ]: "ニュージーランド",
    [COUNTRIES_LABELS.COUNTRIES_NP]: "ネパール",
    [COUNTRIES_LABELS.COUNTRIES_NF]: "ノーフォーク島",
    [COUNTRIES_LABELS.COUNTRIES_NO]: "ノルウェー",
    [COUNTRIES_LABELS.COUNTRIES_BH]: "バーレーン",
    [COUNTRIES_LABELS.COUNTRIES_HT]: "ハイチ",
    [COUNTRIES_LABELS.COUNTRIES_PK]: "パキスタン",
    [COUNTRIES_LABELS.COUNTRIES_VA]: "バチカン市国",
    [COUNTRIES_LABELS.COUNTRIES_PA]: "パナマ",
    [COUNTRIES_LABELS.COUNTRIES_VU]: "バヌアツ",
    [COUNTRIES_LABELS.COUNTRIES_BS]: "バハマ",
    [COUNTRIES_LABELS.COUNTRIES_PG]: "パプアニューギニア",
    [COUNTRIES_LABELS.COUNTRIES_BM]: "バミューダ",
    [COUNTRIES_LABELS.COUNTRIES_PW]: "パラオ",
    [COUNTRIES_LABELS.COUNTRIES_PY]: "パラグアイ",
    [COUNTRIES_LABELS.COUNTRIES_BB]: "バルバドス",
    [COUNTRIES_LABELS.COUNTRIES_PS]: "パレスチナ",
    [COUNTRIES_LABELS.COUNTRIES_HU]: "ハンガリー",
    [COUNTRIES_LABELS.COUNTRIES_BD]: "バングラデシュ",
    [COUNTRIES_LABELS.COUNTRIES_PN]: "ピトケアン諸島",
    [COUNTRIES_LABELS.COUNTRIES_FJ]: "フィジー",
    [COUNTRIES_LABELS.COUNTRIES_PH]: "フィリピン",
    [COUNTRIES_LABELS.COUNTRIES_FI]: "フィンランド",
    [COUNTRIES_LABELS.COUNTRIES_BT]: "ブータン",
    [COUNTRIES_LABELS.COUNTRIES_PR]: "プエルトリコ",
    [COUNTRIES_LABELS.COUNTRIES_FO]: "フェロー諸島",
    [COUNTRIES_LABELS.COUNTRIES_FK]: "フォークランド諸島",
    [COUNTRIES_LABELS.COUNTRIES_BR]: "ブラジル",
    [COUNTRIES_LABELS.COUNTRIES_FR]: "フランス",
    [COUNTRIES_LABELS.COUNTRIES_BG]: "ブルガリア",
    [COUNTRIES_LABELS.COUNTRIES_BF]: "ブルキナファソ",
    [COUNTRIES_LABELS.COUNTRIES_BN]: "ブルネイ",
    [COUNTRIES_LABELS.COUNTRIES_BI]: "ブルンジ",
    [COUNTRIES_LABELS.COUNTRIES_VN]: "ベトナム",
    [COUNTRIES_LABELS.COUNTRIES_BJ]: "ベナン",
    [COUNTRIES_LABELS.COUNTRIES_VE]: "ベネズエラ",
    [COUNTRIES_LABELS.COUNTRIES_BY]: "ベラルーシ",
    [COUNTRIES_LABELS.COUNTRIES_BZ]: "ベリーズ",
    [COUNTRIES_LABELS.COUNTRIES_PE]: "ペルー",
    [COUNTRIES_LABELS.COUNTRIES_BE]: "ベルギー",
    [COUNTRIES_LABELS.COUNTRIES_PL]: "ポーランド",
    [COUNTRIES_LABELS.COUNTRIES_BA]: "ボスニア・ヘルツェゴビナ",
    [COUNTRIES_LABELS.COUNTRIES_BW]: "ボツワナ",
    [COUNTRIES_LABELS.COUNTRIES_BO]: "ボリビア",
    [COUNTRIES_LABELS.COUNTRIES_PT]: "ポルトガル",
    [COUNTRIES_LABELS.COUNTRIES_HN]: "ホンジュラス",
    [COUNTRIES_LABELS.COUNTRIES_MH]: "マーシャル諸島",
    [COUNTRIES_LABELS.COUNTRIES_MK]: "マケドニア",
    [COUNTRIES_LABELS.COUNTRIES_MG]: "マダガスカル",
    [COUNTRIES_LABELS.COUNTRIES_YT]: "マヨット島",
    [COUNTRIES_LABELS.COUNTRIES_MW]: "マラウイ",
    [COUNTRIES_LABELS.COUNTRIES_ML]: "マリ",
    [COUNTRIES_LABELS.COUNTRIES_MT]: "マルタ",
    [COUNTRIES_LABELS.COUNTRIES_MQ]: "マルティニーク",
    [COUNTRIES_LABELS.COUNTRIES_MY]: "マレーシア",
    [COUNTRIES_LABELS.COUNTRIES_IM]: "マン島",
    [COUNTRIES_LABELS.COUNTRIES_FM]: "ミクロネシア連邦",
    [COUNTRIES_LABELS.COUNTRIES_MM]: "ミャンマー",
    [COUNTRIES_LABELS.COUNTRIES_MX]: "メキシコ",
    [COUNTRIES_LABELS.COUNTRIES_MU]: "モーリシャス",
    [COUNTRIES_LABELS.COUNTRIES_MR]: "モーリタニア",
    [COUNTRIES_LABELS.COUNTRIES_MZ]: "モザンビーク",
    [COUNTRIES_LABELS.COUNTRIES_MC]: "モナコ",
    [COUNTRIES_LABELS.COUNTRIES_MV]: "モルディブ",
    [COUNTRIES_LABELS.COUNTRIES_MD]: "モルドバ",
    [COUNTRIES_LABELS.COUNTRIES_MA]: "モロッコ",
    [COUNTRIES_LABELS.COUNTRIES_MN]: "モンゴル",
    [COUNTRIES_LABELS.COUNTRIES_ME]: "モンテネグロ",
    [COUNTRIES_LABELS.COUNTRIES_MS]: "モントセラト",
    [COUNTRIES_LABELS.COUNTRIES_JO]: "ヨルダン",
    [COUNTRIES_LABELS.COUNTRIES_LA]: "ラオス",
    [COUNTRIES_LABELS.COUNTRIES_LV]: "ラトビア",
    [COUNTRIES_LABELS.COUNTRIES_LT]: "リトアニア",
    [COUNTRIES_LABELS.COUNTRIES_LY]: "リビア",
    [COUNTRIES_LABELS.COUNTRIES_LI]: "リヒテンシュタイン",
    [COUNTRIES_LABELS.COUNTRIES_LR]: "リベリア",
    [COUNTRIES_LABELS.COUNTRIES_RO]: "ルーマニア",
    [COUNTRIES_LABELS.COUNTRIES_LU]: "ルクセンブルグ",
    [COUNTRIES_LABELS.COUNTRIES_RW]: "ルワンダ",
    [COUNTRIES_LABELS.COUNTRIES_LS]: "レソト",
    [COUNTRIES_LABELS.COUNTRIES_LB]: "レバノン",
    [COUNTRIES_LABELS.COUNTRIES_RE]: "レユニオン島",
    [COUNTRIES_LABELS.COUNTRIES_RU]: "ロシア",
    [COUNTRIES_LABELS.COUNTRIES_IO]: "英領インド洋地域",
    [COUNTRIES_LABELS.COUNTRIES_VG]: "英領ヴァージン諸島",
    [COUNTRIES_LABELS.COUNTRIES_EH]: "西サハラ",
    [COUNTRIES_LABELS.COUNTRIES_GQ]: "赤道ギニア",
    [COUNTRIES_LABELS.COUNTRIES_TW]: "台湾",
    [COUNTRIES_LABELS.COUNTRIES_KR]: "大韓民国",
    [COUNTRIES_LABELS.COUNTRIES_CF]: "中央アフリカ共和国",
    [COUNTRIES_LABELS.COUNTRIES_MO]: "中華人民共和国マカオ特別行政区",
    [COUNTRIES_LABELS.COUNTRIES_HK]: "中華人民共和国香港特別行政区",
    [COUNTRIES_LABELS.COUNTRIES_CN]: "中国",
    [COUNTRIES_LABELS.COUNTRIES_KP]: "朝鮮民主主義人民共和国",
    [COUNTRIES_LABELS.COUNTRIES_TL]: "東ティモール",
    [COUNTRIES_LABELS.COUNTRIES_ZA]: "南アフリカ",
    [COUNTRIES_LABELS.COUNTRIES_GS]: "南ジョージア島・南サンドイッチ諸島",
    [COUNTRIES_LABELS.COUNTRIES_SS]: "南スーダン",
    [COUNTRIES_LABELS.COUNTRIES_AQ]: "南極",
    [COUNTRIES_LABELS.COUNTRIES_JP]: "日本",
    [COUNTRIES_LABELS.COUNTRIES_GF]: "仏領ギアナ",
    [COUNTRIES_LABELS.COUNTRIES_PF]: "仏領ポリネシア",
    [COUNTRIES_LABELS.COUNTRIES_TF]: "仏領極南諸島",
    [COUNTRIES_LABELS.COUNTRIES_VI]: "米領ヴァージン諸島",
    [COUNTRIES_LABELS.COUNTRIES_AS]: "米領サモア",
    [COUNTRIES_LABELS.COUNTRIES_UM]: "米領太平洋諸島",
    [COUNTRIES_LABELS.COUNTRIES_MP]: "北マリアナ諸島",
};

export default LABELS;
