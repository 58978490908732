import COUNTRIES_LABELS from "./countries.locale";
import ERRORS_LABELS from "./errors.locale";
import GLOBAL_LABELS from "./global.locale";

export const LABELS = {
    ...COUNTRIES_LABELS,
    ...ERRORS_LABELS,
    ...GLOBAL_LABELS,
};

export default LABELS;
