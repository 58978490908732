import * as COUNTRIES_LABELS from "./countries";
import * as ERRORS_LABELS from "./errors";
import * as GLOBAL_LABELS from "./global";

export enum SupportedLocale {
    EN_US = "en-US",
    JA_JP = "ja-JP",
    ZH_CN = "zh-CN",
    ZH_TW = "zh-TW",
    RU_RU = "ru-RU",
    TH_TH = "th-TH",
    KO_KR = "ko-KR",
}

export const LOCALE_LABELS = {
    ...COUNTRIES_LABELS,
    ...ERRORS_LABELS,
    ...GLOBAL_LABELS,
};
