import { LOCALE_LABELS } from "common/locale/labels";
import { formatParameters, schema } from "common/validation/schema";
import { camelCase } from "lodash";
import { ECFormCustomFieldType, PaymentType, ResponseError, ResponseErrorCode, UsageLimit } from "univapay-node";

import { BUTTON_TEXT_DEFAULT } from "../../common/constants";
import { ClientError } from "../../common/errors/ClientError";
import { MessageError, MessageSuccess, ResourceType } from "../../common/Messages";
import {
    CheckoutParams,
    CheckoutStyles,
    CommonParams,
    CustomFieldParams,
    MetadataParams,
    Period,
} from "../../common/types";

import { ButtonSize, renderButton, renderError, renderInput, renderInputWithLegacy } from "./templates/ButtonTemplate";
import { Checkout } from "./Checkout";

type WindowElementParams = Partial<CommonParams> &
    MetadataParams & {
        name?: string;
        size?: string;
        hoverStyle?: string;
        text?: string;
        style?: string;
        univapayCustomerId?: string;
        metadata?: string;
    } & CheckoutStyles &
    CustomFieldParams;

export class WindowElement {
    static DOMObserver: MutationObserver;

    static instances: WindowElement[] = [];

    static createWindowElements(live: boolean): void {
        const elements: NodeListOf<Element> = document.querySelectorAll("[data-checkout]");

        for (let i = 0, l: number = elements.length; i < l; i++) {
            WindowElement.instances.push(new WindowElement(elements[i]));
        }

        if (live) {
            WindowElement.liveButtons(elements);
        }
    }

    static liveButtons(elements: NodeListOf<Element>): void {
        const mutationConf: MutationObserverInit = {
            attributes: true,
            childList: true,
            subtree: true,
        };

        const observer: MutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach(() => WindowElement.createWindowElements(false));
        });

        const observerTarget = (() => {
            if (window.document.body) {
                // When the body exists, assume it as a safe element for the observer
                return window.document.body;
            }

            // Use the element parent in case of a single element or the first document child
            const singleElementParent = elements.length === 1 ? elements[0].parentElement : null;
            const target = singleElementParent || window.document.firstElementChild || window.document.children[0];

            if (target.tagName?.toLowerCase() === "head") {
                console.error(
                    "No body found and the page. Please add `defer` to the univapay-checkout script or move it to the body."
                );
            }

            return target;
        })();

        observer.observe(observerTarget, mutationConf);
        WindowElement.DOMObserver = observer;
    }

    private element: Element;
    private text: string = BUTTON_TEXT_DEFAULT;
    private className: string;
    private style: string;
    private hoverStyle: string;
    private id: string;
    private size: ButtonSize;
    private inputName: string;

    constructor(element: Element) {
        this.element = element;
        const params = this.getCheckoutParameters();

        if (!element) {
            console.error("Checkout created without node");
        }

        try {
            schema.validateSync(params);
        } catch (errors) {
            this.renderError(errors);
            return;
        }

        if (params.inline) {
            this.renderForm(params);
        } else {
            this.renderButton(params);
        }
    }

    renderError(error: ClientError | ClientError[]) {
        const { locale } = this.getCheckoutParameters();
        const errors = Array.isArray(error) ? error : [error];

        const errorElement: Element = renderError(errors, locale);
        if (this.element?.parentNode) {
            this.element.parentNode.replaceChild(errorElement, this.element);
        } else {
            console.error("Could not add the errors to the page", this.element, errorElement);
        }
        this.element = errorElement;
    }

    async renderButton(params: CheckoutParams): Promise<void> {
        const button: Element = renderButton({
            className: this.className,
            style: this.style,
            hoverStyle: this.hoverStyle,
            id: this.id,
            size: this.size,
            text: this.text,
        });

        let transactionInput: HTMLInputElement;

        const setInputForm = (tokenId: string, chargeId: string, subscriptionId: string, error?: string) => {
            const { parentNode } = this.element;

            const tokenElements = renderInputWithLegacy(ResourceType.TRANSACTION_TOKEN, this.inputName, tokenId);

            const chargeElements = chargeId
                ? renderInputWithLegacy(ResourceType.CHARGE, this.inputName, chargeId)
                : undefined;

            const subscriptionElements = subscriptionId
                ? renderInputWithLegacy(ResourceType.SUBSCRIPTION, this.inputName, subscriptionId)
                : undefined;

            if (tokenElements) {
                parentNode.insertBefore(tokenElements.input, this.element);
                parentNode.insertBefore(tokenElements.legacyInput, tokenElements.input);
            }

            if (chargeElements) {
                parentNode.insertBefore(chargeElements.input, this.element);
                parentNode.insertBefore(chargeElements.legacyInput, chargeElements.input);
            }

            if (subscriptionElements) {
                parentNode.insertBefore(subscriptionElements.input, this.element);
                parentNode.insertBefore(subscriptionElements.legacyInput, subscriptionElements.input);
            }

            if (error) {
                parentNode.insertBefore(renderInput("error", error), tokenElements.legacyInput);
            }

            // Ensure we always have an input to replace based on the legacy handling
            const newElement = tokenElements?.input || chargeElements?.input || subscriptionElements?.input;
            if (newElement) {
                if (params.removeCheckoutButtonAfterCharge) {
                    parentNode.removeChild(this.element);
                }
                transactionInput = newElement;
                this.element = newElement;
            }
        };

        const extractErrorCode = (error?: unknown): string => {
            if (!error) {
                return ResponseErrorCode.UnknownError;
            }

            if (typeof error === "string") {
                return error;
            }

            if (error instanceof ResponseError) {
                return error.errorResponse.code;
            }

            if (error instanceof Error) {
                return error.message;
            }

            return ResponseErrorCode.UnknownError;
        };

        const checkout: Checkout = new Checkout({
            ...params,
            onSuccess: (message: MessageSuccess) => {
                const { tokenId, subscriptionId, chargeId } = message;

                setInputForm(tokenId, chargeId, subscriptionId);

                params?.onSuccess?.(message);
                params?.callback?.(message); // legacy support for the `callback` result callback
            },
            onError: (message: MessageError) => {
                if (params.autoSubmitOnError) {
                    const { tokenId, subscriptionId, chargeId, error } = message;
                    setInputForm(tokenId, chargeId, subscriptionId, extractErrorCode(error));
                }

                params?.onError?.(message);
            },
            closed: () => {
                if (transactionInput?.form && (params.autoSubmit || params.autoSubmitOnError)) {
                    transactionInput.form.submit();
                }
            },
        });

        button.addEventListener("click", () => checkout.open());

        if (this.element?.parentNode) {
            this.element.parentNode.replaceChild(button, this.element);
        } else {
            console.error("Could not add the widget to the page: ", this.element, button);
        }

        this.element = button;
    }

    async renderForm(params: CheckoutParams) {
        const container = this.element?.parentNode as HTMLElement;
        if (!container) {
            console.error("Could not add the inline form to the page: ", this.element);
            return;
        }

        container.removeChild(this.element); // remove element first to prevent live reload to quick in

        const checkout = new Checkout({ ...params, paymentType: PaymentType.CARD });
        const checkoutIFrame = await checkout.open(undefined, container);
        this.element = checkoutIFrame;

        if (!checkoutIFrame) {
            return this.renderError(new ClientError(LOCALE_LABELS.ERRORS_CHECKOUT));
        }
    }

    parseCustomFields(keyStr: string, labelStr: string, typeStr: string, requiredStr: string, optionStr: string) {
        const customFields = [];
        const keys = keyStr ? keyStr.split(",") : [];
        const labels = labelStr ? labelStr.split(",") : [];
        const types = typeStr ? typeStr.split(",") : [];
        const required = requiredStr ? requiredStr.split(",") : [];
        const options = optionStr ? optionStr.split(",") : [];
        let optionsIndex = 0;

        const maxLength = Math.max(keys.length, labels.length, types.length);

        for (let i = 0; i < maxLength; i++) {
            const customField = {
                key: keys[i] ?? "",
                label: labels[i] ?? "",
                type:
                    types[i] === "string"
                        ? ECFormCustomFieldType.STRING
                        : types[i] === "select"
                        ? ECFormCustomFieldType.SELECT
                        : "",
                required: required[i] === "true",
            };

            if (customField["type"] === ECFormCustomFieldType.SELECT) {
                customField["options"] = options[optionsIndex] ? options[optionsIndex].split(";") : [];
                optionsIndex++;
            } else {
                customField["options"] = null;
            }

            customFields.push(customField);
        }

        return customFields;
    }

    getCheckoutParameters() {
        const params: Partial<WindowElementParams> = {};

        if (this.element?.hasAttributes()) {
            for (let i = 0, l: number = this.element.attributes.length; i < l; i++) {
                if (this.element.attributes[i].name.indexOf("data-") === 0) {
                    const attribute = this.element.attributes[i];
                    const param = camelCase(attribute.name.replace("data-", ""));

                    switch (param) {
                        // Button parameters
                        case "text":
                            this.text = attribute.value;
                            break;

                        case "class":
                            this.className = attribute.value;
                            break;

                        case "id":
                            this.id = attribute.value;
                            break;

                        case "size":
                            this.size = attribute.value as ButtonSize;
                            break;

                        case "hoverStyle":
                            this.hoverStyle = attribute.value;
                            break;

                        case "name":
                            this.inputName = attribute.value;
                            break;

                        case "style":
                            this.style = attribute.value;
                            break;

                        // Incompatible parameters
                        case "captureIn":
                            if (!params.captureAt) {
                                params.captureIn = attribute.value as Period;
                            } else {
                                console.warn(
                                    "Warning: Both captureIn and captureAt have been provided. Ignoring captureIn parameter"
                                );
                            }
                            break;

                        case "subscriptionStartIn":
                            if (!params.subscriptionStart) {
                                params.subscriptionStartIn = attribute.value as Period;
                            } else {
                                console.warn(
                                    "Warning: Both subscriptionStartIn and subscriptionStart have been provided. Ignoring subscriptionStartIn parameter"
                                );
                            }
                            break;

                        // Legacy support
                        case "usageLimit": // support for legacy usage limit (the 'yearly' value has been deprecated)
                            if (attribute.value === "yearly") {
                                console.warn(
                                    `Warning: 'yearly' value for 'data-usage-limit' has been deprecated. Use '${UsageLimit.ANNUALLY}' instead.`
                                );
                                params.usageLimit = UsageLimit.ANNUALLY;
                            }
                            params.usageLimit = attribute.value as UsageLimit;
                            break;

                        case "gopayCustomerId": // support for legacy customer id (the parameter has been deprecated)
                            console.warn(
                                "Warning: 'data-gopay-customer-id' has been deprecated. Use 'data-univapay-customer-id' instead."
                            );
                            params.univapayCustomerId = attribute.value;
                            break;

                        case "callback": // support for legacy success callback (the parameter has been deprecated)
                            console.warn("Warning: 'callback' has been deprecated. Use 'onSuccess' instead.");
                            params.onSuccess = (attribute.value as unknown) as (message: MessageSuccess) => void;
                            break;

                        default:
                            params[param] = attribute.value;
                    }
                }
            }
        } else {
            console.error("No attributes found for node", this.element);
        }

        const fullParams = {
            ...(params as Record<string, string>),
            inlineBaseFontSize: params.inlineBaseFontSize || document.querySelector("html")?.style?.fontSize,
        };

        return formatParameters(fullParams, { formatMetadata: true });
    }
}
