import { Checkout } from "./Checkout";
import { WindowElement } from "./WindowElement";

const create: typeof Checkout.create = Checkout.create;
const submit: typeof Checkout.submit = Checkout.submit;

const storeInWindow = () => {
    const checkoutPublicParams = { create, loaded: true, submit };
    window.UnivapayCheckout = checkoutPublicParams;
    window.GopayCheckout = checkoutPublicParams;
};

if (!window.UnivapayCheckout?.loaded) {
    storeInWindow();
    WindowElement.createWindowElements(true);
}
