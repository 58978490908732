import * as COUNTRIES_LABELS from "../labels/countries";

export const LABELS = {
    [COUNTRIES_LABELS.COUNTRIES_IS]: "Исландия",
    [COUNTRIES_LABELS.COUNTRIES_IE]: "Ирландия",
    [COUNTRIES_LABELS.COUNTRIES_AZ]: "Азербайджан",
    [COUNTRIES_LABELS.COUNTRIES_AC]: "Остров Вознесения",
    [COUNTRIES_LABELS.COUNTRIES_AF]: "Афганистан",
    [COUNTRIES_LABELS.COUNTRIES_US]: "Соединенные Штаты Америки",
    [COUNTRIES_LABELS.COUNTRIES_AE]: "Объединенные Арабские Эмираты",
    [COUNTRIES_LABELS.COUNTRIES_DZ]: "Алжир",
    [COUNTRIES_LABELS.COUNTRIES_AR]: "Аргентина",
    [COUNTRIES_LABELS.COUNTRIES_AW]: "Альба",
    [COUNTRIES_LABELS.COUNTRIES_AL]: "Албания",
    [COUNTRIES_LABELS.COUNTRIES_AM]: "Армения",
    [COUNTRIES_LABELS.COUNTRIES_AI]: "Ангилла",
    [COUNTRIES_LABELS.COUNTRIES_AO]: "Ангола",
    [COUNTRIES_LABELS.COUNTRIES_AG]: "Антигуа Бербуда",
    [COUNTRIES_LABELS.COUNTRIES_AD]: "Андра",
    [COUNTRIES_LABELS.COUNTRIES_YE]: "Йемен",
    [COUNTRIES_LABELS.COUNTRIES_GB]: "Англия",
    [COUNTRIES_LABELS.COUNTRIES_IL]: "Израиль",
    [COUNTRIES_LABELS.COUNTRIES_IT]: "Италия",
    [COUNTRIES_LABELS.COUNTRIES_IQ]: "Ирак",
    [COUNTRIES_LABELS.COUNTRIES_IR]: "Иран",
    [COUNTRIES_LABELS.COUNTRIES_IN]: "Индия",
    [COUNTRIES_LABELS.COUNTRIES_ID]: "Индонезия",
    [COUNTRIES_LABELS.COUNTRIES_WF]: "Варис Футтна",
    [COUNTRIES_LABELS.COUNTRIES_UG]: "Уганда",
    [COUNTRIES_LABELS.COUNTRIES_UA]: "Украина",
    [COUNTRIES_LABELS.COUNTRIES_UZ]: "Узбекистан",
    [COUNTRIES_LABELS.COUNTRIES_UY]: "Уругвай",
    [COUNTRIES_LABELS.COUNTRIES_EC]: "Эквадор",
    [COUNTRIES_LABELS.COUNTRIES_EG]: "Египет",
    [COUNTRIES_LABELS.COUNTRIES_EE]: "Эстония",
    [COUNTRIES_LABELS.COUNTRIES_ET]: "Эфиопия",
    [COUNTRIES_LABELS.COUNTRIES_ER]: "Эритрия",
    [COUNTRIES_LABELS.COUNTRIES_SV]: "Сальвадор",
    [COUNTRIES_LABELS.COUNTRIES_AU]: "Австралия",
    [COUNTRIES_LABELS.COUNTRIES_AT]: "Австрия",
    [COUNTRIES_LABELS.COUNTRIES_AX]: "Орландо Острова",
    [COUNTRIES_LABELS.COUNTRIES_OM]: "Оман",
    [COUNTRIES_LABELS.COUNTRIES_NL]: "Нидерланды",
    [COUNTRIES_LABELS.COUNTRIES_BQ]: "Голландская территория Карибский бассейн",
    [COUNTRIES_LABELS.COUNTRIES_GH]: "Гана",
    [COUNTRIES_LABELS.COUNTRIES_CV]: "Carbo Verde",
    [COUNTRIES_LABELS.COUNTRIES_GG]: "Гангнзи",
    [COUNTRIES_LABELS.COUNTRIES_GY]: "Гайана",
    [COUNTRIES_LABELS.COUNTRIES_KZ]: "Казахстан",
    [COUNTRIES_LABELS.COUNTRIES_QA]: "Катар",
    [COUNTRIES_LABELS.COUNTRIES_CA]: "Канада",
    [COUNTRIES_LABELS.COUNTRIES_IC]: "Канарские острова",
    [COUNTRIES_LABELS.COUNTRIES_GA]: "Габон",
    [COUNTRIES_LABELS.COUNTRIES_CM]: "Камерун",
    [COUNTRIES_LABELS.COUNTRIES_GM]: "Гамбия",
    [COUNTRIES_LABELS.COUNTRIES_KH]: "Камбоджа",
    [COUNTRIES_LABELS.COUNTRIES_GN]: "Гвинея",
    [COUNTRIES_LABELS.COUNTRIES_GW]: "Гвинея Висау",
    [COUNTRIES_LABELS.COUNTRIES_CY]: "Кипр",
    [COUNTRIES_LABELS.COUNTRIES_CU]: "Куба",
    [COUNTRIES_LABELS.COUNTRIES_CW]: "Кюрасо",
    [COUNTRIES_LABELS.COUNTRIES_GR]: "Греция",
    [COUNTRIES_LABELS.COUNTRIES_KI]: "Кирибасс",
    [COUNTRIES_LABELS.COUNTRIES_KG]: "Кыргизстан",
    [COUNTRIES_LABELS.COUNTRIES_GT]: "Гватемала",
    [COUNTRIES_LABELS.COUNTRIES_GP]: "Гуадруп",
    [COUNTRIES_LABELS.COUNTRIES_GU]: "Гуам",
    [COUNTRIES_LABELS.COUNTRIES_KW]: "Кувейт",
    [COUNTRIES_LABELS.COUNTRIES_CK]: "Острова Кука",
    [COUNTRIES_LABELS.COUNTRIES_GL]: "Гренландия",
    [COUNTRIES_LABELS.COUNTRIES_CX]: "Остров Рождества",
    [COUNTRIES_LABELS.COUNTRIES_GE]: "Грузия",
    [COUNTRIES_LABELS.COUNTRIES_GD]: "Гренада",
    [COUNTRIES_LABELS.COUNTRIES_HR]: "Хорватия",
    [COUNTRIES_LABELS.COUNTRIES_KY]: "Каймановы острова",
    [COUNTRIES_LABELS.COUNTRIES_KE]: "Кения",
    [COUNTRIES_LABELS.COUNTRIES_CI]: "Суд Джибоайр",
    [COUNTRIES_LABELS.COUNTRIES_CC]: "Кокос (Кейлинг) Острова",
    [COUNTRIES_LABELS.COUNTRIES_CR]: "Коста -Рика",
    [COUNTRIES_LABELS.COUNTRIES_XK]: "Косово",
    [COUNTRIES_LABELS.COUNTRIES_KM]: "Коморо",
    [COUNTRIES_LABELS.COUNTRIES_CO]: "Колумбия",
    [COUNTRIES_LABELS.COUNTRIES_CG]: "Конго Республика (строительство брата)",
    [COUNTRIES_LABELS.COUNTRIES_CD]: "Конго Демократическая Республика (Киншаса)",
    [COUNTRIES_LABELS.COUNTRIES_SA]: "Саудовская Аравия",
    [COUNTRIES_LABELS.COUNTRIES_WS]: "Самоа",
    [COUNTRIES_LABELS.COUNTRIES_BL]: "Остров Святой Балтерми",
    [COUNTRIES_LABELS.COUNTRIES_MF]: "Сун Мартин",
    [COUNTRIES_LABELS.COUNTRIES_ST]: "Санта -принципа",
    [COUNTRIES_LABELS.COUNTRIES_ZM]: "Замбия",
    [COUNTRIES_LABELS.COUNTRIES_PM]: "МИКРОН МИКРОНСКИЙ остров",
    [COUNTRIES_LABELS.COUNTRIES_SM]: "Sun Marino",
    [COUNTRIES_LABELS.COUNTRIES_SL]: "Сьерра-Леоне",
    [COUNTRIES_LABELS.COUNTRIES_DJ]: "Джибучи",
    [COUNTRIES_LABELS.COUNTRIES_GI]: "Гибралтар",
    [COUNTRIES_LABELS.COUNTRIES_JE]: "Джерси",
    [COUNTRIES_LABELS.COUNTRIES_JM]: "Ямайка",
    [COUNTRIES_LABELS.COUNTRIES_SY]: "Сирия",
    [COUNTRIES_LABELS.COUNTRIES_SG]: "Сингапур",
    [COUNTRIES_LABELS.COUNTRIES_SX]: "Синт Марутен",
    [COUNTRIES_LABELS.COUNTRIES_ZW]: "Зимбабве",
    [COUNTRIES_LABELS.COUNTRIES_CH]: "Швейцария",
    [COUNTRIES_LABELS.COUNTRIES_SE]: "Швеция",
    [COUNTRIES_LABELS.COUNTRIES_SD]: "Судан",
    [COUNTRIES_LABELS.COUNTRIES_SJ]: "Субарбарные острова / Остров Янмайна",
    [COUNTRIES_LABELS.COUNTRIES_ES]: "Испания",
    [COUNTRIES_LABELS.COUNTRIES_SR]: "Слинам",
    [COUNTRIES_LABELS.COUNTRIES_LK]: "Шри -Ланка",
    [COUNTRIES_LABELS.COUNTRIES_SK]: "Словакия",
    [COUNTRIES_LABELS.COUNTRIES_SI]: "Словения",
    [COUNTRIES_LABELS.COUNTRIES_SZ]: "Сваджи земля",
    [COUNTRIES_LABELS.COUNTRIES_EA]: "Суута Мелилла",
    [COUNTRIES_LABELS.COUNTRIES_SC]: "Saechel",
    [COUNTRIES_LABELS.COUNTRIES_SN]: "Сенегал",
    [COUNTRIES_LABELS.COUNTRIES_RS]: "Сербия",
    [COUNTRIES_LABELS.COUNTRIES_KN]: "Святой Кристофер Навс",
    [COUNTRIES_LABELS.COUNTRIES_VC]: "Острова Св. Винсента Гренадин",
    [COUNTRIES_LABELS.COUNTRIES_SH]: "Святой Елены",
    [COUNTRIES_LABELS.COUNTRIES_LC]: "Сент -Люсия",
    [COUNTRIES_LABELS.COUNTRIES_SO]: "Сомали",
    [COUNTRIES_LABELS.COUNTRIES_SB]: "Соломоновы острова",
    [COUNTRIES_LABELS.COUNTRIES_TC]: "Острова Такус Кайкос",
    [COUNTRIES_LABELS.COUNTRIES_TH]: "Таиланд",
    [COUNTRIES_LABELS.COUNTRIES_TJ]: "Таджикистан",
    [COUNTRIES_LABELS.COUNTRIES_TZ]: "Танзания",
    [COUNTRIES_LABELS.COUNTRIES_CZ]: "Чешская Республика",
    [COUNTRIES_LABELS.COUNTRIES_TD]: "Чад",
    [COUNTRIES_LABELS.COUNTRIES_TN]: "Тунис",
    [COUNTRIES_LABELS.COUNTRIES_CL]: "Чили",
    [COUNTRIES_LABELS.COUNTRIES_TV]: "Цубару",
    [COUNTRIES_LABELS.COUNTRIES_DG]: "Остров Дигогарсия",
    [COUNTRIES_LABELS.COUNTRIES_DK]: "Дания",
    [COUNTRIES_LABELS.COUNTRIES_DE]: "Германия",
    [COUNTRIES_LABELS.COUNTRIES_TG]: "Идти",
    [COUNTRIES_LABELS.COUNTRIES_TK]: "Токерау",
    [COUNTRIES_LABELS.COUNTRIES_DO]: "Доминиканская Республика",
    [COUNTRIES_LABELS.COUNTRIES_DM]: "Доминика Страна",
    [COUNTRIES_LABELS.COUNTRIES_TA]: "Тристан да Куния",
    [COUNTRIES_LABELS.COUNTRIES_TT]: "Республика Тринидад и Тобаго",
    [COUNTRIES_LABELS.COUNTRIES_TM]: "Крутящий момент Менистан",
    [COUNTRIES_LABELS.COUNTRIES_TR]: "Тюркие",
    [COUNTRIES_LABELS.COUNTRIES_TO]: "Тонга",
    [COUNTRIES_LABELS.COUNTRIES_NG]: "Нигерия",
    [COUNTRIES_LABELS.COUNTRIES_NR]: "Наурел",
    [COUNTRIES_LABELS.COUNTRIES_NA]: "Намибия",
    [COUNTRIES_LABELS.COUNTRIES_NU]: "Остров Нийо",
    [COUNTRIES_LABELS.COUNTRIES_NI]: "Никарагуа",
    [COUNTRIES_LABELS.COUNTRIES_NE]: "Нигейл",
    [COUNTRIES_LABELS.COUNTRIES_NC]: "Новая Каледония",
    [COUNTRIES_LABELS.COUNTRIES_NZ]: "Новая Зеландия",
    [COUNTRIES_LABELS.COUNTRIES_NP]: "Непал",
    [COUNTRIES_LABELS.COUNTRIES_NF]: "Остров Норфолк",
    [COUNTRIES_LABELS.COUNTRIES_NO]: "Норвегия",
    [COUNTRIES_LABELS.COUNTRIES_BH]: "Баранов",
    [COUNTRIES_LABELS.COUNTRIES_HT]: "Гаити",
    [COUNTRIES_LABELS.COUNTRIES_PK]: "Пакистан",
    [COUNTRIES_LABELS.COUNTRIES_VA]: "Ватикан",
    [COUNTRIES_LABELS.COUNTRIES_PA]: "Панама",
    [COUNTRIES_LABELS.COUNTRIES_VU]: "Вануацу",
    [COUNTRIES_LABELS.COUNTRIES_BS]: "Багамские острова",
    [COUNTRIES_LABELS.COUNTRIES_PG]: "Папуа - Новая Гвинея",
    [COUNTRIES_LABELS.COUNTRIES_BM]: "Бермудские острова",
    [COUNTRIES_LABELS.COUNTRIES_PW]: "Палао",
    [COUNTRIES_LABELS.COUNTRIES_PY]: "Парагвай",
    [COUNTRIES_LABELS.COUNTRIES_BB]: "Барбадос",
    [COUNTRIES_LABELS.COUNTRIES_PS]: "Палестина",
    [COUNTRIES_LABELS.COUNTRIES_HU]: "Венгрия",
    [COUNTRIES_LABELS.COUNTRIES_BD]: "Бангладеш",
    [COUNTRIES_LABELS.COUNTRIES_PN]: "Питчианские острова",
    [COUNTRIES_LABELS.COUNTRIES_FJ]: "Фиджи",
    [COUNTRIES_LABELS.COUNTRIES_PH]: "Филиппины",
    [COUNTRIES_LABELS.COUNTRIES_FI]: "Финляндия",
    [COUNTRIES_LABELS.COUNTRIES_BT]: "Бутан",
    [COUNTRIES_LABELS.COUNTRIES_PR]: "Пуэрто-Рико",
    [COUNTRIES_LABELS.COUNTRIES_FO]: "Товарищи по островам",
    [COUNTRIES_LABELS.COUNTRIES_FK]: "Фолклендские острова",
    [COUNTRIES_LABELS.COUNTRIES_BR]: "Бразилия",
    [COUNTRIES_LABELS.COUNTRIES_FR]: "Франция",
    [COUNTRIES_LABELS.COUNTRIES_BG]: "Болгария",
    [COUNTRIES_LABELS.COUNTRIES_BF]: "Буркина-Фасо",
    [COUNTRIES_LABELS.COUNTRIES_BN]: "Бруней",
    [COUNTRIES_LABELS.COUNTRIES_BI]: "Щетка",
    [COUNTRIES_LABELS.COUNTRIES_VN]: "Вьетнам",
    [COUNTRIES_LABELS.COUNTRIES_BJ]: "Бенан",
    [COUNTRIES_LABELS.COUNTRIES_VE]: "Венесуэла",
    [COUNTRIES_LABELS.COUNTRIES_BY]: "Беларусь",
    [COUNTRIES_LABELS.COUNTRIES_BZ]: "Белиз",
    [COUNTRIES_LABELS.COUNTRIES_PE]: "Перу",
    [COUNTRIES_LABELS.COUNTRIES_BE]: "Бельгия",
    [COUNTRIES_LABELS.COUNTRIES_PL]: "Польша",
    [COUNTRIES_LABELS.COUNTRIES_BA]: "Босния-Герцеговина",
    [COUNTRIES_LABELS.COUNTRIES_BW]: "Ботвана",
    [COUNTRIES_LABELS.COUNTRIES_BO]: "Боливия",
    [COUNTRIES_LABELS.COUNTRIES_PT]: "Португалия",
    [COUNTRIES_LABELS.COUNTRIES_HN]: "Гондурас",
    [COUNTRIES_LABELS.COUNTRIES_MH]: "Маршалловы острова",
    [COUNTRIES_LABELS.COUNTRIES_MK]: "Македония",
    [COUNTRIES_LABELS.COUNTRIES_MG]: "Мадагаскар",
    [COUNTRIES_LABELS.COUNTRIES_YT]: "Остров Мэйот",
    [COUNTRIES_LABELS.COUNTRIES_MW]: "Малави",
    [COUNTRIES_LABELS.COUNTRIES_ML]: "Мари",
    [COUNTRIES_LABELS.COUNTRIES_MT]: "Мальта",
    [COUNTRIES_LABELS.COUNTRIES_MQ]: "Мальтиник",
    [COUNTRIES_LABELS.COUNTRIES_MY]: "Малайзия",
    [COUNTRIES_LABELS.COUNTRIES_IM]: "Остров",
    [COUNTRIES_LABELS.COUNTRIES_FM]: "Микронезия Федерация",
    [COUNTRIES_LABELS.COUNTRIES_MM]: "Мьянма",
    [COUNTRIES_LABELS.COUNTRIES_MX]: "Мексика",
    [COUNTRIES_LABELS.COUNTRIES_MU]: "Маврикий",
    [COUNTRIES_LABELS.COUNTRIES_MR]: "Мавритания",
    [COUNTRIES_LABELS.COUNTRIES_MZ]: "Мозамбик",
    [COUNTRIES_LABELS.COUNTRIES_MC]: "Монако",
    [COUNTRIES_LABELS.COUNTRIES_MV]: "Мальдивы",
    [COUNTRIES_LABELS.COUNTRIES_MD]: "Молдоба",
    [COUNTRIES_LABELS.COUNTRIES_MA]: "Марокко",
    [COUNTRIES_LABELS.COUNTRIES_MN]: "Монголия",
    [COUNTRIES_LABELS.COUNTRIES_ME]: "Черногория",
    [COUNTRIES_LABELS.COUNTRIES_MS]: "Монтрат",
    [COUNTRIES_LABELS.COUNTRIES_JO]: "Иордания",
    [COUNTRIES_LABELS.COUNTRIES_LA]: "Лаос",
    [COUNTRIES_LABELS.COUNTRIES_LV]: "Латвия",
    [COUNTRIES_LABELS.COUNTRIES_LT]: "Литва",
    [COUNTRIES_LABELS.COUNTRIES_LY]: "Ливия",
    [COUNTRIES_LABELS.COUNTRIES_LI]: "Лихтенштейн",
    [COUNTRIES_LABELS.COUNTRIES_LR]: "Либерия",
    [COUNTRIES_LABELS.COUNTRIES_RO]: "Румыния",
    [COUNTRIES_LABELS.COUNTRIES_LU]: "Люксембург",
    [COUNTRIES_LABELS.COUNTRIES_RW]: "Руанда",
    [COUNTRIES_LABELS.COUNTRIES_LS]: "Лесо",
    [COUNTRIES_LABELS.COUNTRIES_LB]: "Ливан",
    [COUNTRIES_LABELS.COUNTRIES_RE]: "Остров воссоединения",
    [COUNTRIES_LABELS.COUNTRIES_RU]: "Россия",
    [COUNTRIES_LABELS.COUNTRIES_IO]: "Британский регион Индийского океана",
    [COUNTRIES_LABELS.COUNTRIES_VG]: "Британские Виргинские острова",
    [COUNTRIES_LABELS.COUNTRIES_EH]: "Западный Сахара",
    [COUNTRIES_LABELS.COUNTRIES_GQ]: "Экваториальная Гвинея",
    [COUNTRIES_LABELS.COUNTRIES_TW]: "Тайвань",
    [COUNTRIES_LABELS.COUNTRIES_KR]: "Южная Корея",
    [COUNTRIES_LABELS.COUNTRIES_CF]: "Центральная Африка",
    [COUNTRIES_LABELS.COUNTRIES_MO]: "Народная Республика Китая Специальный административный округ Макао",
    [COUNTRIES_LABELS.COUNTRIES_HK]: "Китайская Народная Республика Специальный административный округ Гонконг",
    [COUNTRIES_LABELS.COUNTRIES_CN]: "Китай",
    [COUNTRIES_LABELS.COUNTRIES_KP]: "Корейская демократия Народная Республика",
    [COUNTRIES_LABELS.COUNTRIES_TL]: "Восточный Тимор",
    [COUNTRIES_LABELS.COUNTRIES_ZA]: "Южная Африка",
    [COUNTRIES_LABELS.COUNTRIES_GS]: "Южная Грузия, Южные Сэндвич Острова",
    [COUNTRIES_LABELS.COUNTRIES_SS]: "южный Судан",
    [COUNTRIES_LABELS.COUNTRIES_AQ]: "Антарктика",
    [COUNTRIES_LABELS.COUNTRIES_JP]: "Япония",
    [COUNTRIES_LABELS.COUNTRIES_GF]: "Французская Джана",
    [COUNTRIES_LABELS.COUNTRIES_PF]: "Французская Полинезия",
    [COUNTRIES_LABELS.COUNTRIES_TF]: "Французские южные острова",
    [COUNTRIES_LABELS.COUNTRIES_VI]: "Территория США, Виргинские острова",
    [COUNTRIES_LABELS.COUNTRIES_AS]: "Территория США Самоа",
    [COUNTRIES_LABELS.COUNTRIES_UM]: "Территория США Тихоокеанские острова",
    [COUNTRIES_LABELS.COUNTRIES_MP]: "Северные Марианы Острова",
};

export default LABELS;
