const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export function generate(length = 8): string {
    let random = "";

    for (let i = 0; i < length; i++) {
        random += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return random;
}
