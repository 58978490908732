import * as COUNTRIES_LABELS from "../labels/countries";

export const LABELS = {
    [COUNTRIES_LABELS.COUNTRIES_IS]: "아이슬란드",
    [COUNTRIES_LABELS.COUNTRIES_IE]: "아일랜드",
    [COUNTRIES_LABELS.COUNTRIES_AZ]: "아제르바이잔",
    [COUNTRIES_LABELS.COUNTRIES_AC]: "승천 섬",
    [COUNTRIES_LABELS.COUNTRIES_AF]: "아프가니스탄",
    [COUNTRIES_LABELS.COUNTRIES_US]: "미국",
    [COUNTRIES_LABELS.COUNTRIES_AE]: "아랍 에미리트",
    [COUNTRIES_LABELS.COUNTRIES_DZ]: "알제리",
    [COUNTRIES_LABELS.COUNTRIES_AR]: "아르헨티나",
    [COUNTRIES_LABELS.COUNTRIES_AW]: "알바",
    [COUNTRIES_LABELS.COUNTRIES_AL]: "알바니아",
    [COUNTRIES_LABELS.COUNTRIES_AM]: "아르메니아",
    [COUNTRIES_LABELS.COUNTRIES_AI]: "앵글라",
    [COUNTRIES_LABELS.COUNTRIES_AO]: "앙골라",
    [COUNTRIES_LABELS.COUNTRIES_AG]: "안티구아 베르 부다",
    [COUNTRIES_LABELS.COUNTRIES_AD]: "안드라",
    [COUNTRIES_LABELS.COUNTRIES_YE]: "예멘",
    [COUNTRIES_LABELS.COUNTRIES_GB]: "영국",
    [COUNTRIES_LABELS.COUNTRIES_IL]: "이스라엘",
    [COUNTRIES_LABELS.COUNTRIES_IT]: "이탈리아",
    [COUNTRIES_LABELS.COUNTRIES_IQ]: "이라크",
    [COUNTRIES_LABELS.COUNTRIES_IR]: "이란",
    [COUNTRIES_LABELS.COUNTRIES_IN]: "인도",
    [COUNTRIES_LABELS.COUNTRIES_ID]: "인도네시아",
    [COUNTRIES_LABELS.COUNTRIES_WF]: "Waris Futna",
    [COUNTRIES_LABELS.COUNTRIES_UG]: "우간다",
    [COUNTRIES_LABELS.COUNTRIES_UA]: "우크라이나",
    [COUNTRIES_LABELS.COUNTRIES_UZ]: "우즈베키스탄",
    [COUNTRIES_LABELS.COUNTRIES_UY]: "우루과이",
    [COUNTRIES_LABELS.COUNTRIES_EC]: "에콰도르",
    [COUNTRIES_LABELS.COUNTRIES_EG]: "이집트",
    [COUNTRIES_LABELS.COUNTRIES_EE]: "에스토니아",
    [COUNTRIES_LABELS.COUNTRIES_ET]: "에티오피아",
    [COUNTRIES_LABELS.COUNTRIES_ER]: "에리트리 아",
    [COUNTRIES_LABELS.COUNTRIES_SV]: "엘살바도르",
    [COUNTRIES_LABELS.COUNTRIES_AU]: "호주",
    [COUNTRIES_LABELS.COUNTRIES_AT]: "오스트리아",
    [COUNTRIES_LABELS.COUNTRIES_AX]: "올랜도 제도",
    [COUNTRIES_LABELS.COUNTRIES_OM]: "오만",
    [COUNTRIES_LABELS.COUNTRIES_NL]: "네덜란드",
    [COUNTRIES_LABELS.COUNTRIES_BQ]: "네덜란드 영토 카리브해",
    [COUNTRIES_LABELS.COUNTRIES_GH]: "가나",
    [COUNTRIES_LABELS.COUNTRIES_CV]: "카보 베르데",
    [COUNTRIES_LABELS.COUNTRIES_GG]: "Gangnzie",
    [COUNTRIES_LABELS.COUNTRIES_GY]: "가이아나",
    [COUNTRIES_LABELS.COUNTRIES_KZ]: "카자흐스탄",
    [COUNTRIES_LABELS.COUNTRIES_QA]: "카타르",
    [COUNTRIES_LABELS.COUNTRIES_CA]: "캐나다",
    [COUNTRIES_LABELS.COUNTRIES_IC]: "카나리아 제도",
    [COUNTRIES_LABELS.COUNTRIES_GA]: "가봉",
    [COUNTRIES_LABELS.COUNTRIES_CM]: "카메룬",
    [COUNTRIES_LABELS.COUNTRIES_GM]: "감비아",
    [COUNTRIES_LABELS.COUNTRIES_KH]: "캄보디아",
    [COUNTRIES_LABELS.COUNTRIES_GN]: "기니",
    [COUNTRIES_LABELS.COUNTRIES_GW]: "기니 비소",
    [COUNTRIES_LABELS.COUNTRIES_CY]: "키프로스",
    [COUNTRIES_LABELS.COUNTRIES_CU]: "쿠바",
    [COUNTRIES_LABELS.COUNTRIES_CW]: "쿠라 사",
    [COUNTRIES_LABELS.COUNTRIES_GR]: "그리스",
    [COUNTRIES_LABELS.COUNTRIES_KI]: "Kiribass",
    [COUNTRIES_LABELS.COUNTRIES_KG]: "키르기스스탄",
    [COUNTRIES_LABELS.COUNTRIES_GT]: "과테말라",
    [COUNTRIES_LABELS.COUNTRIES_GP]: "과드로프",
    [COUNTRIES_LABELS.COUNTRIES_GU]: "괌",
    [COUNTRIES_LABELS.COUNTRIES_KW]: "쿠웨이트",
    [COUNTRIES_LABELS.COUNTRIES_CK]: "쿡 제도",
    [COUNTRIES_LABELS.COUNTRIES_GL]: "그린란드",
    [COUNTRIES_LABELS.COUNTRIES_CX]: "크리스마스 섬",
    [COUNTRIES_LABELS.COUNTRIES_GE]: "그루지야",
    [COUNTRIES_LABELS.COUNTRIES_GD]: "그레나다",
    [COUNTRIES_LABELS.COUNTRIES_HR]: "크로아티아",
    [COUNTRIES_LABELS.COUNTRIES_KY]: "케이맨 제도",
    [COUNTRIES_LABELS.COUNTRIES_KE]: "케냐",
    [COUNTRIES_LABELS.COUNTRIES_CI]: "법원 지바이어",
    [COUNTRIES_LABELS.COUNTRIES_CC]: "코코 (키어링) 섬",
    [COUNTRIES_LABELS.COUNTRIES_CR]: "코스타리카",
    [COUNTRIES_LABELS.COUNTRIES_XK]: "코소보",
    [COUNTRIES_LABELS.COUNTRIES_KM]: "코 모로",
    [COUNTRIES_LABELS.COUNTRIES_CO]: "콜롬비아",
    [COUNTRIES_LABELS.COUNTRIES_CG]: "콩고 공화국 (Brother Building)",
    [COUNTRIES_LABELS.COUNTRIES_CD]: "콩고 민주 공화국 (Kinshasa)",
    [COUNTRIES_LABELS.COUNTRIES_SA]: "사우디 아라비아",
    [COUNTRIES_LABELS.COUNTRIES_WS]: "사모아",
    [COUNTRIES_LABELS.COUNTRIES_BL]: "세인트 볼터 미 섬",
    [COUNTRIES_LABELS.COUNTRIES_MF]: "선 마틴",
    [COUNTRIES_LABELS.COUNTRIES_ST]: "산타 원리",
    [COUNTRIES_LABELS.COUNTRIES_ZM]: "잠비아",
    [COUNTRIES_LABELS.COUNTRIES_PM]: "산 피에르 섬 미크론 섬",
    [COUNTRIES_LABELS.COUNTRIES_SM]: "선 마리노",
    [COUNTRIES_LABELS.COUNTRIES_SL]: "시에라 리온",
    [COUNTRIES_LABELS.COUNTRIES_DJ]: "지부치",
    [COUNTRIES_LABELS.COUNTRIES_GI]: "지브롤터",
    [COUNTRIES_LABELS.COUNTRIES_JE]: "저지",
    [COUNTRIES_LABELS.COUNTRIES_JM]: "자메이카",
    [COUNTRIES_LABELS.COUNTRIES_SY]: "시리아",
    [COUNTRIES_LABELS.COUNTRIES_SG]: "싱가포르",
    [COUNTRIES_LABELS.COUNTRIES_SX]: "신트 마루 텐",
    [COUNTRIES_LABELS.COUNTRIES_ZW]: "짐바브웨",
    [COUNTRIES_LABELS.COUNTRIES_CH]: "스위스",
    [COUNTRIES_LABELS.COUNTRIES_SE]: "스웨덴",
    [COUNTRIES_LABELS.COUNTRIES_SD]: "수단",
    [COUNTRIES_LABELS.COUNTRIES_SJ]: "수바리 제도 / Yanmaien Island",
    [COUNTRIES_LABELS.COUNTRIES_ES]: "스페인",
    [COUNTRIES_LABELS.COUNTRIES_SR]: "슬리움",
    [COUNTRIES_LABELS.COUNTRIES_LK]: "스리랑카",
    [COUNTRIES_LABELS.COUNTRIES_SK]: "슬로바키아",
    [COUNTRIES_LABELS.COUNTRIES_SI]: "슬로베니아",
    [COUNTRIES_LABELS.COUNTRIES_SZ]: "스와 지 땅",
    [COUNTRIES_LABELS.COUNTRIES_EA]: "Suuta Melilla",
    [COUNTRIES_LABELS.COUNTRIES_SC]: "Saechel",
    [COUNTRIES_LABELS.COUNTRIES_SN]: "세네갈",
    [COUNTRIES_LABELS.COUNTRIES_RS]: "세르비아",
    [COUNTRIES_LABELS.COUNTRIES_KN]: "세인트 크리스토퍼 나비스",
    [COUNTRIES_LABELS.COUNTRIES_VC]: "세인트 빈센트 그레나딘 제도",
    [COUNTRIES_LABELS.COUNTRIES_SH]: "세인트 헬레나",
    [COUNTRIES_LABELS.COUNTRIES_LC]: "세인트 루시아",
    [COUNTRIES_LABELS.COUNTRIES_SO]: "소말리아",
    [COUNTRIES_LABELS.COUNTRIES_SB]: "솔로몬 제도",
    [COUNTRIES_LABELS.COUNTRIES_TC]: "타쿠 쿠스 카이 코스 제도",
    [COUNTRIES_LABELS.COUNTRIES_TH]: "태국",
    [COUNTRIES_LABELS.COUNTRIES_TJ]: "타지키스탄",
    [COUNTRIES_LABELS.COUNTRIES_TZ]: "탄자니아",
    [COUNTRIES_LABELS.COUNTRIES_CZ]: "체코 공화국",
    [COUNTRIES_LABELS.COUNTRIES_TD]: "차드",
    [COUNTRIES_LABELS.COUNTRIES_TN]: "튀니지",
    [COUNTRIES_LABELS.COUNTRIES_CL]: "칠레",
    [COUNTRIES_LABELS.COUNTRIES_TV]: "츠 바루",
    [COUNTRIES_LABELS.COUNTRIES_DG]: "Diegogarcia Island",
    [COUNTRIES_LABELS.COUNTRIES_DK]: "덴마크",
    [COUNTRIES_LABELS.COUNTRIES_DE]: "독일",
    [COUNTRIES_LABELS.COUNTRIES_TG]: "토고",
    [COUNTRIES_LABELS.COUNTRIES_TK]: "Tokerau",
    [COUNTRIES_LABELS.COUNTRIES_DO]: "도미니카 공화국",
    [COUNTRIES_LABELS.COUNTRIES_DM]: "도미니카 국가",
    [COUNTRIES_LABELS.COUNTRIES_TA]: "트리스탄 다 쿠니아",
    [COUNTRIES_LABELS.COUNTRIES_TT]: "트리니다드 토바고 공화국",
    [COUNTRIES_LABELS.COUNTRIES_TM]: "토크 메니스탄",
    [COUNTRIES_LABELS.COUNTRIES_TR]: "Türkiye",
    [COUNTRIES_LABELS.COUNTRIES_TO]: "통가",
    [COUNTRIES_LABELS.COUNTRIES_NG]: "나이지리아",
    [COUNTRIES_LABELS.COUNTRIES_NR]: "나우 렐",
    [COUNTRIES_LABELS.COUNTRIES_NA]: "나미비아",
    [COUNTRIES_LABELS.COUNTRIES_NU]: "Niyoe Island",
    [COUNTRIES_LABELS.COUNTRIES_NI]: "니카라과",
    [COUNTRIES_LABELS.COUNTRIES_NE]: "Nigail",
    [COUNTRIES_LABELS.COUNTRIES_NC]: "뉴 칼레도니아",
    [COUNTRIES_LABELS.COUNTRIES_NZ]: "뉴질랜드",
    [COUNTRIES_LABELS.COUNTRIES_NP]: "네팔",
    [COUNTRIES_LABELS.COUNTRIES_NF]: "노퍽 섬",
    [COUNTRIES_LABELS.COUNTRIES_NO]: "노르웨이",
    [COUNTRIES_LABELS.COUNTRIES_BH]: "바란",
    [COUNTRIES_LABELS.COUNTRIES_HT]: "아이티",
    [COUNTRIES_LABELS.COUNTRIES_PK]: "파키스탄",
    [COUNTRIES_LABELS.COUNTRIES_VA]: "바티칸 시티",
    [COUNTRIES_LABELS.COUNTRIES_PA]: "파나마",
    [COUNTRIES_LABELS.COUNTRIES_VU]: "바누아츠",
    [COUNTRIES_LABELS.COUNTRIES_BS]: "바하마",
    [COUNTRIES_LABELS.COUNTRIES_PG]: "파푸아 뉴기니",
    [COUNTRIES_LABELS.COUNTRIES_BM]: "버뮤다",
    [COUNTRIES_LABELS.COUNTRIES_PW]: "팔라오",
    [COUNTRIES_LABELS.COUNTRIES_PY]: "파라과이",
    [COUNTRIES_LABELS.COUNTRIES_BB]: "바베이도스",
    [COUNTRIES_LABELS.COUNTRIES_PS]: "팔레스타인",
    [COUNTRIES_LABELS.COUNTRIES_HU]: "헝가리",
    [COUNTRIES_LABELS.COUNTRIES_BD]: "방글라데시",
    [COUNTRIES_LABELS.COUNTRIES_PN]: "Pitcean Islands",
    [COUNTRIES_LABELS.COUNTRIES_FJ]: "피지",
    [COUNTRIES_LABELS.COUNTRIES_PH]: "필리핀 제도",
    [COUNTRIES_LABELS.COUNTRIES_FI]: "핀란드",
    [COUNTRIES_LABELS.COUNTRIES_BT]: "부탄",
    [COUNTRIES_LABELS.COUNTRIES_PR]: "푸에르토 리코",
    [COUNTRIES_LABELS.COUNTRIES_FO]: "동료 제도",
    [COUNTRIES_LABELS.COUNTRIES_FK]: "포클랜드 제도",
    [COUNTRIES_LABELS.COUNTRIES_BR]: "브라질",
    [COUNTRIES_LABELS.COUNTRIES_FR]: "프랑스",
    [COUNTRIES_LABELS.COUNTRIES_BG]: "불가리아",
    [COUNTRIES_LABELS.COUNTRIES_BF]: "부키 나 파소",
    [COUNTRIES_LABELS.COUNTRIES_BN]: "브루나이",
    [COUNTRIES_LABELS.COUNTRIES_BI]: "브러시",
    [COUNTRIES_LABELS.COUNTRIES_VN]: "베트남",
    [COUNTRIES_LABELS.COUNTRIES_BJ]: "베넌",
    [COUNTRIES_LABELS.COUNTRIES_VE]: "베네수엘라",
    [COUNTRIES_LABELS.COUNTRIES_BY]: "벨라루스",
    [COUNTRIES_LABELS.COUNTRIES_BZ]: "벨리즈",
    [COUNTRIES_LABELS.COUNTRIES_PE]: "페루",
    [COUNTRIES_LABELS.COUNTRIES_BE]: "벨기에",
    [COUNTRIES_LABELS.COUNTRIES_PL]: "폴란드",
    [COUNTRIES_LABELS.COUNTRIES_BA]: "보스니아-헤르체고비나",
    [COUNTRIES_LABELS.COUNTRIES_BW]: "Botwana",
    [COUNTRIES_LABELS.COUNTRIES_BO]: "볼리비아",
    [COUNTRIES_LABELS.COUNTRIES_PT]: "포르투갈",
    [COUNTRIES_LABELS.COUNTRIES_HN]: "온두라스",
    [COUNTRIES_LABELS.COUNTRIES_MH]: "마샬 제도",
    [COUNTRIES_LABELS.COUNTRIES_MK]: "마케도니아",
    [COUNTRIES_LABELS.COUNTRIES_MG]: "마다가스카르",
    [COUNTRIES_LABELS.COUNTRIES_YT]: "Mayot Island",
    [COUNTRIES_LABELS.COUNTRIES_MW]: "말라위",
    [COUNTRIES_LABELS.COUNTRIES_ML]: "마리",
    [COUNTRIES_LABELS.COUNTRIES_MT]: "몰타",
    [COUNTRIES_LABELS.COUNTRIES_MQ]: "몰티 니크",
    [COUNTRIES_LABELS.COUNTRIES_MY]: "말레이시아",
    [COUNTRIES_LABELS.COUNTRIES_IM]: "섬",
    [COUNTRIES_LABELS.COUNTRIES_FM]: "미크로네시아 연맹",
    [COUNTRIES_LABELS.COUNTRIES_MM]: "미얀마",
    [COUNTRIES_LABELS.COUNTRIES_MX]: "멕시코",
    [COUNTRIES_LABELS.COUNTRIES_MU]: "모리셔스",
    [COUNTRIES_LABELS.COUNTRIES_MR]: "모리타니",
    [COUNTRIES_LABELS.COUNTRIES_MZ]: "모잠비크",
    [COUNTRIES_LABELS.COUNTRIES_MC]: "모나코",
    [COUNTRIES_LABELS.COUNTRIES_MV]: "몰디브",
    [COUNTRIES_LABELS.COUNTRIES_MD]: "몰도바",
    [COUNTRIES_LABELS.COUNTRIES_MA]: "모로코",
    [COUNTRIES_LABELS.COUNTRIES_MN]: "몽골리아",
    [COUNTRIES_LABELS.COUNTRIES_ME]: "몬테네그로",
    [COUNTRIES_LABELS.COUNTRIES_MS]: "Montrat",
    [COUNTRIES_LABELS.COUNTRIES_JO]: "요르단",
    [COUNTRIES_LABELS.COUNTRIES_LA]: "라오스",
    [COUNTRIES_LABELS.COUNTRIES_LV]: "라트비아",
    [COUNTRIES_LABELS.COUNTRIES_LT]: "리투아니아",
    [COUNTRIES_LABELS.COUNTRIES_LY]: "리비아",
    [COUNTRIES_LABELS.COUNTRIES_LI]: "리히텐슈타인",
    [COUNTRIES_LABELS.COUNTRIES_LR]: "라이베리아",
    [COUNTRIES_LABELS.COUNTRIES_RO]: "루마니아",
    [COUNTRIES_LABELS.COUNTRIES_LU]: "룩셈부르크",
    [COUNTRIES_LABELS.COUNTRIES_RW]: "르완다",
    [COUNTRIES_LABELS.COUNTRIES_LS]: "Lesot",
    [COUNTRIES_LABELS.COUNTRIES_LB]: "레바논",
    [COUNTRIES_LABELS.COUNTRIES_RE]: "동창회 섬",
    [COUNTRIES_LABELS.COUNTRIES_RU]: "러시아",
    [COUNTRIES_LABELS.COUNTRIES_IO]: "영국 인도양 지역",
    [COUNTRIES_LABELS.COUNTRIES_VG]: "영국령 버진 아일랜드",
    [COUNTRIES_LABELS.COUNTRIES_EH]: "웨스트 사하라",
    [COUNTRIES_LABELS.COUNTRIES_GQ]: "적도 기니",
    [COUNTRIES_LABELS.COUNTRIES_TW]: "대만",
    [COUNTRIES_LABELS.COUNTRIES_KR]: "대한민국",
    [COUNTRIES_LABELS.COUNTRIES_CF]: "중앙 아프리카",
    [COUNTRIES_LABELS.COUNTRIES_MO]: "중국 인민 공화국 마카오 특별 행정 지구",
    [COUNTRIES_LABELS.COUNTRIES_HK]: "중국 인민 공화국 홍콩 특별 행정 지구",
    [COUNTRIES_LABELS.COUNTRIES_CN]: "중국",
    [COUNTRIES_LABELS.COUNTRIES_KP]: "한국 민주주의 인민 공화국",
    [COUNTRIES_LABELS.COUNTRIES_TL]: "동 티모르",
    [COUNTRIES_LABELS.COUNTRIES_ZA]: "남아프리카",
    [COUNTRIES_LABELS.COUNTRIES_GS]: "남부 조지아, 사우스 샌드위치 제도",
    [COUNTRIES_LABELS.COUNTRIES_SS]: "남 수단",
    [COUNTRIES_LABELS.COUNTRIES_AQ]: "남극",
    [COUNTRIES_LABELS.COUNTRIES_JP]: "일본",
    [COUNTRIES_LABELS.COUNTRIES_GF]: "프랑스 지아나",
    [COUNTRIES_LABELS.COUNTRIES_PF]: "프랑스 령 폴리네시아의",
    [COUNTRIES_LABELS.COUNTRIES_TF]: "프랑스 사우스 남쪽 섬",
    [COUNTRIES_LABELS.COUNTRIES_VI]: "미국 영토 버진 아일랜드",
    [COUNTRIES_LABELS.COUNTRIES_AS]: "미국 영토 사모아",
    [COUNTRIES_LABELS.COUNTRIES_UM]: "미국 영토 태평양 섬",
    [COUNTRIES_LABELS.COUNTRIES_MP]: "노스 마리아나 제도",
};

export default LABELS;
