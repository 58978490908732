export const extractHostname = (url: string) => {
    const a = document.createElement("a");
    a.href = url;

    return a.hostname || url.split(":")[0];
};

export const extractRootDomain = (url: string) => {
    const hostname = extractHostname(url);
    const hostnameParts = hostname.split(".");
    const partLength = hostnameParts.length;

    if (partLength <= 2) {
        return hostname;
    }

    const [part1, part2, part3] = hostnameParts.splice(partLength - 3);
    const hasCountryCodeTopLevelDomain = part2.length === 2 && part3.length === 2; // (i.e. ".me.uk")

    return hasCountryCodeTopLevelDomain ? [part1, part2, part3].join(".") : [part2, part3].join(".");
};
