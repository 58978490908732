import * as COUNTRIES_LABELS from "../labels/countries";

export const LABELS = {
    [COUNTRIES_LABELS.COUNTRIES_GN]: "幾內亞",
    [COUNTRIES_LABELS.COUNTRIES_GW]: "幾內亞比紹",
    [COUNTRIES_LABELS.COUNTRIES_YE]: "也門",
    [COUNTRIES_LABELS.COUNTRIES_TR]: "土耳其",
    [COUNTRIES_LABELS.COUNTRIES_TM]: "土庫曼斯坦",
    [COUNTRIES_LABELS.COUNTRIES_MV]: "馬爾代夫",
    [COUNTRIES_LABELS.COUNTRIES_YT]: "馬約特",
    [COUNTRIES_LABELS.COUNTRIES_MT]: "馬耳他",
    [COUNTRIES_LABELS.COUNTRIES_MY]: "馬來西亞",
    [COUNTRIES_LABELS.COUNTRIES_MG]: "馬達加斯加",
    [COUNTRIES_LABELS.COUNTRIES_ML]: "馬里",
    [COUNTRIES_LABELS.COUNTRIES_MK]: "馬其頓",
    [COUNTRIES_LABELS.COUNTRIES_MW]: "馬拉維",
    [COUNTRIES_LABELS.COUNTRIES_MH]: "馬紹爾群島",
    [COUNTRIES_LABELS.COUNTRIES_MQ]: "馬提尼克",
    [COUNTRIES_LABELS.COUNTRIES_BT]: "不丹",
    [COUNTRIES_LABELS.COUNTRIES_CN]: "中國",
    [COUNTRIES_LABELS.COUNTRIES_HK]: "中國香港特別行政區",
    [COUNTRIES_LABELS.COUNTRIES_MO]: "中國澳門特別行政區",
    [COUNTRIES_LABELS.COUNTRIES_CF]: "中非共和國",
    [COUNTRIES_LABELS.COUNTRIES_DK]: "丹麥",
    [COUNTRIES_LABELS.COUNTRIES_UG]: "烏干達",
    [COUNTRIES_LABELS.COUNTRIES_UA]: "烏克蘭",
    [COUNTRIES_LABELS.COUNTRIES_UY]: "烏拉圭",
    [COUNTRIES_LABELS.COUNTRIES_UZ]: "烏茲別克斯坦",
    [COUNTRIES_LABELS.COUNTRIES_GM]: "岡比亞",
    [COUNTRIES_LABELS.COUNTRIES_EC]: "厄瓜多爾",
    [COUNTRIES_LABELS.COUNTRIES_ER]: "厄立特里亞",
    [COUNTRIES_LABELS.COUNTRIES_BB]: "巴巴多斯",
    [COUNTRIES_LABELS.COUNTRIES_PG]: "巴布亞新幾內亞",
    [COUNTRIES_LABELS.COUNTRIES_BR]: "巴西",
    [COUNTRIES_LABELS.COUNTRIES_PY]: "巴拉圭",
    [COUNTRIES_LABELS.COUNTRIES_BH]: "巴林",
    [COUNTRIES_LABELS.COUNTRIES_BS]: "巴哈馬",
    [COUNTRIES_LABELS.COUNTRIES_PA]: "巴拿馬",
    [COUNTRIES_LABELS.COUNTRIES_PS]: "巴勒斯坦領土",
    [COUNTRIES_LABELS.COUNTRIES_PK]: "巴基斯坦",
    [COUNTRIES_LABELS.COUNTRIES_KY]: "開曼群島",
    [COUNTRIES_LABELS.COUNTRIES_BN]: "文萊",
    [COUNTRIES_LABELS.COUNTRIES_JP]: "日本",
    [COUNTRIES_LABELS.COUNTRIES_BE]: "比利時",
    [COUNTRIES_LABELS.COUNTRIES_MU]: "毛里求斯",
    [COUNTRIES_LABELS.COUNTRIES_MR]: "毛里塔尼亞",
    [COUNTRIES_LABELS.COUNTRIES_JM]: "牙買加",
    [COUNTRIES_LABELS.COUNTRIES_BJ]: "貝寧",
    [COUNTRIES_LABELS.COUNTRIES_TL]: "東帝汶",
    [COUNTRIES_LABELS.COUNTRIES_TD]: "乍得",
    [COUNTRIES_LABELS.COUNTRIES_IL]: "以色列",
    [COUNTRIES_LABELS.COUNTRIES_GH]: "加納",
    [COUNTRIES_LABELS.COUNTRIES_IC]: "加納利群島",
    [COUNTRIES_LABELS.COUNTRIES_CA]: "加拿大",
    [COUNTRIES_LABELS.COUNTRIES_GA]: "加蓬",
    [COUNTRIES_LABELS.COUNTRIES_MP]: "北馬里亞納群島",
    [COUNTRIES_LABELS.COUNTRIES_QA]: "卡塔爾",
    [COUNTRIES_LABELS.COUNTRIES_RW]: "盧旺達",
    [COUNTRIES_LABELS.COUNTRIES_LU]: "盧森堡",
    [COUNTRIES_LABELS.COUNTRIES_CU]: "古巴",
    [COUNTRIES_LABELS.COUNTRIES_TW]: "台灣",
    [COUNTRIES_LABELS.COUNTRIES_SM]: "聖馬力諾",
    [COUNTRIES_LABELS.COUNTRIES_BL]: "聖巴泰勒米",
    [COUNTRIES_LABELS.COUNTRIES_VC]: "聖文森特和格林納丁斯",
    [COUNTRIES_LABELS.COUNTRIES_LC]: "聖盧西亞",
    [COUNTRIES_LABELS.COUNTRIES_PM]: "聖皮埃爾和密克隆群島",
    [COUNTRIES_LABELS.COUNTRIES_ST]: "聖多美和普林西比",
    [COUNTRIES_LABELS.COUNTRIES_CX]: "聖誕島",
    [COUNTRIES_LABELS.COUNTRIES_KN]: "聖基茨和尼維斯",
    [COUNTRIES_LABELS.COUNTRIES_SH]: "聖赫勒拿",
    [COUNTRIES_LABELS.COUNTRIES_NE]: "尼日爾",
    [COUNTRIES_LABELS.COUNTRIES_NG]: "尼日利亞",
    [COUNTRIES_LABELS.COUNTRIES_NI]: "尼加拉瓜",
    [COUNTRIES_LABELS.COUNTRIES_NP]: "尼泊爾",
    [COUNTRIES_LABELS.COUNTRIES_BF]: "布基納法索",
    [COUNTRIES_LABELS.COUNTRIES_BI]: "布隆迪",
    [COUNTRIES_LABELS.COUNTRIES_GP]: "瓜德羅普",
    [COUNTRIES_LABELS.COUNTRIES_WF]: "瓦利斯和富圖納",
    [COUNTRIES_LABELS.COUNTRIES_VU]: "瓦努阿圖",
    [COUNTRIES_LABELS.COUNTRIES_BY]: "白俄羅斯",
    [COUNTRIES_LABELS.COUNTRIES_PN]: "皮特凱恩群島",
    [COUNTRIES_LABELS.COUNTRIES_LT]: "立陶宛",
    [COUNTRIES_LABELS.COUNTRIES_AM]: "亞美尼亞",
    [COUNTRIES_LABELS.COUNTRIES_IQ]: "伊拉克",
    [COUNTRIES_LABELS.COUNTRIES_IR]: "伊朗",
    [COUNTRIES_LABELS.COUNTRIES_EA]: "休達及梅利利亞",
    [COUNTRIES_LABELS.COUNTRIES_GU]: "關島",
    [COUNTRIES_LABELS.COUNTRIES_IS]: "冰島",
    [COUNTRIES_LABELS.COUNTRIES_LI]: "列支敦士登",
    [COUNTRIES_LABELS.COUNTRIES_CG]: "剛果（布）",
    [COUNTRIES_LABELS.COUNTRIES_CD]: "剛果（金）",
    [COUNTRIES_LABELS.COUNTRIES_HU]: "匈牙利",
    [COUNTRIES_LABELS.COUNTRIES_IN]: "印度",
    [COUNTRIES_LABELS.COUNTRIES_ID]: "印度尼西亞",
    [COUNTRIES_LABELS.COUNTRIES_GT]: "危地馬拉",
    [COUNTRIES_LABELS.COUNTRIES_KG]: "吉爾吉斯斯坦",
    [COUNTRIES_LABELS.COUNTRIES_DJ]: "吉布提",
    [COUNTRIES_LABELS.COUNTRIES_GY]: "圭亞那",
    [COUNTRIES_LABELS.COUNTRIES_DO]: "多米尼加共和國",
    [COUNTRIES_LABELS.COUNTRIES_DM]: "多米尼克",
    [COUNTRIES_LABELS.COUNTRIES_TG]: "多哥",
    [COUNTRIES_LABELS.COUNTRIES_AI]: "安圭拉",
    [COUNTRIES_LABELS.COUNTRIES_AO]: "安哥拉",
    [COUNTRIES_LABELS.COUNTRIES_AG]: "安提瓜和巴布達",
    [COUNTRIES_LABELS.COUNTRIES_AD]: "安道爾",
    [COUNTRIES_LABELS.COUNTRIES_TK]: "托克勞",
    [COUNTRIES_LABELS.COUNTRIES_TO]: "湯加",
    [COUNTRIES_LABELS.COUNTRIES_BM]: "百慕大",
    [COUNTRIES_LABELS.COUNTRIES_JO]: "約旦",
    [COUNTRIES_LABELS.COUNTRIES_LA]: "老撾",
    [COUNTRIES_LABELS.COUNTRIES_ES]: "西班牙",
    [COUNTRIES_LABELS.COUNTRIES_EH]: "西撒哈拉",
    [COUNTRIES_LABELS.COUNTRIES_BZ]: "伯利茲",
    [COUNTRIES_LABELS.COUNTRIES_CV]: "佛得角",
    [COUNTRIES_LABELS.COUNTRIES_HR]: "克羅地亞",
    [COUNTRIES_LABELS.COUNTRIES_LY]: "利比亞",
    [COUNTRIES_LABELS.COUNTRIES_LR]: "利比里亞",
    [COUNTRIES_LABELS.COUNTRIES_GR]: "希臘",
    [COUNTRIES_LABELS.COUNTRIES_CK]: "庫克群島",
    [COUNTRIES_LABELS.COUNTRIES_CW]: "庫拉索",
    [COUNTRIES_LABELS.COUNTRIES_SA]: "沙特阿拉伯",
    [COUNTRIES_LABELS.COUNTRIES_NA]: "納米比亞",
    [COUNTRIES_LABELS.COUNTRIES_NU]: "紐埃",
    [COUNTRIES_LABELS.COUNTRIES_GQ]: "赤道幾內亞",
    [COUNTRIES_LABELS.COUNTRIES_TV]: "圖瓦盧",
    [COUNTRIES_LABELS.COUNTRIES_TZ]: "坦桑尼亞",
    [COUNTRIES_LABELS.COUNTRIES_VE]: "委內瑞拉",
    [COUNTRIES_LABELS.COUNTRIES_BD]: "孟加拉國",
    [COUNTRIES_LABELS.COUNTRIES_PW]: "帕勞",
    [COUNTRIES_LABELS.COUNTRIES_SB]: "所羅門群島",
    [COUNTRIES_LABELS.COUNTRIES_LV]: "拉脫維亞",
    [COUNTRIES_LABELS.COUNTRIES_FR]: "法國",
    [COUNTRIES_LABELS.COUNTRIES_FO]: "法羅群島",
    [COUNTRIES_LABELS.COUNTRIES_MF]: "法屬聖馬丁",
    [COUNTRIES_LABELS.COUNTRIES_GF]: "法屬圭亞那",
    [COUNTRIES_LABELS.COUNTRIES_PF]: "法屬波利尼西亞",
    [COUNTRIES_LABELS.COUNTRIES_TF]: "法屬南部領地",
    [COUNTRIES_LABELS.COUNTRIES_PL]: "波蘭",
    [COUNTRIES_LABELS.COUNTRIES_PR]: "波多黎各",
    [COUNTRIES_LABELS.COUNTRIES_BA]: "波斯尼亞和黑塞哥維那",
    [COUNTRIES_LABELS.COUNTRIES_JE]: "澤西島",
    [COUNTRIES_LABELS.COUNTRIES_GI]: "直布羅陀",
    [COUNTRIES_LABELS.COUNTRIES_RO]: "羅馬尼亞",
    [COUNTRIES_LABELS.COUNTRIES_KE]: "肯尼亞",
    [COUNTRIES_LABELS.COUNTRIES_FI]: "芬蘭",
    [COUNTRIES_LABELS.COUNTRIES_SD]: "蘇丹",
    [COUNTRIES_LABELS.COUNTRIES_SR]: "蘇里南",
    [COUNTRIES_LABELS.COUNTRIES_DZ]: "阿爾及利亞",
    [COUNTRIES_LABELS.COUNTRIES_AL]: "阿爾巴尼亞",
    [COUNTRIES_LABELS.COUNTRIES_AE]: "阿拉伯聯合酋長國",
    [COUNTRIES_LABELS.COUNTRIES_AR]: "阿根廷",
    [COUNTRIES_LABELS.COUNTRIES_OM]: "阿曼",
    [COUNTRIES_LABELS.COUNTRIES_AF]: "阿富汗",
    [COUNTRIES_LABELS.COUNTRIES_AC]: "阿森松島",
    [COUNTRIES_LABELS.COUNTRIES_AW]: "阿魯巴",
    [COUNTRIES_LABELS.COUNTRIES_AZ]: "阿塞拜疆",
    [COUNTRIES_LABELS.COUNTRIES_RU]: "俄羅斯",
    [COUNTRIES_LABELS.COUNTRIES_BG]: "保加利亞",
    [COUNTRIES_LABELS.COUNTRIES_GS]: "南喬治亞島和南桑威齊群島",
    [COUNTRIES_LABELS.COUNTRIES_AQ]: "南極洲",
    [COUNTRIES_LABELS.COUNTRIES_SS]: "南蘇丹",
    [COUNTRIES_LABELS.COUNTRIES_ZA]: "南非",
    [COUNTRIES_LABELS.COUNTRIES_SY]: "敘利亞",
    [COUNTRIES_LABELS.COUNTRIES_KZ]: "哈薩克斯坦",
    [COUNTRIES_LABELS.COUNTRIES_KH]: "柬埔寨",
    [COUNTRIES_LABELS.COUNTRIES_ZW]: "津巴布韋",
    [COUNTRIES_LABELS.COUNTRIES_HN]: "洪都拉斯",
    [COUNTRIES_LABELS.COUNTRIES_BO]: "玻利維亞",
    [COUNTRIES_LABELS.COUNTRIES_KW]: "科威特",
    [COUNTRIES_LABELS.COUNTRIES_CC]: "科科斯（基林）群島",
    [COUNTRIES_LABELS.COUNTRIES_CI]: "科特迪瓦",
    [COUNTRIES_LABELS.COUNTRIES_XK]: "科索沃",
    [COUNTRIES_LABELS.COUNTRIES_KM]: "科摩羅",
    [COUNTRIES_LABELS.COUNTRIES_TN]: "突尼斯",
    [COUNTRIES_LABELS.COUNTRIES_US]: "美國",
    [COUNTRIES_LABELS.COUNTRIES_UM]: "美國本土外小島嶼",
    [COUNTRIES_LABELS.COUNTRIES_VI]: "美屬維京群島",
    [COUNTRIES_LABELS.COUNTRIES_AS]: "美屬薩摩亞",
    [COUNTRIES_LABELS.COUNTRIES_GB]: "英國",
    [COUNTRIES_LABELS.COUNTRIES_IO]: "英屬印度洋領地",
    [COUNTRIES_LABELS.COUNTRIES_VG]: "英屬維京群島",
    [COUNTRIES_LABELS.COUNTRIES_DG]: "迪戈加西亞島",
    [COUNTRIES_LABELS.COUNTRIES_CO]: "哥倫比亞",
    [COUNTRIES_LABELS.COUNTRIES_CR]: "哥斯達黎加",
    [COUNTRIES_LABELS.COUNTRIES_EG]: "埃及",
    [COUNTRIES_LABELS.COUNTRIES_ET]: "埃塞俄比亞",
    [COUNTRIES_LABELS.COUNTRIES_NO]: "挪威",
    [COUNTRIES_LABELS.COUNTRIES_GG]: "根西島",
    [COUNTRIES_LABELS.COUNTRIES_GD]: "格林納達",
    [COUNTRIES_LABELS.COUNTRIES_GL]: "格陵蘭",
    [COUNTRIES_LABELS.COUNTRIES_GE]: "格魯吉亞",
    [COUNTRIES_LABELS.COUNTRIES_TH]: "泰國",
    [COUNTRIES_LABELS.COUNTRIES_HT]: "海地",
    [COUNTRIES_LABELS.COUNTRIES_IE]: "愛爾蘭",
    [COUNTRIES_LABELS.COUNTRIES_EE]: "愛沙尼亞",
    [COUNTRIES_LABELS.COUNTRIES_TT]: "特立尼達和多巴哥",
    [COUNTRIES_LABELS.COUNTRIES_TC]: "特克斯和凱科斯群島",
    [COUNTRIES_LABELS.COUNTRIES_TA]: "特里斯坦-達庫尼亞群島",
    [COUNTRIES_LABELS.COUNTRIES_RE]: "留尼汪",
    [COUNTRIES_LABELS.COUNTRIES_PE]: "秘魯",
    [COUNTRIES_LABELS.COUNTRIES_SO]: "索馬里",
    [COUNTRIES_LABELS.COUNTRIES_NF]: "諾福克島",
    [COUNTRIES_LABELS.COUNTRIES_KI]: "基里巴斯",
    [COUNTRIES_LABELS.COUNTRIES_FM]: "密克羅尼西亞",
    [COUNTRIES_LABELS.COUNTRIES_CZ]: "捷克共和國",
    [COUNTRIES_LABELS.COUNTRIES_IM]: "曼島",
    [COUNTRIES_LABELS.COUNTRIES_VA]: "梵蒂岡",
    [COUNTRIES_LABELS.COUNTRIES_NL]: "荷蘭",
    [COUNTRIES_LABELS.COUNTRIES_BQ]: "荷蘭加勒比區",
    [COUNTRIES_LABELS.COUNTRIES_SX]: "荷屬聖馬丁",
    [COUNTRIES_LABELS.COUNTRIES_MZ]: "莫桑比克",
    [COUNTRIES_LABELS.COUNTRIES_LS]: "萊索托",
    [COUNTRIES_LABELS.COUNTRIES_BW]: "博茨瓦納",
    [COUNTRIES_LABELS.COUNTRIES_CM]: "喀麥隆",
    [COUNTRIES_LABELS.COUNTRIES_AX]: "奧蘭群島",
    [COUNTRIES_LABELS.COUNTRIES_AT]: "奧地利",
    [COUNTRIES_LABELS.COUNTRIES_FJ]: "斐濟",
    [COUNTRIES_LABELS.COUNTRIES_SJ]: "斯瓦爾巴特和揚馬延",
    [COUNTRIES_LABELS.COUNTRIES_LK]: "斯里蘭卡",
    [COUNTRIES_LABELS.COUNTRIES_SZ]: "斯威士蘭",
    [COUNTRIES_LABELS.COUNTRIES_SI]: "斯洛文尼亞",
    [COUNTRIES_LABELS.COUNTRIES_SK]: "斯洛伐克",
    [COUNTRIES_LABELS.COUNTRIES_CL]: "智利",
    [COUNTRIES_LABELS.COUNTRIES_KP]: "朝鮮",
    [COUNTRIES_LABELS.COUNTRIES_MM]: "緬甸",
    [COUNTRIES_LABELS.COUNTRIES_PH]: "菲律賓",
    [COUNTRIES_LABELS.COUNTRIES_VN]: "越南",
    [COUNTRIES_LABELS.COUNTRIES_KR]: "韓國",
    [COUNTRIES_LABELS.COUNTRIES_ME]: "黑山共和國",
    [COUNTRIES_LABELS.COUNTRIES_TJ]: "塔吉克斯坦",
    [COUNTRIES_LABELS.COUNTRIES_SN]: "塞內加爾",
    [COUNTRIES_LABELS.COUNTRIES_RS]: "塞爾維亞",
    [COUNTRIES_LABELS.COUNTRIES_SC]: "塞舌爾",
    [COUNTRIES_LABELS.COUNTRIES_SL]: "塞拉利昂",
    [COUNTRIES_LABELS.COUNTRIES_CY]: "塞浦路斯",
    [COUNTRIES_LABELS.COUNTRIES_IT]: "意大利",
    [COUNTRIES_LABELS.COUNTRIES_SG]: "新加坡",
    [COUNTRIES_LABELS.COUNTRIES_NZ]: "新西蘭",
    [COUNTRIES_LABELS.COUNTRIES_NC]: "新喀裡多尼亞",
    [COUNTRIES_LABELS.COUNTRIES_NR]: "瑙魯",
    [COUNTRIES_LABELS.COUNTRIES_CH]: "瑞士",
    [COUNTRIES_LABELS.COUNTRIES_SE]: "瑞典",
    [COUNTRIES_LABELS.COUNTRIES_SV]: "薩爾瓦多",
    [COUNTRIES_LABELS.COUNTRIES_WS]: "薩摩亞",
    [COUNTRIES_LABELS.COUNTRIES_PT]: "葡萄牙",
    [COUNTRIES_LABELS.COUNTRIES_FK]: "福克蘭群島",
    [COUNTRIES_LABELS.COUNTRIES_MN]: "蒙古",
    [COUNTRIES_LABELS.COUNTRIES_MS]: "蒙特塞拉特",
    [COUNTRIES_LABELS.COUNTRIES_DE]: "德國",
    [COUNTRIES_LABELS.COUNTRIES_MD]: "摩爾多瓦",
    [COUNTRIES_LABELS.COUNTRIES_MC]: "摩納哥",
    [COUNTRIES_LABELS.COUNTRIES_MA]: "摩洛哥",
    [COUNTRIES_LABELS.COUNTRIES_LB]: "黎巴嫩",
    [COUNTRIES_LABELS.COUNTRIES_MX]: "墨西哥",
    [COUNTRIES_LABELS.COUNTRIES_AU]: "澳大利亞",
    [COUNTRIES_LABELS.COUNTRIES_ZM]: "贊比亞",
};

export default LABELS;
