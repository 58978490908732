import browserLocale from "browser-locale";

import { SupportedLocale } from "./locale/labels";

export const LocalesMap = {
    [SupportedLocale.EN_US]: /^en(-(us))?$/i,
    [SupportedLocale.JA_JP]: /^ja(-jp)?$/i,
    [SupportedLocale.ZH_CN]: /^zh(-(cn))?$/i,
    [SupportedLocale.ZH_TW]: /^zh(-(tw))?$/i,
    [SupportedLocale.RU_RU]: /^ru(-(ru))?$/i,
    [SupportedLocale.TH_TH]: /^th(-(th))?$/i,
    [SupportedLocale.KO_KR]: /^ko(-(kr))?$/i,
};

export const defaultLocale: string = SupportedLocale.JA_JP;

export const getLocale = (locale: string): string => {
    const _locale: string = !locale || locale === "auto" ? browserLocale().toLocaleLowerCase() : locale;

    return Object.keys(LocalesMap).reduce(
        (accLocale: string, currLocale: string) => (LocalesMap[currLocale].test(_locale) ? currLocale : accLocale),
        defaultLocale
    );
};
