import { Metadata } from "univapay-node";

export const toBoolean = (value: unknown, defaultValue?: boolean): boolean | null => {
    if (value && typeof value === "string") {
        const lowerCasedValue = value.toLowerCase();
        return lowerCasedValue === "true" ? true : lowerCasedValue === "false" ? false : null;
    }

    if (value && typeof value === "number") {
        return value === 1 ? true : value === 0 ? false : null;
    }

    if (typeof value === "boolean") {
        return value;
    }

    return defaultValue !== undefined ? defaultValue : value === undefined ? undefined : null;
};

export const toNumber = (value: unknown, defaultValue?: number): number | null => {
    if (value && typeof value === "string") {
        return Number(value);
    }

    if (value && typeof value === "boolean") {
        return value ? 1 : 0;
    }

    if (typeof value === "number") {
        return value;
    }

    return defaultValue !== undefined ? defaultValue : value === undefined ? undefined : null;
};

const replaceAll = (text: string, char: string, replace: string) => (text ? text.split(char).join(replace) : text);

export const toArray = <T extends string>(value: unknown, defaultValue?: T[], separator = ","): T[] | null => {
    if (value && typeof value === "string") {
        const encodedComma = encodeURIComponent(separator);

        return replaceAll(value || "", `\\${separator}`, encodedComma)
            .split(separator)
            .filter(Boolean)
            .map((value) => replaceAll(value, encodedComma, separator).trim() as T);
    }

    if (value && Array.isArray(value)) {
        return value.filter(Boolean);
    }

    return defaultValue !== undefined ? defaultValue : value === undefined ? undefined : null;
};

export const toObject = (value: string | Metadata, defaultValue?: Metadata, propertySeparator = ","): Metadata => {
    if (value && typeof value === "string") {
        const encodedPropertySeparator = encodeURIComponent(propertySeparator);
        const encodedColumn = encodeURIComponent(":");

        return replaceAll(replaceAll(value, `\\${propertySeparator}`, encodedPropertySeparator), "\\:", encodedColumn)
            .split(propertySeparator)
            .reduce((acc, pair) => {
                const [key, value] = pair.split(":");
                if (key && value) {
                    acc[key.trim()] = replaceAll(
                        replaceAll(value, encodedPropertySeparator, propertySeparator),
                        encodedColumn,
                        ":"
                    ).trim();
                }

                return acc;
            }, {});
    }

    if (value && typeof value === "object") {
        return (value as unknown) as Metadata;
    }

    return defaultValue !== undefined ? defaultValue : value === undefined ? undefined : null;
};

export const toDate = (value: unknown, defaultValue?: Date): Date | null => {
    if ((value && typeof value === "string") || typeof value === "number") {
        return new Date(value);
    }

    if (value && value instanceof Date) {
        return value;
    }

    return defaultValue !== undefined ? defaultValue : value === undefined ? undefined : null;
};
